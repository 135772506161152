let cache = [];
let persisted = [];

const TranslationCache  = {
    remember(language, text, translation) {
        const index = cache.findIndex(x => x.text === text && x.language === language);
        if (index > -1) {
            cache[index].counter++;
            if ( cache[index].counter === 2) {
                TranslationCache.persist(index);
            }
        } else {
            cache.push({language, text, translation, counter: 0});
        }
        TranslationCache.cleanUpCache();
    },
    cleanUpCache() {
        if (cache.length > 1000) {
            for (let i=0; i<cache.length - 300; i++) {
                if (cache[i].counter < 2) {
                    cache.splice(i, 1);
                }
            }
        }
    },
    persist(index) {
        persisted.push(cache[index]);
        localStorage.setItem('translationCache', JSON.stringify(persisted));
    },
    async retrieveFromStorage() {
        const tmp = localStorage.getItem('translationCache');
        if (tmp && JSON.parse(tmp)) {
            persisted = JSON.parse(tmp);
        }
        cache = persisted;
        console.log(cache);
    },
    query(language, text) {
        return cache.find(x => x.text === text && x.language === language);
    }
};

TranslationCache.retrieveFromStorage();

export default TranslationCache;