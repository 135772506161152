import Store from './Store';

let existingStores = [];

function getArticlesDiffsStore(nrWeeksAgo) {
  if (!existingStores[nrWeeksAgo]) {
    existingStores[nrWeeksAgo] = new Store('articles/diff/' + nrWeeksAgo);
  }
  return existingStores[nrWeeksAgo];
}

export {getArticlesDiffsStore};