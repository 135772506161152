const Reasons = [
    { value: 'Alnatura', label: 'Alnatura'},
    { value: 'Amazon', label: 'Amazon'},
    { value: 'Tegut', label: 'Tegut'},
    { value: 'Müller', label: 'Müller'},
    { value: 'DM DE', label: 'DM DE'},
    { value: 'DM AT', label: 'DM AT'},
    { value: 'Edeka/REWE', label: 'Edeka/REWE'},
    { value: 'Westwing', label: 'Westwing'},
    { value: 'Subscription Box', label: 'Subscription Box'},
    { value: 'besondere B2B Kunden', label: 'besondere B2B Kunden'},
    { value: 'beets&roots', label: 'beets&roots'},
    { value: 'bipa', label: 'bipa'},
    { value: 'Wochenmärkte', label: 'Wochenmärkte'},
    { value: 'KoRo Eis', label: 'KoRo Eis'},
    { value: 'Gorillas DE', label: 'Gorillas DE'},
    { value: 'Gorillas Benelux', label: 'Gorillas Benelux'},
    { value: 'Budni', label: 'Budni'},
    { value: 'KoRo Café', label: 'KoRo Café' }
]

export { Reasons };