import RestClient from "../../../shared/Network/RestClient";

class ReservationObject {
    id = null
    orderlineKey = null
    amount = 0
    date = ''
    reason = ''
    productNumber = ''
    productName = ''
    note = ''
    groupId = ''
    amountReserved = 0
    amountPending = 0

    constructor({id=null, orderlineKey=null, amount, date, reason,
                    productNumber, productName="", note="",
                    groupId='', amountPending=0, amountReserved= 0}) {
        this.id = id
        this.orderlineKey = orderlineKey
        this.amount = amount
        this.date = date
        this.reason = reason
        this.productNumber = productNumber
        this.productName = productName
        this.note = note
        this.groupId = groupId
        this.amountReserved = amountReserved
        this.amountPending = amountPending
    }

    update({date, reason, note, amount}){
        this.date = date
        this.reason = reason
        this.note = note
        if(amount){
            this.amount = amount
        }
    }

    buildCreateRequest() {
        return RestClient.prepareRequest(
            'POST',
            'stockReservations/?itemNr=' + this.productNumber
            + '&name=' + this.productName
            + '&quantity=' + this.amount
            + '&reason=' + this.reason
            + '&until=' + this.date
            + '&note=' + this.note
            + '&groupId=' + this.groupId
        );
    }

    buildUpdateRequest() {
        return RestClient.prepareRequest(
            'PUT',
            'stockReservations/'+this.id
            + '?quantity=' + this.amount
            + '&reason=' + this.reason
            + '&until=' + this.date
            + '&note=' + this.note
        );
    }
}

class BulkReservation {
    date = ''
    reason = ''
    note = ''
    groupId = ''
    reservations = {}

    constructor({date, reason, note ="", groupId, reservations}) {
        this.date = date
        this.reason = reason
        this.note = note
        this.groupId = groupId
        this.reservations = reservations

        this.updateReservations()
    }

    updateReservations(){
        for (const reservation of this.reservations) {
            reservation.update({date: this.date, reason: this.reason, note: this.note})
        }
    }
}

export {ReservationObject, BulkReservation}