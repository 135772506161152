import React, {Component} from "react";
import {observer} from "mobx-react";
import {
    Divider,
    List,
    ListItem,
    ListItemText,MenuItem, TextField,
    Typography
} from "@material-ui/core";
import {Modal, Button, Container, Tabs} from 'react-bulma-components/dist';
import NavigationBar from "../../NavigationBar/NavigationBar";
import {observable} from "mobx";
import {FaFileAlt, FaTrash} from "react-icons/fa";
import {IconWithText} from "../../../dumb";
import AttachmentsModal from "./AttachmentsModal";
import AnswerModal from "./AnswerModal";
import {MailStore} from "../../../../stores/SourcingTool";

import { toast } from 'react-toastify';
import FetchView from "components/dumb/FetchView/FetchView";



const currencies = [
    {
        value: "USD",
        label: "$"
    },
    {
        value:"EUR",
        label:"€"
    }
]

const units = [
    {
        value: "kg"
    },
    {
        value: "l"
    }
]

@observer
class MailsScreen extends Component{
    @observable localstate ={
        classifiedProducts: [],
        convo: null,
        mailContent: {
            subject: "",
            text: "",
            attachments: []
        },
        showModal: false,
        showAnswerModal: false,
        selectedAttachment: {},
        mailID: null,
        mailChecked: null,
        showMails: "all",
        loading: false,
        error: null
    }

    handleClickOnMail(convo){
        const firstEmail = convo[0];
        this.localstate.mailContent.subject = firstEmail.subject;
        this.localstate.mailContent.text = firstEmail.text;
        this.localstate.mailContent.attachments = firstEmail.attachments;
        this.localstate.classifiedProducts = firstEmail.classifiedProducts;
        this.localstate.mailID = firstEmail.id;
        this.localstate.mailChecked = firstEmail.checked
        this.localstate.convo = convo.map(email => ({
            ...email,
            mailContent: {
                subject: email.subject,
                text: email.text,
                attachments: email.attachments
            }
        }));
    }

    shorten(text, displayedChar){
        if(text.length > displayedChar){
            return(
                text.slice(0, displayedChar) + "..."
            )
        }else{
            return(
                text
            )
        }
    }

    filterItems(item, mailID, showMails){
        const firstEmail = item.emails[0];

        if (showMails === "checked"){
            if (firstEmail.checked === true){
                return(
                    <div>
                        <ListItem button key={firstEmail.subject} onClick={() => this.handleClickOnMail(item.emails)} selected={firstEmail.id === mailID}>
                            <ListItemText
                                primary={this.shorten(firstEmail.subject, 50)}
                                secondary={this.shorten(firstEmail.text, 100)}
                            />
                        </ListItem>
                        <Divider />
                    </div>
                )
            }
        } else if (showMails === "unchecked"){
            if (firstEmail.checked === false){
                return(
                    <div>
                        <ListItem button key={firstEmail.subject} onClick={() => this.handleClickOnMail(item.emails)} selected={firstEmail.id === mailID}>
                            <ListItemText
                                primary={this.shorten(firstEmail.subject, 50)}
                                secondary={this.shorten(firstEmail.text, 100)}
                            />
                        </ListItem>
                        <Divider />
                    </div>
                )
            }
        } else {
            return(
                <div>
                    <ListItem button key={firstEmail.subject} onClick={() => this.handleClickOnMail(item.emails)} selected={firstEmail.id === mailID}>
                        <ListItemText
                            primary={this.shorten(firstEmail.subject, 50)}
                            secondary={this.shorten(firstEmail.text, 100)}
                        />
                    </ListItem>
                    <Divider />
                </div>
            )
        }
    }

    mapConvos(emails) {
        const convos = [];

        emails.forEach(email => {
            const convoIndex = convos.findIndex(convo => convo.id === email.id);

            if (convoIndex < 0) {
                convos.push({ id: email.id, emails: [email] });
            } else {
                convos[convoIndex].emails.push(email);
            }
        });
        convos.forEach(convo => {
            convo.emails.sort((email1, email2) => email1.date < email2.date ? 1 : -1);
        })
        convos.sort((convo1, convo2) => convo1.emails[0].date < convo2.emails[0].date ? 1 : -1);

        return convos;
    }

    openAttachment(attach) {
        this.localstate.showModal = true;
        this.localstate.selectedAttachment = attach
    }

    closeAttachment() {
        this.localstate.showModal = false
    }

    displaySubject(mailContent_help){
        if (mailContent_help.text.length >0) {
            return(
                <Typography variant="h6">
                    {mailContent_help.subject}
                </Typography>
            )
        } else {
            return(
                <Typography variant="h5">
                    Wähle eine Mail aus!
                </Typography>
            )
        }
    }

    displayMailText(mailContent_help){
        const attachments = mailContent_help.attachments;
        if (mailContent_help.text.length > 0){
            if (attachments.length > 0) {
                return(
                    <Container>
                        <Typography variant="body1">
                            {mailContent_help.text.split("\n").map(function(item, i) {
                                return (<span key={i}>{item}<br/></span>)})}
                        </Typography>
                        {attachments.map(attachment => (
                            <Container key={attachment.filename} style={{margin: 4}}>
                                <Button variant='contained' onClick={() => {
                                    if(!attachment.text.split('/')[2]){
                                        this.openAttachment(attachment)
                                    } else if(!attachment.text.split('/')[2].includes('mail-pdfs.s3.eu-central-1.amazonaws.com')){
                                        this.openAttachment(attachment)
                                    } else  {
                                        window.open(attachment.text)
                                    }
                                }}>
                                    <IconWithText icon={<FaFileAlt/>} text={attachment.filename} />
                                </Button>
                            </Container>
                        ))}
                    </Container>
                )
            } else {
                return(
                    <Container>
                        <Typography variant="body1">
                            {mailContent_help.text.split("\n").map(function(item, i) {
                                return (<span key={i}>{item}<br/></span>)})}
                        </Typography>
                    </Container>
                )
            }
        }
    }

    handleProductChange(e, index){
        this.localstate.classifiedProducts[index].product = e.target.value
        this.localstate.classifiedProducts[index].manual = true
    }

    handlePriceChange(e, index){
        if(!this.localstate.classifiedProducts[index].price) {
            this.localstate.classifiedProducts[index].price = {};
        }
        this.localstate.classifiedProducts[index].price.value = e.target.value
        this.localstate.classifiedProducts[index].manual = true
    }

    handleCurrencyChange(e, index){
        if(!this.localstate.classifiedProducts[index].price) {
            this.localstate.classifiedProducts[index].price = {};
        }
        this.localstate.classifiedProducts[index].price.currency = e.target.value
        this.localstate.classifiedProducts[index].manual = true
    }

    handleQuantityChange(e, index){
        if(!this.localstate.classifiedProducts[index].weight) {
            this.localstate.classifiedProducts[index].weight = {};
        }
        this.localstate.classifiedProducts[index].weight.value = e.target.value
        this.localstate.classifiedProducts[index].manual = true
    }

    handleUnitChange(e, index){
        if(!this.localstate.classifiedProducts[index].weight) {
            this.localstate.classifiedProducts[index].weight = {};
        }
        this.localstate.classifiedProducts[index].weight.unit = e.target.value
        this.localstate.classifiedProducts[index].manual = true
    }

    //TODO: send array this.localstate.classifiedProducts to backend in order to calculate price in EUR per kg
    async handleSave(){
        this.localstate.loading = true;
        this.localstate.error = null;
        this.localstate.mailChecked = true;
        const { convo, classifiedProducts } = this.localstate;

        try {
            for (let i = 0; i < convo.length; i++) {
                const email = convo[i];

                await MailStore.updateItem({id: email.id, classifiedProducts: classifiedProducts, checked: true });
            }

            toast.success("Successfully saved");
        } catch (error) {
            toast.error("An error occurred. " + error);
        }

        this.localstate.loading = false;
    }

    handleAnswer() {
        this.localstate.showAnswerModal = true;
    }

    closeAnswer() {
        this.localstate.showAnswerModal = false;
    }

    render(){
        const {
            classifiedProducts,
            convo,
            loading,
            mailContent,
            mailID,
            selectedAttachment,
            showMails,
            showModal,
            showAnswerModal
        } = this.localstate;

        return(
            <div>
                <NavigationBar title="Mails" />
                <Modal show={showModal} onClose={this.closeAttachment.bind(this)} closeOnBlur={true}>
                    <AttachmentsModal attachment={selectedAttachment}/>
                </Modal>
                <Modal show={showAnswerModal} onClose={this.closeAnswer.bind(this)} closeOnBlur={true}>
                    <Modal.Content style={styles.innerModal}>
                        <AnswerModal mailID={mailID} onSend={this.closeAnswer.bind(this)}/>
                    </Modal.Content>
                </Modal>
                <Container style={styles.vert}>
                    <Container style={styles.lhs}>
                        <Tabs type="boxed" style={styles.tabs}>
                            <Tabs.Tab active={this.localstate.showMails==="checked"} onClick={() => this.localstate.showMails="checked"}>
                                geprüft
                            </Tabs.Tab>
                            <Tabs.Tab active={this.localstate.showMails==="unchecked"} onClick={() => this.localstate.showMails="unchecked"}>
                                ungeprüft
                            </Tabs.Tab>
                            <Tabs.Tab active={this.localstate.showMails==="all"} onClick={() => this.localstate.showMails="all"}>
                                alle
                            </Tabs.Tab>
                        </Tabs>
                        <Container style={styles.maillist}>
                            <FetchView store = {MailStore}>
                                <List>
                                    {this.mapConvos(MailStore.list).map((item, i) => (
                                        <div key={i}>
                                            {this.filterItems(item, mailID, showMails)}
                                        </div>
                                    ))}
                                </List>
                            </FetchView>
                        </Container>
                    </Container>
                    <Container style={styles.rhs}>
                        <Container style={styles.upperRight}>
                            <Container>
                                {this.displaySubject(mailContent)}
                            </Container>
                            <Container style={styles.mailContent}>
                                {convo && convo.map((email, i) => (
                                    <div key={i}>
                                        <div style={styles.emailText}>
                                            {this.displayMailText(email.mailContent)}
                                        </div>
                                    </div>
                                ))}
                            </Container>
                        </Container>
                        <Divider />
                        <Container mt={2} style={styles.lowerRight}>
                            <Container style={styles.lowerRightHeader}>
                                <Container style={styles.productHeader}>
                                    <Typography variant="h6">
                                        Produkte
                                    </Typography>
                                </Container>
                                <Container style={styles.productButton}>
                                    <Button loading={loading} variant="contained" onClick={this.handleAnswer.bind(this, mailID)} disabled={!mailID}>
                                        Antworten
                                    </Button>
                                    <Button loading={loading} variant="contained" onClick={this.handleSave.bind(this)} disabled={!mailID}>
                                        Speichern und als geprüft markieren
                                    </Button>
                                </Container>
                            </Container>
                            <Container style={styles.productList}>
                                {classifiedProducts.map((item, index) => (
                                    // <ClassProdDisplay item={item} />
                                    <form key={item.product} noValidate autoComplete="off">
                                        <Container style={styles.products}>
                                            <Container style={styles.formfields}>
                                                <TextField
                                                    id="outlined-required"
                                                    label="Produkt"
                                                    value={item.sku}
                                                    variant="outlined"
                                                    onChange={e => this.handleProductChange(e, index)}
                                                />
                                            </Container>
                                            <Container style={styles.formfields}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Preis (Wert)"
                                                    type="number"
                                                    value={item.price ? item.price.value ? item.price.value :  "" : ""}
                                                    variant="outlined"
                                                    onChange={e => this.handlePriceChange(e, index)}
                                                />
                                            </Container>
                                            <Container style={styles.formfields}>
                                                <TextField
                                                    id="outlined-select"
                                                    select
                                                    label="Währung"
                                                    value={item.price ? item.price.currency ? item.price.currency : "" : ""}
                                                    variant="outlined"
                                                    onChange={e => this.handleCurrencyChange(e, index)}
                                                >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Container>
                                            <Container style={styles.formfields}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Menge"
                                                    type="number"
                                                    value={item.weight ? item.weight.value ? item.weight.value : "" : ""}
                                                    variant="outlined"
                                                    onChange={e => this.handleQuantityChange(e, index)}
                                                />
                                            </Container>
                                            <Container style={styles.formfields}>
                                                <TextField
                                                    id="outlined-select"
                                                    select
                                                    label="Einheit"
                                                    value={item.weight ? item.weight.unit ? item.weight.unit : "" : ""}
                                                    variant="outlined"
                                                    onChange={e => this.handleUnitChange(e, index)}
                                                >
                                                    {units.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.value}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Container>
                                        </Container>
                                    </form>
                                ))}
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </div>
        )
    }
}

const styles = {
    vert: {
        display: "flex",
        overflow: 'hidden',
        height: 'calc(100vh - 144px)'
    },


    lhs:{
        width: "30%"
    },
    tabs:{
      marginBottom: 0,
    },
    maillist: {
        overflow: 'auto',
        height: 'calc(100% - 42px)'
    },


    rhs:{
        width: "70%",
        borderLeft: '1px solid #eee',
        padding: '10px'
    },
    upperRight: {
        height: "50%",
        overflow: "hidden"
    },
    mailContent: {
        height: "calc(100% - 32px)",
        overflow: "auto"
    },


    lowerRight: {
        height: "50%",
        overflow: "hidden"
    },
    lowerRightHeader: {
        display: "flex",
        alignContent: "space-between"
    },
    productHeader: {
        display: "flex",
        flex: 10
    },
    productButton: {
        display: "flex",
        flex: 1
    },
    productList:{
        height: "calc(100% - 36px)",
        overflow: "auto"
    },
    products: {
        display: "flex",
        margin: 10
    },
    formfields: {
        padding: 10
    },
    emailText: {
        borderBottom: '2px solid #eee',
        paddingBottom: 30,
        marginBottom: 30
    },
    innerModal: {
        backgroundColor: 'white'
    }
}

export default MailsScreen;