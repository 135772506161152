class OrderLine {
    shipped = 0
    shippedGroup = 0
    mode = 0
    esdArticle = 0

    // set the following values during import:
    statusId = 0
    articleId = ""
    taxId = ""
    taxRate = ""
    articleNumber = ""
    price = null
    quantity = null
    articleName = ""
}

class AddressDetails {
    id = null
    stateId = null

    // set the following values during import:
    customerId = ""
    countryId = ""
    company = ""
    salutation = ""
    firstName = ""
    lastName = ""
    street = ""
    zipCode = ""
    city = ""
}


class ShopwareOrder {
    invoiceShipping = 0
    invoiceShippingNet = 0
    net = 0
    taxFree = 0
    languageIso = 1
    documents = []
    currencyFactor = 1
    remoteAddress = ""

    // set the following values during import:
    customerId = ""
    paymentId = ""
    dispatchId = ""
    shopId = ""
    invoiceAmount = null
    invoiceAmountNet = null
    orderTime = ""
    currency = ""
    paymentStatusId = ""
    orderStatusId = ""

    details = []
    billing = new AddressDetails();
    shipping = new AddressDetails();

    additionalFields = {}

    calculateInvoiceAmount() {
        let invoiceAmount = 0
        let invoiceAmountNet = 0
        this.details.forEach(orderline => {
            const orderlineAmount = orderline.price * orderline.quantity;
            invoiceAmount += orderlineAmount
            invoiceAmountNet += orderlineAmount / (1 + (orderline.taxRate/100))
        })
        this.invoiceAmount = invoiceAmount
        this.invoiceAmountNet = invoiceAmountNet
    }


}

export { ShopwareOrder, OrderLine, AddressDetails }