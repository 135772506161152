import React, { Component } from "react";
import ChallengeCard from "../ChallengeCard";
import {
  Container,
  Columns,

} from "react-bulma-components/dist";

class ChallengeCardList extends Component {
  render() {
    return (
      <Container>
        <Columns>
          {this.props.challenges.map((challenge) => (
            <Columns.Column className="is-one-third" key={challenge.title}>
              <ChallengeCard
                onClick={() => this.props.onChallengeClick(challenge)}
                challenge={challenge}
              />
            </Columns.Column>
          ))}
        </Columns>
      </Container>
    );
  }
}

export default ChallengeCardList;
