import {observer} from "mobx-react";
import React, {Component} from "react";
import {observable} from "mobx";
import FetchView from "../../dumb/FetchView/FetchView";
import {Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Tabs, Container, Button} from 'react-bulma-components/dist'
import NavigationBar from "../NavigationBar/NavigationBar";
import moment from 'moment';
import {B2BOrderStore, B2BPricesStore} from "../../../stores";
import {Input} from "react-bulma-components/lib/components/form";

@observer
class DeliveryDashboard extends Component {
    @observable localstate = {
        refresh: null,
        search: ""
    };

    onChange(k, v) {
        this.localstate[k] = v;
    }

    applyFilter(order) {
        let {search} = this.localstate;
        if (!search) return true;

        search = search.toLowerCase();
        const {referenceNumber,ordernumber,internalNote} = order;
        const customer = B2BPricesStore.list.find(x => x.customer_id === order.userId);
        if (
            (customer && customer.customer.toLowerCase().indexOf(search) >= 0) ||
            referenceNumber.toLowerCase().indexOf(search) >= 0 ||
            ordernumber.toLowerCase().indexOf(search) >= 0 ||
            internalNote.toLowerCase().indexOf(search) >= 0
        ) return true;

        return false;
    }

    render() {
        const {refresh,search} = this.localstate;
        return (<div>
            <NavigationBar title='B2B Orders Overview'>
            </NavigationBar>
        <Container style={{marginTop: 20, marginBottom: 20}}><FetchView stores={[B2BOrderStore, B2BPricesStore]} refresh={refresh}>
            <Container style={{overflowX: 'scroll'}}>
                <div> <Input
                    onChange={(e) => this.onChange('search', e.target.value)}
                    value={search}
                    placeholder="Search for Reference Number, Order Number or Customer"
                /></div>
                <Table aria-label="simple table" stickyHeader style={{marginTop: '15px'}} >
                    <TableHead >
                        <TableRow>
                            <TableCell>
                                Datum
                            </TableCell>
                            <TableCell>
                                OrderNr
                            </TableCell>
                            <TableCell>
                                Total
                            </TableCell>
                            <TableCell>
                                Customer
                            </TableCell>
                            <TableCell>
                                Reference Number
                            </TableCell>
                            <TableCell>
                                Internal Comment
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {B2BOrderStore.list.filter(this.applyFilter.bind(this)).sort(this.sortList).map(item => {
                            const customer = B2BPricesStore.list.find(x => x.customer_id === item.userId);
                            return <TableRow>
                                <TableCell>{moment(item.createdAt).format('D.M.Y')}</TableCell>
                                <TableCell><a target="_blank" href={`https://kor.toolbox.deltatier.cloud/sales/order/?perPage=10&order_listing%5Bsearch%5D=${item.ordernumber}`}>{item.ordernumber}</a></TableCell>
                                <TableCell>{item.total}€</TableCell>
                                <TableCell>{customer ? customer.customer : "Removed"}</TableCell>
                                <TableCell>{item.referenceNumber}</TableCell>
                                <TableCell>{item.internalNote}</TableCell>

                            </TableRow>

                    })}
                </Table>
            </Container>
        </FetchView></Container></div>);
    }

}

export default DeliveryDashboard;