import React, {Component} from 'react';
import {observer} from "mobx-react";
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container, Button, Section} from "react-bulma-components/dist";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {Input} from "react-bulma-components/lib/components/form";
import RestClient from "../../../shared/Network/RestClient";
import {toast} from "react-toastify";
import countryCodes from '../../../data/ressources/CountryCodes.json';

@observer
class CommercialInvoiceBuilder extends Component {
    @observable localstate = {
        orderNr: '',
        pixiOrder: null,
        loading: false
    };

    buildPDFTitle(doc, start, invoiceDate, invoiceNumber) {
        doc.autoTable({
            startY: start,
            head: [[{
                content: 'Commercial Invoice KoRo Handels GmbH',
                colSpan: 2,
                styles: { halign: 'center', fontSize: '14' },
            }]],
            body: [['Date '+ invoiceDate, 'Invoice No: '+ invoiceNumber]],
            bodyStyles: {lineWidth: 0},
            columnStyles: {1: {halign: 'right'}},
            tableLineWidth: 0,
            theme: 'plain'
        })
    }

    buildAddressPart(doc, recipient, includeConsignee=false) {
        const koroAddress = {name: 'KoRo Handels GmbH', address: 'Hauptstr. 26', city: '10827 Berlin',
            country: 'Germany', phone: '+49 (0)30 921079072', contact: 'Florian Schwenkert'}
        const addressFields = {name: 'Name:', address: 'Address:', city: 'City/State/ZIP:',
            country: 'Country:', phone: 'Phone:', contact: 'Contact Person:'}

        let head = includeConsignee
            ? ['', 'Exporter', 'Consignee', 'Delivery Address']
            : ['', 'Exporter', 'Delivery Address']

        let body = []
        for (const [key, value] of Object.entries(addressFields)) {
            let row = [value, koroAddress[key]]
            if (includeConsignee) {
                row.push(koroAddress[key])
            }
            row.push(recipient[key] || '')
            body.push(row)
        }

        doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            head: [head],
            body: body,
            bodyStyles: {lineWidth: 0, overflow: "linebreak"},
            columnStyles: {0: {fontStyle: 'bold'}},
            tableLineWidth: 0.5,
            tableLineColor: 150,
            theme: 'plain',
            didDrawCell: (HookData) => {
                if (HookData.row.section === 'body' && HookData.cell.raw === "") {
                    const textField = new jsPDF.API.AcroFormTextField();
                    textField.Rect = [HookData.cell.x+4, HookData.cell.y+2.5, 150, 15];
                    textField.fontSize = 10
                    textField.fontName = 'helvetica'
                    textField.defaultValue=""
                    doc.addField(textField);
                }
            }
        })
    }

    buildSingleBox(doc, start, title, value='', marginLeft = 0, marginRight = 0, addFormField=false, defaultValue=""){
        const addFormFunction = addFormField ? (HookData) => {
            if (HookData.row.index === 1) {
                const textField = new jsPDF.API.AcroFormTextField();
                textField.Rect = [HookData.cell.x+4, HookData.cell.y+2.5, 100, 15];
                textField.fontSize = 10
                textField.fontName = 'helvetica'
                textField.defaultValue = defaultValue
                doc.addField(textField);
            }
        } : () => {}

        let margin = {}
        if(marginLeft && marginLeft > 0){
            margin.left = marginLeft
        }
        if(marginRight && marginRight > 0){
            margin.right = marginRight
        }

        doc.autoTable({
            startY: start,
            body: [[title], [value]],
            bodyStyles: {lineWidth: 0, fillColor: 255},
            tableLineWidth: 0.5,
            tableLineColor: 150,
            margin: margin,
            theme: 'plain',
            alternateRowStyles: {fontStyle: 'bold'},
            didDrawCell: addFormFunction
        })
    }

    buildVatAndEoriLines(doc, currency, nrOfUnits, isForUK){
        const width = 595
        const spaceDistribution = isForUK ? [3, 2, 2, 2, 3] : [1, 1, 1, 1]
        const boxWidth = width / spaceDistribution.reduce((a, b) => a + b, 0)

        const sumSpaceRightOf = (index) => {return spaceDistribution.reduce((a, b, i) => i>index ? a+b : a, 0) * boxWidth}
        const sumSpaceLeftOf = (index) => {return spaceDistribution.reduce((a, b, i) => i<index ? a+b : a, 0) * boxWidth}

        const box0Right = sumSpaceRightOf(0)
        const box1Left = sumSpaceLeftOf(1)
        const box1Right = sumSpaceRightOf(1)
        const box2Left = sumSpaceLeftOf(2)
        const box2Right = sumSpaceRightOf(2)
        const box3Left = sumSpaceLeftOf(3)
        const box3Right = sumSpaceRightOf(3)

        const VAT = isForUK ? 'GB367701090' : 'DE281415364'
        const EORI = isForUK ? 'GB367701090000' : 'DE397662039694438'

        let start = doc.lastAutoTable.finalY
        this.buildSingleBox(doc, start, 'VAT No.', null, null, box0Right, true, VAT)
        this.buildSingleBox(doc, start, 'Gross Weight', null, box1Left, box1Right, true)
        this.buildSingleBox(doc, start, 'Transportation', null, box2Left, box2Right, true, 'Road')
        this.buildSingleBox(doc, start, 'Terms of Sale', null, box3Left, box3Right, true, 'DDP')

        let endOfFirstRow = doc.lastAutoTable.finalY
        this.buildSingleBox(doc, endOfFirstRow, 'EORI No. ', null, null, box0Right, true, EORI)
        this.buildSingleBox(doc, endOfFirstRow, 'Total # of pallets', null, box1Left, box1Right, true)
        this.buildSingleBox(doc, endOfFirstRow, 'Total # of units', null, box2Left, box2Right, true, nrOfUnits)
        this.buildSingleBox(doc, endOfFirstRow, 'Currency', null, box3Left, box3Right, true, currency)

        if(isForUK){
            doc.autoTable({
                startY: start,
                body: [['REX No.'], [{content: 'DEREX21500137', styles: { minCellHeight: 64.5, valign: 'middle', halign: 'center' }}]],
                bodyStyles: {lineWidth: 0, fillColor: 255},
                margin: { left: sumSpaceLeftOf(4)},
                theme: 'plain',
                alternateRowStyles: {fontStyle: 'bold'}
            })
        }
    }

    buildArticleListing(doc, articleData){
        const addFormFunction = (HookData) => {
            if (HookData.row.section === 'body' && HookData.column.dataKey === 2 && (!HookData.cell.raw || HookData.cell.raw === '')) {
                const textField = new jsPDF.API.AcroFormTextField();
                textField.Rect = [HookData.cell.x+4, HookData.cell.y+2.5, 90, 15];
                textField.fontSize = 10
                textField.fontName = 'helvetica'
                doc.addField(textField);
            }
        }
        doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            head: [[
              {content: 'Commodity Description', styles: {minCellWidth: 100}},
                {content: 'SKU', styles: {minCellWidth: 80}},
                {content: 'Country of Origin', styles: {minCellWidth: 100}},
                {content: 'HS-Code', styles: {minCellWidth: 70}},
                {content: 'Quantity', styles: {minCellWidth: 50}},
                {content: 'Unit Price', styles: {minCellWidth: 40}},
                {content: 'Total Amount', styles: {minCellWidth: 50}}]],
            body: articleData,
            theme: 'plain',
            headStyles: {lineWidth: 0.5, lineColor: 150, valign: 'middle', halign: 'center'},
            bodyStyles: {lineWidth: 0.5, lineColor: 150, valign: 'middle', halign: 'center'},
            styles: {overflow: "linebreak", lineWidth: 1, lineColor: 'black'},
            rowPageBreak: 'avoid',
            pageBreak: 'auto',
            didDrawCell: addFormFunction
        })
    }

    buildWeightsListing(doc, data, totalWeight = '0', totalPrice = '0', currency='') {
        const totalWeightRow = [
            [{content: 'Total Net Weight: ', styles: {fontStyle: 'bold', halign: 'center'}}, totalWeight + ' kg']
        ]

        let bodyData = data.concat(totalWeightRow)

        doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            body: bodyData,
            bodyStyles: {overflow: "linebreak", lineWidth: 0.5, lineColor: 150, fillColor: 255},
            tableLineWidth: 0.5,
            tableLineColor: 150,
            pageBreak: 'avoid',
            rowPageBreak: 'avoid',
            columnStyles: {1: {valign: 'middle', halign: 'center'}, 2: {fontStyle: 'bold', valign: 'middle', halign: 'center'}},
            theme: 'plain',
            margin: { right: 180 }
        })
        doc.setFontSize(10).setFont(undefined, 'bold')
        doc.text(450, doc.lastAutoTable.finalY -30, 'Total Invoice Value: ').setFont(undefined, 'normal');
        doc.text(450, doc.lastAutoTable.finalY -10, totalPrice + ' ' + currency)
    }

    buildStatement(doc){
        doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            theme: 'plain',
            pageBreak: 'avoid',
            body: [["I/we hereby certify that the information on this invoice is true and correct and that the contents of this shipment are as stated above.\n" +
            "Der Ausführer der Waren, auf die sich dieses Handelspapier bezieht, erklärt, dass diese Waren, soweit nicht anders angegeben, präferenzbegünstigte EU Ursprungswaren sind."]]
        })
    }

    buildSignatureBlock(doc){
        doc.autoTable({
            startY: doc.lastAutoTable.finalY,
            head: [['Name', 'Signature', 'Date']],
            body: [['','','']],
            bodyStyles: { minCellHeight: 40, lineWidth: 0, valign: 'middle', halign: 'center' },
            tableLineColor: 150,
            tableLineWidth: 0.5,
            pageBreak: 'avoid',
            theme: 'plain',
            didDrawCell: (HookData) => {
                if (HookData.row.section === 'body' && HookData.column.dataKey === 0) {
                    const textField = new jsPDF.API.AcroFormTextField();
                    textField.Rect = [HookData.cell.x+5, HookData.cell.y+10, 120, 15];
                    textField.fontSize = 10
                    textField.fontName = 'helvetica'
                    textField.defaultValue=""
                    doc.addField(textField);
                }
                if (HookData.row.section === 'body' && HookData.column.dataKey === 2) {
                    const textField = new jsPDF.API.AcroFormTextField();
                    textField.Rect = [HookData.cell.x+5, HookData.cell.y+10, 120, 15];
                    textField.fontSize = 10
                    textField.fontName = 'helvetica'
                    textField.defaultValue= new Date().toLocaleDateString('en-GB')
                    doc.addField(textField);
                }
            }
        })
    }

    async getArticleData(isForUK) {
        const { pixiOrder } = this.localstate

        let orderlines = Array.isArray(pixiOrder.Orderlines.row) ? pixiOrder.Orderlines.row : [pixiOrder.Orderlines.row]
        orderlines = orderlines.filter(orderline => !orderline.BundleOrderlineRef)

        if(pixiOrder.BundleOrderlines){
            const bundleOrderlines = Array.isArray(pixiOrder.BundleOrderlines.row) ? pixiOrder.BundleOrderlines.row : [pixiOrder.BundleOrderlines.row]
            orderlines = orderlines.concat(bundleOrderlines)
        }

        orderlines.sort((first, second) => {
            const firstSKU = first.ItemNrInt._text
            const secondSKU = second.ItemNrInt._text
            if(firstSKU < secondSKU){
                return -1
            }else if(firstSKU > secondSKU){
                return 1
            }else{
                return 0
            }
        })

        let articleInfo = []
        let weightInfo = []
        let totalPrice = 0
        let totalWeight = 0
        let totalNrUnits = 0
        for(let orderline of orderlines) {
            if(orderline.Status._text !== 'STO') {
                const sku = orderline.ItemNrInt._text
                const quantity = parseInt(orderline.Qty._text)
                const sellingPrice = parseFloat(orderline.Price._text)
                const supplierPrice = parseFloat(orderline.SupplPrice._text)
                const price = sellingPrice === 0 ? supplierPrice : sellingPrice

                const request = RestClient.prepareRequest('GET', 'commercialInvoices/articleInfo/' + sku);
                const swArticle = (await RestClient.sendRequest(request)).data;
                const name = (isForUK && swArticle.englishName !== '') ? swArticle.englishName : swArticle.germanName

                let originData = swArticle.originCode;
                let originArray = [];
                let origin = '';
                if (originData) {
                    originArray = originData.split(',');
                }
                for(let originCode of originArray) {
                    const countryCodeData = countryCodes.find(c => c.code === originCode.toLowerCase())
                    if(countryCodeData){
                        if(origin === '') {
                            origin = isForUK ? `${countryCodeData.nameEN}` : `${countryCodeData.nameDE}`;
                        } else {
                            origin = isForUK ? `${origin}, ${countryCodeData.nameEN}` : `${origin}, ${countryCodeData.nameDE}`;
                        }
                    }
                }
                
                const hsCode = swArticle.hs_code

                const weightUnit = swArticle.weightUnit.toLowerCase()
                const netWeight = parseFloat(swArticle.netWeight.toString().replace(',', '.'))
                let weight = swArticle.grossWeightKG

                if(['g', 'ml'].includes(weightUnit)){
                    weight = netWeight / 1000
                }else if(['kg', 'l'].includes(weightUnit)){
                    weight = netWeight
                }

                weight *= quantity
                totalPrice += price * quantity
                totalWeight += weight
                totalNrUnits += quantity

                const infoData = [name, sku, origin, hsCode, quantity, price.toFixed(2), (price * quantity).toFixed(2)]
                articleInfo.push(infoData)

                const weightData = [name, weight.toFixed(2) + ' kg']
                weightInfo.push(weightData)
            }
        }
        return {articleInfo: articleInfo, totalPrice: totalPrice.toFixed(2), weightInfo: weightInfo, totalWeight: totalWeight.toFixed(2), totalNrUnits: totalNrUnits}
    }

    async exportPDF() {
        try {
            const {pixiOrder} = this.localstate
            const doc = new jsPDF("portrait", "pt", "A4", {filters: ["ASCIIHexEncode"]});

            const shippingAddress = Array.isArray(pixiOrder.ShippingAddress.row) ? pixiOrder.ShippingAddress.row[0] : pixiOrder.ShippingAddress.row
            const isForUK = shippingAddress.CountryCodeISO2._text === 'GB'
            const invoice = Array.isArray(pixiOrder.Invoices.row) ? pixiOrder.Invoices.row[0] : pixiOrder.Invoices.row

            const invoiceDate = new Date(invoice.InvDate._text).toLocaleDateString('en-GB')
            this.buildPDFTitle(doc, 20, invoiceDate, invoice.InvoiceNr._text)


            this.buildAddressPart(doc,
              {
                  name: shippingAddress.Company?._text ? shippingAddress.Company._text : shippingAddress.Name?._text,
                  address: shippingAddress.Address?._text,
                  city: shippingAddress.City?._text + ' ' + (shippingAddress.State?._text ? shippingAddress.State._text + ' ' : '') + shippingAddress.ZIP?._text,
                  country: shippingAddress.CountryName?._text,
                  phone: shippingAddress.Phone?._text ? shippingAddress.Phone._text : null,
                  contact: shippingAddress.Name?._text === shippingAddress.Company._text ? null : shippingAddress.Name?._text
              }, isForUK)

            const articleData = await this.getArticleData(isForUK)
            this.buildVatAndEoriLines(doc, invoice.OrderCurrency._text, articleData.totalNrUnits, isForUK)
            this.buildArticleListing(doc, articleData.articleInfo)
            this.buildWeightsListing(doc, articleData.weightInfo, articleData.totalWeight, articleData.totalPrice, invoice.OrderCurrency._text)

            this.buildStatement(doc)
            this.buildSignatureBlock(doc)

            const totalPageCount = doc.internal.getNumberOfPages();
            for (let i = 0; i < totalPageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(10);
                doc.text("Page " + pageCurrent + ' of ' + totalPageCount, 500, 820);
            }
            doc.save("KoRo_CommercialInvoice.pdf")
        }catch(e) {
            toast.error("An error occurred while building the PDF. Please check if you used the correct Shopware Order ID")
            toast.error(e.message)
        }
    };

    async findOrder() {
        this.localstate.loading = true
        const orderNr = this.localstate.orderNr
        const requestOrder = RestClient.prepareRequest('GET', 'commercialInvoices/pixiOrder/' + orderNr);
        const resultOrder = await RestClient.sendRequest(requestOrder)
        const emptyResult = Array.isArray(resultOrder.data) && resultOrder.data.length === 0
        if(resultOrder.success && !emptyResult && resultOrder.data){
            this.localstate.pixiOrder = resultOrder.data
            await this.exportPDF()
        }else{
            toast.error("Could not retrieve data about this order id.")
        }
        this.localstate.loading = false
    }

    render() {
        return(
            <div>
                <NavigationBar title = "Commercial Invoice Builder"/>
                <Container style={styles.container}>
                    <Container >
                        <Section style={{textAlign: 'center'}}>
                            <Input
                                style={{marginBottom: '10px', width: '300px'}}
                                value = {this.localstate.orderNr}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange = {e => this.localstate.orderNr = e.target.value}
                                placeholder='Order ID'
                            />
                            <br/>
                            <Button onClick={() => this.findOrder()}
                                    color='primary'
                                    style={{width: '300px'}}
                                    disabled={this.localstate.orderNr === ''}
                                    loading={this.localstate.loading}
                            >
                                Make Commercial Invoice PDF
                            </Button>
                        </Section>
                    </Container>
                </Container>
            </div>
        )
    }
}


const styles ={
    exportbutton: {
        marginRight: "6pt"
    },
    matchingTable:{
        width: "100%",
        maxWidth: "800px"
    },
}

export default CommercialInvoiceBuilder;