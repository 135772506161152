import {Button, Section,Heading} from 'react-bulma-components/dist'
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import React from "react";
import {observable, autorun} from 'mobx';
import {observer} from "mobx-react";
import {ProductStore, SupplierStore} from "../../../../stores/SourcingTool";
import Select from 'react-select';
import FetchView from "../../../dumb/FetchView/FetchView";
import _ from "lodash"
import {toast} from "react-toastify";

@observer
class SupplierEdit extends React.Component {
    @observable localstate = {
        supplier: {
            id: null,
            Name:'',
            Language: 'DE',
            ContactName: '',
            ContactMail: '',
            ContactGender: ''
        },
        loading: false
    };

    componentDidMount() {
        autorun(() => {
            if (this.props.supplier.Name && !_.isEqual(this.localstate.supplier, this.props.supplier)) {
                this.localstate.supplier = {...this.props.supplier};
                this.localstate.supplier.Language = this.localstate.supplier.Language || 'DE';
            }
        });
    }

    onChange(k, v) {
        if(this.props.supplier) {
            this.props.supplier[k] = v;
        }
        this.localstate.supplier[k] = v;
    }

    async save() {
        const {supplier} = this.localstate;
        this.localstate.loading = true;
        let success;
        if (supplier.id !== null && supplier.id !== undefined) {
            // update existing supplier
            success = await SupplierStore.updateItem(supplier);
        } else {
            // create new supplier
            success = await SupplierStore.createItem(supplier);
        }
        this.localstate.loading = false;
        console.log(success);
        if (success && this.props.onSave) {
            this.props.onSave();
        }
        if (success.success === false){
            toast.error("An error occurred. " + success.error.message.name)
        } else {
            toast.success("Supplier has been created successfully")
        }
    }

    render() {
        const {supplier} = this.localstate;
        return (
            <div>
                <FetchView store={ProductStore}>
                    <Section>
                        <Heading>{supplier.id ? 'Lieferant bearbeiten' : 'Lieferant anlegen'}</Heading>
                        <Field>
                            <Label>Firma</Label>
                            <Control  >
                                <Input type='text' value={supplier.Name} onChange={(e) => this.onChange('Name', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Sprache</Label>
                            <Control  >
                                <Select options={languageOptions}
                                        value={supplier.Language === 'EN' ?  languageOptions[1] : languageOptions[0]}
                                        onChange={(lang) => this.onChange('Language',lang.value)}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Ansprechpartner</Label>
                            <Control>
                                <Input type='text' value={supplier.ContactName} onChange={(e) => this.onChange('ContactName', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Mailadresse</Label>
                            <Control>
                                <Input type='text' value={supplier.ContactMail} onChange={(e) => this.onChange('ContactMail', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Contact Gender</Label>
                            <Control>
                                <Select
                                    options={GenderOptions}
                                    value={GenderOptions.find(x => x.value === this.localstate.supplier.ContactGender)}
                                    onChange={(gen) => this.onChange('ContactGender', gen.value)}
                                />
                            </Control>
                        </Field>
                        <Button fullwidth loading={this.localstate.loading} onClick={this.save.bind(this)}>Speichern</Button>
                    </Section>
                </FetchView>
            </div>
        );
    }
}



const languageOptions = [{
    value: 'DE',
    label: 'DE'
}, {
    value: 'EN',
    label: 'EN'
}];

const GenderOptions = [
    {value: 'male', label: 'male'},
    {value: 'female', label: 'female'},
    {value: 'neutral', label: 'neutral'}
]

export default SupplierEdit;
