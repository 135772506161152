import RestClient from "../../../shared/Network/RestClient";
import {toast} from "react-toastify";

const ReservationService = {
    delete: async function(id) {
        const request = RestClient.prepareRequest('DELETE', 'stockReservations/'+id);

        try{
            return await RestClient.sendRequest(request)
        }catch(e) {
            toast.error("An error occurred. " + e)
        }
    },
    create: async function(reservation) {
        const request = reservation.buildCreateRequest();

        try {
            return await RestClient.sendRequest(request);
        } catch (e) {
            toast.error("An error occurred." + e)
        }
    },
    update: async function(reservation) {
        const request = reservation.buildUpdateRequest()

        try{
            return await RestClient.sendRequest(request)
        }catch(e) {
            toast.error("An error occurred. " + e)
        }
    },
    getAll: async function() {
        const request = RestClient.prepareRequest('GET', 'stockReservations');

        try{
            const response = await RestClient.sendRequest(request);
            if(!response.success){
                throw(response.error)
            }else{
                return response.data
            }
        }catch(e) {
            toast.error("An error occurred. " + e)
        }
    },

};

export default ReservationService;