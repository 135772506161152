import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import NavigationBar from "../../NavigationBar/NavigationBar";
import FetchView from "../../../dumb/FetchView/FetchView";
import { UserStore } from "../../../../stores/UserStore";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { MarketStallStatementStore } from "../../../../stores/Markets/MarketStallStatementStore";
import { MarketEmployeeStore } from "../../../../stores/Markets/MarketEmployeeStore";
import { MarketStore } from "stores";
import { Button, Container } from "react-bulma-components";

import StatementApproval from "./StatementApproval";
import { MarketStatementApprovalStore } from "../../../../stores/Markets/MarketStatementApprovalStore";
import { FaCheck } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";

@observer
class MarketDailyStatement extends Component {
  @observable localstate = {
    status: {
      edit: false,
    },
    form: null,
    market: null,
    approval: null,
  };

  clearStates() {
    this.localstate.status.edit = false;
    this.localstate.approval = null;
  }

  showApprovalWindow(statement, market, approval) {
    if (approval) {
      this.localstate.approval = approval;
    }
    this.localstate.form = statement;
    this.localstate.market = market;
    this.localstate.status.edit = true;
  }

  render() {
    const { edit } = this.localstate.status;
    const { form, market, approval } = this.localstate;
    return (
      <div>
        <NavigationBar title="Markets Statement"></NavigationBar>
        <FetchView store={UserStore}>
          <Container style={styles.container}>
            <FetchView store={MarketStallStatementStore}>
              <FetchView store={MarketEmployeeStore}>
                <FetchView store={MarketStore}>
                  <FetchView store={MarketStatementApprovalStore}>
                    <Table
                      style={{
                        paddingTop: "15px",
                        textAlign: "center",
                        width: "100%",
                      }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Nr</TableCell>
                          <TableCell>Datum</TableCell>
                          <TableCell>Markt</TableCell>
                          <TableCell>Bestätigung?</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>

                      {MarketStallStatementStore.list.map((item, key) => {
                        const market = MarketStore.list.find(
                          (market) => market.id === item.MarketId
                        );
                        const approved = MarketStatementApprovalStore.list.find(
                          (approval) => approval.MarketStallStatementId === item.id
                        );
                        return (
                          <TableRow key={key}>
                            <TableCell>{key + 1}</TableCell>
                            <TableCell>
                              {item.marketDate.split("T")[0]}
                            </TableCell>
                            <TableCell>{market && market.name}</TableCell>
                            <TableCell>
                              {approved && approved.approved ? (
                                <FaCheck color="#23d160" size={20} />
                              ) : approved && approved.approved === false ? (
                                <RiCloseCircleLine color="#ff3860" size={20} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={approved && true}
                                onClick={() =>
                                  this.showApprovalWindow(item, market)
                                }
                              >
                                Check
                              </Button>
                              {approved && (
                                <Button
                                  onClick={() =>
                                    this.showApprovalWindow(
                                      item,
                                      market,
                                      approved
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                    {edit && (
                      <StatementApproval
                        edit={edit}
                        form={form}
                        market={market}
                        approval={approval}
                        clearStates={() => this.clearStates()}
                      />
                    )}
                  </FetchView>
                </FetchView>
              </FetchView>
            </FetchView>
          </Container>
        </FetchView>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 0,
    margin: 0,
    marginInline: "auto",
    width: "100%",
  },

  receiptWrapper: {
    display: "flex",
    alignItems: "baseline",
    cursor: "default",
  },
  receiptIconSuccess: {
    color: "#23d160",
    fontSize: "1.15rem",
    marginLeft: ".25em",
    cursor: "pointer",
  },
  receiptIconDanger: {
    width: "100%",
    textAlign: "center",
    fontSize: "1.5rem",
    color: "#ff3860",
  },
};

export default MarketDailyStatement;
