import React, {Component}  from "react";
import {observable, autorun} from 'mobx';
import {observer} from 'mobx-react';
import Loader from 'react-bulma-components/lib/components/loader';

@observer
class FetchView extends Component {
    @observable localstate = {
        loading: true,
        error: null,
        refresh: null
    };
    refresh = null;

    componentDidMount() {
      this.refresh = JSON.stringify(this.props.refresh);
      this.loadData();

      autorun(async () => {
          if (this.refresh !== JSON.stringify(this.props.refresh)) {
              this.refresh = JSON.stringify(this.props.refresh);
              this.localstate.refresh = true;
              await this.loadData();
              this.localstate.refresh = false;
              console.log("fetchview autorun triggered")
          }
      })
    }

    async loadData() {
        this.localstate.loading=true;
        this.localstate.error = null;
        const {store, stores, call} = this.props;
        try {
            if (stores) {
                for (const st of stores) {
                    await this.loadStore(st);
                }
            }
            else if (store) {
                await this.loadStore(store, true);
            } else if (call) {
                await call();
            }
            this.localstate.loading = false;
        } catch (e) {
            this.localstate.error = e;
            this.localstate.loading = false;
        }
    }

    async loadStore(store, force = false) {
        if (!store.status.fetched || force) {
            await store.fetchData(true);
        }
    }

    render() {
        const {children = null, showSpinner = true} = this.props;
        const {loading, error, refresh} = this.localstate;
        if (loading && !refresh) {
            return (<div style={styles.loadingContainer}>{showSpinner && <Loader style={styles.loader} />}</div>);
        } else if (error) {
            return <div style={styles.error}>
                <b>An Error Occurred</b>
                <br/>{error.message}<br/>
                <input onClick={this.loadData.bind(this)} type="button" value="Try again" />
            </div>;
        }  else {
            return children;
        }

    }

}

const styles = {
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    error: {
      color: 'red',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    loader: {
        width: 150,
        height: 150
    }
}

export default FetchView;