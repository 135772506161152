import React, {Component} from 'react';
import {getArticleTranslationsForLanguage} from '../../../../stores/ArticlesTranslations';
import FetchView from "../../../dumb/FetchView/FetchView";
import {ArticleAttributesStore} from '../../../../stores/index';

import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Button, Container,Box} from 'react-bulma-components/dist'
import {Control, Field} from "react-bulma-components/lib/components/form";
import ArticleTranslation from "../TranslationForms/ArticleTranslation";
import ArticleDefinitions from '../Definitions/articles';
import NavigationBar from "../../NavigationBar/NavigationBar";
import Select from "react-select";
import {toast} from "react-toastify";
import languages from '../Definitions/languages';
import SnippetDefinition from '../Definitions/snippets';
import PageDefinition from '../Definitions/pages';

import RestClient from "../../../../shared/Network/RestClient";

const DEFINITIONS = {
  article: {
      label: "Product",
      structure: ArticleDefinitions,
      mainIdentifier: 'ordernumber'
  },
  snippet: {
      label: "Snippet",
      structure: SnippetDefinition,
      mainIdentifier: 'name',
      includeInPost: ['namespace', 'name']
  }, page: {
        label: "Page",
        structure: PageDefinition,
        mainIdentifier: 'description',
        includeInPost: ['id']
  }

};

@observer
class TranslationScreen extends Component {
    translator = null;

    @observable localstate = {
        language: null,
        translationStore: null,
        compareSnippetStore: null,
        snippetStore: null,
        toBeTranslated: [],
        currentForm: null,
        autoTranslate: false,
        onlyShowMissingFields: true,
        form:{
            language: null
        }
    };

    async editTranslation(article, translation) {
        const translated = translation || {name: ''};
        this.localstate.translate = {article, translated};
    }

    constructor(props) {
        super(props);
    }

    isCompleteTranslation(item, translation) {
        let complete = true;
        Object.keys(ArticleDefinitions).forEach(key => {
            if (!translation[key] && item[key]) {
                complete = false;
                return false;
            }
        });
        return complete;
    }

    async getChangedArticles(language){
        const requiredFields = Object.keys(ArticleDefinitions).join(',')
        const url = 'translations/language/' + language.toString() + '/article_change?required_fields=' + requiredFields
        const request = RestClient.prepareRequest('GET', url)

        //const body = {requiredFields: requiredFields}
        try {
            const response = await RestClient.sendRequest(request)
            return response.data
        } catch (e) {
            toast.error('An error occurred' + e)
        }
    }

    async init() {
        const {language} = this.localstate;

        this.localstate.translationStore = getArticleTranslationsForLanguage(language.value);



        await this.localstate.translationStore.fetchData(true);
        await ArticleAttributesStore.fetchData();

        let toTranslate = [];

        // next up: articles with the highest sales
        const ArticleChanges = await this.getChangedArticles(language.value)
        ArticleAttributesStore.list.sort(this.sortBySales).forEach(item => {
            const translation = this.localstate.translationStore.list.find(x => x.articleID === item.id);
            const articleChange = ArticleChanges.find(change => change.articleID === item.id);
            if (articleChange && translation){
                // TODO: Disabled for now, waiting for bugfix
            } else if (!translation || !this.isCompleteTranslation(item, translation)) {
                toTranslate.push({type: 'article', item, translation, store: this.localstate.translationStore});
            }
        });
        
        console.log(toTranslate);
        this.localstate.toBeTranslated= toTranslate;
        if (toTranslate.length > 0){
            this.nextForm();
        }
    }

    sortBySales(a, b) {
        if (a.sales<b.sales) {
            return 1;
        }
        if (a.sales>b.sales) {
            return -1;
        }
        // a must be equal to b
        return 0;
    }


    nextForm() {
        this.forceUpdate();
    }

    async onSkip() {
        toast.info('Skipped the translation. It will show up again next time. ');
        this.localstate.toBeTranslated.shift();
        this.nextForm();
    }

    async onSave(body, issues) {
        try {
            const {toBeTranslated} = this.localstate;
            const store = toBeTranslated[0].store;
            let bodyToSend = body;

            // merge params from the item to be translated to help identify it in the backend
            const def = DEFINITIONS[toBeTranslated[0].type];
            if (def.includeInPost) {
                def.includeInPost.forEach(key => {
                    bodyToSend[key] = toBeTranslated[0].item[key];
                });
            }

            const response = await store.createItem(bodyToSend);
            if (response.success) {
                toast.success('Saved the translation! Good job :)');
                if (issues.length > 0) {
                    toast.info('Not all fields were filled in. The missing fields will show up again next time.');
                }
                this.localstate.toBeTranslated.shift();
                this.nextForm();
            } else {
                throw new Error('Could not save the translation - please try again');
            }
        } catch (e) {
            toast.error(e);
        }
    }

    onChange(k, v) {
        this.localstate[k] = v;
    }

    handleLangSelect(language){
      this.localstate.form.language = language;
    }

    startTranslating() {
        this.localstate.toBeTranslated=[];
        if (!this.localstate.form.language) {
            return toast.error('Please select the translation language');
        }
        this.localstate.language = this.localstate.form.language;
        toast.success('Setting the scene, this can take a few seconds');
    }

    render() {
        const {toBeTranslated, autoTranslate, onlyShowMissingFields, language} = this.localstate;

        let nextUp =null;
        let definition = null;
        let visibleIdentifier = null;
        let showFields = null;
        if (toBeTranslated.length > 0) {
            nextUp = toBeTranslated[0];
            if (!onlyShowMissingFields){
                showFields = 'all'
            } else if (nextUp.fields){
                showFields = nextUp.fields
            } else {
                showFields = 'missing'
            }
            definition = DEFINITIONS[nextUp.type];
            console.log(nextUp.item);
            visibleIdentifier = nextUp.item[definition.mainIdentifier].substr(0,20)
        }

        return (
            <div>
                <NavigationBar itemsLeft={language &&  toBeTranslated.length>0 && language.label + ": " + toBeTranslated.length + " translations left"}
                               title =  { toBeTranslated[0] ? 'Translating ' + definition.label + " '" + visibleIdentifier + "'" : 'Translations'}>
                    {language && language.deepl && <label className="checkbox">
                        <input type="checkbox" checked={autoTranslate} onChange={(e) => this.onChange('autoTranslate', e.target.checked)}/>
                        Auto-Translate with Deepl
                    </label>}
                    {language && <label className="checkbox">
                        <input type="checkbox" checked={onlyShowMissingFields} onChange={(e) => this.onChange('onlyShowMissingFields', e.target.checked)}/>
                        Show only missing fields
                    </label> }
                </NavigationBar>
                <Container style={styles.container}>
                    {!language && <div style={styles.languageContainer}>
                        <Box>
                            To get started, please choose the translation language<br /><br />
                            <Field>
                                <Control>
                                    <Select
                                        className = "basic-multi-select"
                                        options = {languages}
                                        onChange = {e => this.handleLangSelect(e)}
                                        value = {languages.find(item => item.value === language)}
                                        placeholder = "Select a language..."
                                    />
                                </Control>
                            </Field>
                            <Button onClick={this.startTranslating.bind(this)} fullwidth color='primary'>Start translating</Button>
                        </Box>
                    </div>}
                    {language && <FetchView call={this.init.bind(this)}>
                        {toBeTranslated.length > 0 ?
                            <ArticleTranslation
                                id={nextUp.item.id}
                                onSkip={this.onSkip.bind(this)}
                                language={language}
                                item={nextUp.item}
                                translation={nextUp.translation}
                                definition={definition.structure}
                                autotranslate={autoTranslate}
                                //onlyShowMissingFields={onlyShowMissingFields}
                                showFields = {showFields} // can take value '[field]' (e.g. 'metaTitle'), 'all', 'missing'
                                onSave={this.onSave.bind(this)}/>
                            : 'All done for now :-) No translations remaining.'}
                    </FetchView>}
                </Container>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
       padding: 20
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    }
}

export default TranslationScreen;