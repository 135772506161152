import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { toast } from 'react-toastify';
import { Container, Box, Button } from 'react-bulma-components/dist'
import { Control, Field, Input, Label } from "react-bulma-components/lib/components/form";
import RestClient from "../../../shared/Network/RestClient";
import Loader from "react-bulma-components/lib/components/loader";

@observer
class Recall extends Component {
    @observable localstate = {
        loading: false,
        orderNr: undefined,
        recallData: []
    }

    constructor(props) {
        super(props);
    }

    async getRecallData() {
        if (this.localstate.orderNr) {
            this.localstate.loading = true;

            const request = RestClient.prepareRequest('GET', `recall/${this.localstate.orderNr}`);

            try {
                const response = await request.send();

                if (response.success) {
                    this.localstate.recallData = response.data;
                } else {
                    toast.error("An error occurred " + response.error);
                    this.localstate.recallData = undefined;
                    console.log(response.error);
                }
            } catch (error) {
                toast.error("An error occurred " + error);
                this.localstate.recallData = undefined;
                console.log(error);
            }

            this.localstate.loading = false;
        } else {
            toast.error("Please provide an order number!");
            this.localstate.recallData = undefined;
        }
    }

    render() {
        const {recallData} = this.localstate;
        return (
            <Container style={styles.container}>
                <Box>
                    Please provide the order number or email address for which you want to retrieve the voucher.
                </Box>

                <Field>
                    <Control>
                        <Input
                            onChange = {e => this.localstate.orderNr = e.target.value}
                            value = {this.localstate.orderNr}
                            placeholder='Order Number or eMail'
                        />
                    </Control>
                </Field>

                <Button
                    onClick={this.getRecallData.bind(this)}
                    fullwidth
                    color='primary'
                >
                    {!this.localstate.loading ? 'Get recall data' : <Loader />}
                </Button>

                {recallData.length > 0 && recallData.map(item => {
                    return (<Box style={styles.dataContainer}>
                        <div style={styles.dataHeading}>
                            Please send the voucher to the customer via email.<br/>
                        </div>


                        <div style={styles.dataRow}>
                            <Label>Rückruf</Label>
                            <div>{item.recall_name}</div>
                        </div>


                        <div style={styles.dataRow}>
                            <Label>Order Number</Label>
                            <div>{item.ordernr}</div>
                        </div>

                        <div style={styles.dataRow}>
                            <Label>Name</Label>
                            <div>{item.name}</div>
                        </div>

                        <div style={styles.dataRow}>
                            <Label>Email</Label>
                            <div>{item.email}</div>
                        </div>

                        <div style={styles.dataRow}>
                            <Label>Voucher</Label>
                            <div style={styles.voucher}>{item.voucher}</div>
                        </div>
                    </Box>);
                })

                }
            </Container>
        );
    }
}

const styles = {
    container: {
        padding: 20,
        width: 'fit-content'
    },
    dataContainer: {
       marginTop: 64
    },
    dataHeading: {
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center'
    },
    dataRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    voucher: {
        fontWeight: 'bold'
    }
};

export default Recall;