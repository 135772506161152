import React, {Component} from 'react';
import {Tabs, Container} from 'react-bulma-components/dist'
import Navigation from "../../../shared/Navigation";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import MarketsOverview from "./MarketsOverview/MarketsOverview";
import MarketStorage from "./MarketStorage/MarketStorage";
import MarketProducts from "./MarketProducts/MarketProducts";
import MarketDailyStatement from "./MarketDailyStatement/MarketDailyStatement";
import MarketEmployees from "./MarketEmployees/MarketEmployees"

const subpages = [{
    "label": "Markets",
    "url": "",
    "component": <MarketsOverview/>
}, {
    "label": "Storage Units",
    "url": "/storages",
    "component": <MarketStorage/>
},{
    "label": "Market Products",
    "url": "/products",
    "component": <MarketProducts/>
},{
    "label": "Market Statement",
    "url": "/statement",
    "component": <MarketDailyStatement />
}, {
    "label": "Market Employee",
    "url": "/employee",
    "component": <MarketEmployees/>
}]

@observer
class Markets extends Component {
    @observable localstate = {
        navigate: null
    };

    navigate(item) {
        Navigation.history && Navigation.history.push('/markets' + item.url);
    }

    render() {
        const url = Navigation.location.pathname;
        let activeItem = null;
        return (<div><Container>
            <Tabs type='toggle' fullwidth={true}>
                {subpages.map(item => {
                    const cleanedUrl= '/markets' + item.url.replace('*', '');
                    const active = (url === '/markets' + item.url || (url.indexOf(cleanedUrl)>-1 && url.length>cleanedUrl.length));
                    if (active) {
                        activeItem = item;
                    }
                    return (<Tabs.Tab key={item.url} onClick={() => this.navigate(item)} active={url === '/markets' + item.url}>
                        {item.label}
                    </Tabs.Tab>)
                })}
            </Tabs>
            <div></div>
        </Container>
            {activeItem && activeItem.component}
        </div>)
    }
}

export default Markets;
