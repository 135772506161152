import RestClient from "./Network/RestClient";
import {observable} from 'mobx';

class Authentication {
    @observable
    status = {
        loading: false,
        error: false,
        loggedIn: false
    };
    @observable
    user = {
      firstName: null,
        lastName: null,
        canAddUsers: null
    };
    token = null;

    constructor() {
       this.init();
    }

    async init() {
        const storage = localStorage.getItem('userData');
        if (storage) {
            await this.setUserData(JSON.parse(storage));
            // refresh user data now
            await this.refreshUserData();

        }
        this.logout = this.logout.bind(this);
    }

    async refreshUserData() {
        const request = RestClient.prepareRequest('GET', 'me');
        const result = await request.send();
        if (result.success) {
            await this.setUserData({user: result.data});
        }
    }

    async setUserData(userData) {
        if (userData.token) {
            this.token = userData.token;
            await localStorage.setItem('userData', JSON.stringify(userData));
        }
        this.user = userData.user;
        this.status.loggedIn = true;
    }

    hasAccess(routeItem) {
        if (this.user.isAdmin) {
            return true;
        }
        if (routeItem) {
            if(routeItem.group.includes("General")) {
                return true
            }
            for (const group of routeItem.group) {
                if (this.user.usergroups && (this.user.usergroups.find(x => x.group === group))) {
                    return true;
                }
            }

            return false;
        } else {
            return true;
        }
    }

    async login(email, password) {
        this.status.loading = true;
        const request = RestClient.prepareRequest('POST', 'auth/login');
        request.setData({email, password});
        try {
            const response = await request.send();
            console.log(response);
            this.status.loading = false;
            if (response.success) {
                await this.setUserData(response.data);
            }
            return response;
        }
        catch (e) {
            this.status.loading = false;
            this.status.error = e;
            return e.message;
        }
    }

    isLoggedIn() {
        return this.status.loggedIn;
    }

    logout() {
        localStorage.setItem('userData', '');
        this.status.loggedIn = false;
        console.log("logged out")
    }
}

export default new Authentication();