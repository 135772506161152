import React, {Component} from 'react';
import {Heading } from 'react-bulma-components/dist'
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";
import Loader from "react-bulma-components/lib/components/loader";
import RegistrationScreen from "./RegistrationScreen";
import DashboardScreen from "./DashboardScreen";

@observer
class AffiliateUserScreen extends Component {
    @observable localstate = {
        registered: false,
        instaName: "",
        loadingPage: false,
        successfulLoad: true,
        userData: {
            fullName: "",
            socialMediaName: "",
            numberOfOrders: null,
            payableAmount: null,
            totalMoneyClaimed: null,
            publicVoucher: "",
            rewardVouchers: ""
        }
    };

    componentDidMount() {
        this.loadUserParams()
    }

    async loadUserParams(){
        const userCode = this.props.match.params.userCode
        const request = RestClient.prepareRequest('GET', 'affiliate-user/' + userCode);
        this.localstate.loadingPage = true

        try{
            const response = await RestClient.sendRequest(request)
            if (response.success){
                this.localstate.registered = response.data.accountName !== null && response.data.name !== null
                this.localstate.userData.fullName = response.data.name
                this.localstate.userData.socialMediaName = response.data.accountName
                this.localstate.userData.numberOfOrders = response.data.ordersGenerated
                let payableAmount = response.data.amountGenerated - parseFloat(response.data.amountPaidOut)
                this.localstate.userData.payableAmount = Math.max (0, Math.round(payableAmount * 100) / 100) // 2 decimals only
                this.localstate.userData.totalMoneyClaimed = parseFloat(response.data.amountPaidOut)
                this.localstate.userData.publicVoucher = response.data.voucherCode
                this.localstate.userData.rewardVouchers = JSON.parse(response.data.rewardVouchers)
            } else{
                this.localstate.successfulLoad = false
            }
        } catch (e){
            toast.error("An error occurred. " + e)
            this.localstate.successfulLoad = false
        }
        this.localstate.loadingPage = false
    }

    render() {
        const {registered, loadingPage, userData, successfulLoad} = this.localstate
        const userCode = this.props.match.params.userCode
        if (loadingPage){
            return (
                <div style={styles.loadingContainer}>
                    <Loader style={styles.loader} />
                </div>
            )
        } else {
            if (successfulLoad){
                if (registered){
                    return(
                        <DashboardScreen userData={userData} userCode={userCode}/>
                    )
                } else {
                    return(
                        <RegistrationScreen userCode={userCode}/>
                    )
                }
            } else {
                return(
                    <div>
                        <Heading>
                            Something went wrong
                        </Heading>
                        <div>
                            Make sure the link is correct.
                        </div>
                    </div>
                )
            }
        }
    }
}

const styles = {
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader: {
        width: 150,
        height: 150
    }
}

export default AffiliateUserScreen;