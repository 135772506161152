import React, {Component} from 'react';
import {Modal, Section, Button, Container} from 'react-bulma-components/dist'

import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {ProductStore} from '../../../../stores/SourcingTool';
import FetchView from "../../../dumb/FetchView/FetchView";
import NavigationBar from "../../NavigationBar/NavigationBar";
import {IconWithText} from "../../../dumb";
import { FaPlusSquare, FaUpload } from 'react-icons/fa';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import { FaPen, FaTrash } from 'react-icons/fa';
import ProductEdit from "../ProductEdit/ProductEdit";
import {TableBody} from "@material-ui/core";

@observer
class ProductsScreen extends Component {
    @observable localstate = {
        showModal: false,
        product: null,
        filtered: ProductStore.list,
        finishedFetch: false
    };

    componentDidUpdate() {
        if (ProductStore.status.fetched && !this.localstate.finishedFetch) {
            this.localstate.filtered = ProductStore.list;
            this.localstate.finishedFetch = true;
        }
    }

    editProduct(product) {
        this.localstate.showModal = true;
        this.localstate.product = product
    }

    deleteProduct(product) {
        const confirmed = confirm('Möchtest du dieses Produkt wirklich löschen?');
        if (confirmed) {
            ProductStore.deleteItem(product);
        }
    }

    closeModal() {
        this.localstate.showModal = false;
    }

    handleChangeSearchBar(e) {
        let currentList = ProductStore.list;
        let newList;

        if (e.target.value !== "") {
            newList = currentList.filter(item => {
                const filter = e.target.value.toLowerCase();
                let object;
                if (item.Title.toLowerCase().includes(filter))
                    object = item.Title.toLowerCase().includes(filter)
                else if (item.SalesDesignationDE.toLowerCase().includes(filter))
                    object = item.SalesDesignationDE.toLowerCase().includes(filter)
                else
                    object = item.SalesDesignationEN.toLowerCase().includes(filter)
                return object;
            });
        } else
            newList = ProductStore.list;
        this.localstate.filtered = newList;
    }

    getItemById(list, id) {
        if(id === null){
            return {Name:"-"}
        } else {
            return list.find(x => x.id === id);
        }
    }

    render() {
        const {showModal, product} = this.localstate;
        return (<div>
            <NavigationBar title='Produkte' itemsLeft={<input column="left" type="text" className="input" onChange={(e) => this.handleChangeSearchBar(e)} placeholder="Suche..." />}>
                <Button column="right"> <IconWithText icon={<FaUpload/>} text='Produkte importieren'/></Button>
                <Button column="right" onClick={() => this.editProduct()}> <IconWithText icon={<FaPlusSquare/>} text='Produkt hinzufügen'/></Button>
            </NavigationBar>
            <Modal show={showModal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                <Modal.Content style={styles.innerModal}>
                    <ProductEdit product={product}  onSave={this.closeModal.bind(this)} />
                </Modal.Content>
            </Modal>

            <Container>
                <Section>
                    <FetchView store={ProductStore}>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Verkehrsbezeichnung DE</TableCell>
                                    <TableCell>MainToken DE</TableCell>
                                    <TableCell align="left">Verkehrsbezeichnung EN</TableCell>
                                    <TableCell>MainToken EN</TableCell>
                                    <TableCell align="left">Lieferanten</TableCell>
                                    <TableCell align="left">Aktueller Lieferant</TableCell>
                                    <TableCell align="left">Produktname</TableCell>
                                    <TableCell align="right">Aktueller EK (kg)</TableCell>
                                    <TableCell align="right">Aktionen</TableCell>
                                </TableRow>
                            </TableHead>
                        {this.localstate.filtered.map(item =>
                            (item && <TableBody key={item.id.toString()}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{item.SalesDesignationDE}</TableCell>
                                    <TableCell>{item.KeywordDE}</TableCell>
                                    <TableCell>{item.SalesDesignationEN}</TableCell>
                                    <TableCell>{item.KeywordEN}</TableCell>
                                    <TableCell>{item.suppliers.length + ' Lieferanten'} </TableCell>
                                    <TableCell>{item.stdSuppName}</TableCell>
                                    <TableCell>{item.Title}</TableCell>
                                    <TableCell>{item.PixiEK && item.PixiEK.toFixed(2)}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => this.editProduct(item)}><FaPen/></Button>
                                        <Button onClick={() => this.deleteProduct(item)}><FaTrash/></Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>)
                        )}
                    </Table>
                </FetchView>
                </Section>
            </Container></div>);
    }
}

const styles = {
    innerModal: {
        backgroundColor: 'white'
    }
}

export default ProductsScreen;