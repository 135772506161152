import {observable} from 'mobx';
import RestClient from "../shared/Network/RestClient";

class Store {
    @observable list = [];
    url = null;
    structure = null;
    @observable
    status = {
       fetched: false,
       loading: false,
       error: null
    };

    constructor(url, structure) {
        this.url = url;
        this.structure = structure;
        this.removeItem = this.removeItem.bind(this);
    }

    getItemById(id) {
        return this.list.find(x => x.id === id);
    }

    async fetchData(force = false) {
        return new Promise(async (resolve,reject) => {
            if (!force && this.status.fetched) {
                return resolve(this.list);
            }
            this.status.error = null;
            this.status.loading = true;
            const request = RestClient.prepareRequest('GET', this.url);
            try {
                const response = await request.send();
                if (response.success) {
                    this.status.fetched = true;
                    this.list = response.data || [];
                    resolve(response.data);
                } else {
                    this.status.error = response.error;
                    reject(response.error);
                }
            } catch (e) {
                this.status.error = e;
                reject(e);
            }
            this.status.loading = false;
        });

    }

    async updateItem(data) {
        let item = this.list.find(x => x.id === data.id);
        // item = {...item, ...data};

        const request = RestClient.prepareRequest('PUT', this.url + '/' + item.id);
        request.setData(data);
        const response = await request.send();
        if (response.success) {
            const index = this.list.indexOf(item);

            if (typeof this.list[index] === 'object'){
                for (const listKey of Object.keys(this.list[index])){
                    if (response.data[listKey]){
                        if (this.list[index][listKey] !== response.data[listKey]){
                            this.list[index][listKey] = response.data[listKey]
                        }
                    }
                }
            } else {
                this.list[index] = response.data
            }
        }
        return response;
    }

    removeItem(item) {
        // this.list = this.list.splice(0,1)
        this.list = this.list.filter(x => x !== item);
    }

    async createItem(item) {
        const request = RestClient.prepareRequest('POST', this.url);
        request.setData(item);
        const response = await request.send();
        if (response.success) {
            this.list.push(response.data);
        }
        return response;
    }

    async deleteItem(item) {
        let itemInList = this.list.find(x => x.id === item.id);
        const index = this.list.indexOf(itemInList);
        const request = RestClient.prepareRequest('DELETE', this.url + '/' + item.id);
        const response = await request.send();
        if (response.success) {
            this.list.splice(index, 1);
        }
        return response.success;
    }


    async addItem(item, prepend = false) {
        if (prepend) {
            this.list.splice(0, 0, item);
        } else {
            this.list.push(item);
        }
    }

    initWithData(data) {
        this.list = data;
    }
}

export default Store;