import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { toast } from 'react-toastify';
import { Container, Box } from 'react-bulma-components/dist'
import RestClient from "../../../shared/Network/RestClient";
import Loader from "react-bulma-components/lib/components/loader";

@observer
class OrderRetention extends Component {
    @observable localstate = {
        loading: false,
        retentionData: []
    }

    constructor(props) {
        super(props);
    }

    async getRetentionData() {
        this.localstate.loading = true;

        const request = RestClient.prepareRequest('GET', `orderRetention`);

        try {
            const response = await request.send();

            if (response.success) {
                console.log(response.data);
                this.localstate.retentionData = response.data;
            } else {
                toast.error("An error occurred " + response.error);

                this.localstate.retentionData = undefined;

                console.log(response.error);
            }
        } catch (error) {
            toast.error("An error occurred " + error);

            this.localstate.retentionData = undefined;

            console.log(error);
        }

        this.localstate.loading = false;
    }

    render() {
        const {retentionData} = this.localstate;
        this.getRetentionData();

        return (
            <Container style={styles.container}>
                {retentionData.length > 0 ?
                    <Box style={styles.dataContainer}>
                        <div style={styles.dataHeading}>
                            Order Retention of last 6 months
                        </div>

                        <div>
                            <div style={styles.row}>
                                <div style={styles.date}>Month</div>

                                {retentionData.map((row, index) => {
                                    return <div>{index} Month</div>
                                })}
                            </div>

                            {this.localstate.retentionData.map(row => {
                                const date = Object.keys(row)[0];

                                return (
                                    <div style={styles.row}>
                                        <div style={styles.date}>{date}</div>

                                        {row[date].map(element => <div style={styles.element}>{element}</div>)}
                                    </div>
                                );
                            })}
                        </div>
                    </Box> :
                    <div style={styles.loaderContainer}>
                        <Loader style={styles.loader} />
                    </div>
                }
            </Container>
        );
    }
}

const styles = {
    container: {
        padding: 20,
        width: 'fit-content'
    },
    dataContainer: {
        marginTop: 64
    },
    dataHeading: {
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center'
    },
    dataRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    element: {
        padding: 8
    },
    loader: {
        width: 150,
        height: 150
    },
    loaderContainer: {
        padding: '6em',
        display: 'flex',
        justifyContent: 'center'
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 8,
        fontWeight: 'bold'
    }
};

export default OrderRetention;