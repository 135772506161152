import {Button, Section,Heading} from 'react-bulma-components/dist'
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import React from "react";
import {observable} from 'mobx';
import {observer} from "mobx-react";
import RestClient from "../../../../shared/Network/RestClient";
import {TextareaAutosize} from "@material-ui/core";
import {SupplierStore, TemplateStore, MailStore} from "../../../../stores/SourcingTool";
import FetchView from "../../../dumb/FetchView/FetchView";
import Select from "react-select";

@observer
class AnswerModal extends React.Component {
    @observable localstate = {
        id: this.props.mailID,
        subject: '',
        text: '',
        loading: false,
        options: [],
        templates: TemplateStore,
        selectedTemplateID: null
    };


    componentDidMount() {
        this.filterTemplatesByLanguage()
    }

    async filterTemplatesByLanguage() {
        const {id} = this.localstate
        const mail = MailStore.list.find(x => x.id === id)
        await SupplierStore.fetchData()
        const supplier = SupplierStore.list.find(x => x.id === mail.supplier)
        await TemplateStore.fetchData()
        const options = []
        TemplateStore.list.map(item => {
            if(item.language === supplier.Language){
                const tpl = {value: item.id, label: item.name, data: item}
                options.push(tpl)
            }
        })
        this.localstate.options = options

        if(supplier.language === "DE"){
            if(supplier.ContactGender === "male"){
                this.localstate.text = "Sehr geehrter Herr " + supplier.ContactName + ","
            } else if (supplier.ContactGender === "female"){
                this.localstate.text = "Sehr geehrte Frau " + supplier.ContactName + ","
            } else {
                this.localstate.text = "Guten Tag " + supplier.ContactName + ","
            }
        } else{
            if(supplier.ContactGender === "male"){
                this.localstate.text = "Dear Mr. " + supplier.ContactName + ","
            } else if (supplier.ContactGender === "female"){
                this.localstate.text = "Dear Ms. " + supplier.ContactName + ","
            } else {
                this.localstate.text = "Dear " + supplier.ContactName + ","
            }
        }
    }

    onChangeSubject(text) {
        this.localstate.subject = text
    }

    onChangeText(text) {
        this.localstate.text = text
    }

    async send() {
        const id = this.localstate.id;
        const subject = this.localstate.subject;
        const text = this.localstate.text;
        let success = false;

        if (id && subject && text) {
            this.localstate.loading = true;
            const request = RestClient.prepareRequest("POST", "mail")
            request.setData({id: id, subject: subject, text: text})
            try {
                success = await request.send();
            } catch(error) {
                alert("ERROR: Request failed.");
                this.localstate.loading = false;
            }
            if (success && this.props.onSend) {
                this.props.onSend();
            }
        }
    }

    applyTemplate(v) {
        this.localstate.selectedTemplateID = v.data.id
        this.localstate.text = v.data.content
        this.localstate.subject = v.data.subject
    }

    render() {
        const {subject, text, options} = this.localstate;

        return (
            <div>
                <FetchView store={TemplateStore}>
                    <FetchView store={SupplierStore}>
                        <Section>
                            <Heading>{'E-Mail beantworten'}</Heading>
                            <Field>
                                <Label>Select Template</Label>
                                <Control>
                                    <Select
                                        options={options}
                                        onChange={(e) => this.applyTemplate(e)}
                                    />
                                </Control>
                            </Field>
                            <Field>
                                <Label>Subject</Label>
                                <Control  >
                                    <Input type='text' value={subject} onChange={(e) => this.onChangeSubject(e.target.value)}/>
                                </Control>
                            </Field>
                            <Field>
                                <Label>Text</Label>
                                <Control>
                                    <TextareaAutosize style={{height:"500px", width:"100%"}} type='text' value={text} onChange={(e) => this.onChangeText(e.target.value)}/>
                                </Control>
                            </Field>
                            <Button
                                fullwidth
                                loading={this.localstate.loading}
                                disabled={!text || !subject}
                                onClick={this.send.bind(this)}>
                                Senden
                            </Button>
                        </Section>
                    </FetchView>
                </FetchView>
            </div>
        );
    }
}





export default AnswerModal;
