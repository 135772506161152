import BackButton from "components/dumb/BackButton";
import ChallengeCardList from "components/dumb/ChallengeCardList";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Container,
  Heading,

} from "react-bulma-components/dist";
import { Input } from "react-bulma-components/lib/components/form";
import { toast } from "react-toastify";
import Navigation from "shared/Navigation";
import RestClient from "shared/Network/RestClient";
import "./index.css";
import InviteConfirmation from "./InviteConfirmation";

@observer
class InviteCreation extends Component {
  @observable localstate = {
    selectedChallenge: null,
    challenges: [],
    step: 0,
    email: "",
    name: "",
    updatedData: {
      description: "",
      time: null,
      files: null,
    },
  };

  componentDidMount() {
    this.fetchChallenges();
  }

  async fetchChallenges() {
    const request = RestClient.prepareRequest("GET", "challenges");
    try {
      const response = await RestClient.sendRequest(request);

      this.localstate.challenges = response.data || [];
    } catch (error) {
      toast.error(
        "Etwas hat beim Laden der Challenges nicht geklappt. Bitte versuchen Sie es erneut."
      );
    }
  }

  handleChallengeClick(challenge) {
    this.localstate.selectedChallenge = challenge;
    this.localstate.step = 2;
  }

  handleApplicantDataChange(value, key) {
    this.localstate[key] = value;

    // Set step depending on applicant data completeness
    if (this.localstate.email && this.localstate.name) {
      this.localstate.step = 1;
    } else {
      this.localstate.step = 0;
    }
  }

  handleTimeChange(time) {
    this.localstate.updatedData.time = time;
    console.log(this.localstate.updatedData.time);
  }

  handleDescriptionChange(value) {
    this.localstate.updatedData.description = value;
  }

  onFileChange(e) {
    const newFiles = e.target.files || [];

    // Sync updated files to challenge files
    if (!this.localstate.updatedData.files) {
      this.localstate.updatedData.files = [
        ...this.localstate.selectedChallenge.challengeFiles,
      ];
    }

    this.localstate.updatedData.files = [
      ...this.localstate.updatedData.files,
      ...newFiles,
    ];

    e.target.value = null;
  }

  async removeFile(fileToDelete) {
    if (
      !confirm(
        "Möchtest du die Datei " +
          fileToDelete.name +
          " wirklich löschen? \n" +
          "Sie verbleibt in der Challenge, wird aber diesem Bewerber nicht zur Verfügung gestellt."
      )
    )
      return;

    // Sync updated files to challenge files
    if (!this.localstate.updatedData.files) {
      this.localstate.updatedData.files = [
        ...this.localstate.selectedChallenge.challengeFiles,
      ];
    }

    this.localstate.updatedData.files =
      this.localstate.updatedData.files.filter(
        (file) => file.name !== fileToDelete.name
      );
  }

  async handleSend() {
    const request = RestClient.prepareRequest("POST", "invites");

    const data = new FormData();
    data.append("challenge", JSON.stringify(this.localstate.selectedChallenge));
    data.append(
      "updatedData",
      JSON.stringify({ ...this.localstate.updatedData, files: undefined })
    );
    data.append(
      "applicant",
      JSON.stringify({
        email: this.localstate.email,
        name: this.localstate.name,
      })
    );
    // If new files have been added, extract and append them for upload
    if (!!this.localstate.updatedData.files) {
      const filesToUpload = this.localstate.updatedData.files.filter(
        (file) => file instanceof File
      );

      for (const file of filesToUpload) {
        data.append("files", file);
      }
    }
    // Specify files to include in challenge invite, including existing files
    data.append(
      "filesToInclude",
      JSON.stringify(
        this.localstate.updatedData.files ||
          this.localstate.selectedChallenge.challengeFiles
      )
    );

    request.setData(data);

    try {
      await RestClient.sendRequest(request);

      toast("Einladung erfolgreich versendet!");
      Navigation.history.push("/hrchallenges");
    } catch (error) {
      toast.error(
        "Etwas ist beim Versenden schiefgelaufen, bitte versuchen Sie es erneut."
      );
    }
  }

  render() {
    return (
      <Container>
        {this.localstate.step !== 2 ? (
          <div>
            <div style={styles.topSection} className="section">
              <BackButton text="Zurück zum Dashboard" to="/hrchallenges" />
              <Heading className="main-heading">Neue Einladung</Heading>
              <Heading>1. Bewerber:in auswählen</Heading>
              <div style={styles.applicantData}>
                <Input
                  onChange={(e) =>
                    this.handleApplicantDataChange(e.target.value, "email")
                  }
                  style={styles.emailInput}
                  placeholder="E-Mail-Adresse"
                  value={this.localstate.email}
                />
                <Input
                  placeholder="Name"
                  onChange={(e) =>
                    this.handleApplicantDataChange(e.target.value, "name")
                  }
                  value={this.localstate.name}
                />
              </div>
            </div>
            <div
              className={`section ${this.localstate.step === 0 ? "faded" : ""}`}
            >
              <Heading>2. Challenge auswählen</Heading>
              <ChallengeCardList
                onChallengeClick={(challenge) =>
                  this.localstate.step === 1
                    ? this.handleChallengeClick(challenge)
                    : ""
                }
                challenges={this.localstate.challenges}
              />
            </div>
          </div>
        ) : (
          <div className="section">
            <InviteConfirmation
              challenge={this.localstate.selectedChallenge}
              updatedData={this.localstate.updatedData}
              onTimeSelect={(time) => this.handleTimeChange(time)}
              onDescriptionChange={(value) =>
                this.handleDescriptionChange(value)
              }
              onFileChange={(e) => this.onFileChange(e)}
              onFileRemove={(file) => this.removeFile(file)}
              onSend={() => this.handleSend()}
              onGoBack={() => (this.localstate.step = 1)}
              name={this.localstate.name}
              email={this.localstate.email}
            />
          </div>
        )}
      </Container>
    );
  }
}

const styles = {
  topSection: {
    paddingTop: 0,
  },
  applicantData: {
    display: "flex",
    justifyContent: "space-between",
  },
  emailInput: {
    marginRight: "1rem",
  },
};

export default InviteCreation;
