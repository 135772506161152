import {Button, Section,Heading} from 'react-bulma-components/dist'
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import React from "react";
import {observable, autorun} from 'mobx';
import {observer} from "mobx-react";
import {ProductStore, SupplierStore} from "../../../../stores/SourcingTool";
import Select from 'react-select';
import FetchView from "../../../dumb/FetchView/FetchView";
import _ from "lodash"

@observer
class ProductEdit extends React.Component {
    @observable localstate = {
        product: {
            id: null,
            SKU: '',
            Title:'',
            SalesDesignationDE: '',
            KeywordDE: '',
            SalesDesignationEN: '',
            KeywordEN: '',
            CurrentPrice: '',
            suppliers: [],
            CurrentSupplier: null
        },
        loading: false
    };

    componentDidMount() {
        autorun(() => {
            if (this.props.product && !_.isEqual(this.localstate.product, this.props.product)) {
                this.localstate.product = {...this.props.product};
                this.localstate.product.suppliers = this.localstate.product.suppliers || [];
            }
        });
    }

    onChange(k, v) {
        if (k !== 'suppliers' && k !== 'currSupplier') {
            this.localstate.product[k] = v;
        } else if (k === 'suppliers') {
            if(!v) {
                v = [];
            }
            if(this.props.product) {
                this.props.product[k] = v.map(item => item.data);
            }
            this.localstate.product[k] = v.map(item => item.data);
        } else if (k === 'currSupplier'){
            this.localstate.product.CurrentSupplier = v.value;
            this.localstate.currselect = v;
        }
    }

    async save() {
        const {product} = this.localstate;
        this.localstate.loading = true;
        let success;
        if (product.id !== null && product.id !== undefined) {
            // update existing supplier
            success = await ProductStore.updateItem(product);
        } else {
            // create new supplier
            success = await ProductStore.createItem(product);
        }
        this.localstate.loading = false;
        console.log(success);
        if (success && this.props.onSave) {
            this.props.onSave();
        }
    }

    render() {
        const {product} = this.localstate;
        let selected = [];
        if (SupplierStore.status.fetched) {
            selected = product.suppliers.map((item) =>  {
                const supplier = SupplierStore.getItemById(item.id);
                console.log("supplier")
                console.log(supplier)
                return supplier && {
                    label: supplier.Name,
                    value: supplier.id,
                    data: supplier
                }
            });
        }


        return (
            <div>
                <FetchView store={SupplierStore}>
                    <Section>
                        <Heading>{product.id ? 'Produkt bearbeiten' : 'Produkt anlegen'}</Heading>
                        <Field>
                            <Label>SKU</Label>
                            <Control  >
                                <Input type='text' value={product.SKU} onChange={(e) => this.onChange('SKU', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Verkehrsbezeichnung Deutsch</Label>
                            <Control>
                                <Input type='text' value={product.SalesDesignationDE} onChange={(e) => this.onChange('SalesDesignationDE', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>MainToken Deutsch</Label>
                            <Control>
                                <Input type='text' value={product.KeywordDE} onChange={(e) => this.onChange('KeywordDE', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Verkehrsbezeichnung Englisch</Label>
                            <Control  >
                                <Input type='text' value={product.SalesDesignationEN} onChange={(e) => this.onChange('SalesDesignationEN', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>MainToken Englisch</Label>
                            <Control>
                                <Input type='text' value={product.KeywordEN} onChange={(e) => this.onChange('KeywordEN', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Lieferanten</Label>
                            <Control>
                                <Select
                                    options={SupplierStore.list.map((item) => {
                                        return {value: item.id, label: item.Name, data: item}
                                    })}
                                    value={selected}
                                    isMulti
                                    onChange={(suppliers) => this.onChange('suppliers',suppliers)}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Produktname</Label>
                            <Control>
                                <Input type='text' value={product.Title} onChange={(e) => this.onChange('Title', e.target.value)}/>
                            </Control>
                        </Field>

                        <Button
                            fullwidth
                            loading={this.localstate.loading}
                            disabled={!product.SalesDesignationDE || !product.SalesDesignationEN}
                            onClick={this.save.bind(this)}>
                                Speichern
                        </Button>
                    </Section>
                </FetchView>
            </div>
        );
    }
}





export default ProductEdit;
