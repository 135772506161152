import React, {Component} from 'react';
import {Box, Button, Icon, Heading } from 'react-bulma-components/dist'
import {  Field, Input, Label, Control} from 'react-bulma-components/lib/components/form';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import Authentication from '../../../shared/Authentication'
import {PageStructureNotLoggedIn} from "../../dumb";
import {
    Link
} from "react-router-dom";
import {toast} from "react-toastify";

@observer
class Login extends Component {
    @observable localstate = {
        status: {
            loading: false
        },
        form: {
            mail: "",
            passwort: ""
        }
    };

    async startLogin() {
        const {mail, passwort} = this.localstate.form;
        if (mail && passwort)
        {
            // handle login
            this.localstate.status.loading = true;
            const response = await Authentication.login(mail, passwort);
            if (!response.success) {
                toast.error(response.error ? response.error : response);
            }
        }
    }

    onChange(key, val) {
        this.localstate.form[key] = val;
    }

    render() {
        const {mail, passwort} = this.localstate.form;

        return (
            <PageStructureNotLoggedIn>
                                <Heading>KoRo Helper Login</Heading>
                                <Box>
                                    <Field>
                                        <Label>eMail</Label>
                                        <Control iconLeft iconRight>
                                            <Input type="text" value={mail} placeholder='name@firma.de' onChange={(e) => this.onChange('mail', e.target.value)}/>
                                            <Icon align='left' icon='bars'/>
                                        </Control>
                                    </Field>
                                    <Field>
                                        <Label>Passwort</Label>
                                        <Control iconLeft iconRight>
                                            <Input type="password" value={passwort} placeholder='***********'  onChange={(e) => this.onChange('passwort', e.target.value)}/>
                                            <Icon align='left' icon='bars'/>
                                        </Control>
                                    </Field>

                                    <Button fullwidth={true} onClick={this.startLogin.bind(this)} loading={Authentication.status.loading} color="primary">Login</Button>
                                    <Link to='/forgotten'>Passwort vergessen? </Link>
                                </Box>
            </PageStructureNotLoggedIn>);
    }
}

export default Login;