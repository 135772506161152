import React, { Component } from 'react'
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from 'components/screen/NavigationBar/NavigationBar'
import {Box, Button, Container, Modal, Heading} from "react-bulma-components";
import {Control, Field, Input, Label, Option, Checkbox, Textarea} from "react-bulma-components/lib/components/form";
import {Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {toast} from "react-toastify";
import {MarketEmployeesStore} from "../../../../stores/Markets/MarketEmployeesStore";
import FetchView from "../../../dumb/FetchView/FetchView";



@observer
export default class MarketEmployees extends Component {
    @observable localstate = {
        edit: null,
        editEmployee: null,
        employeeName: null,
        employeeEmail: null,
        employeeWage: null,
        employeeID: null,
    }

    onChange(key, value){
        this.localstate[key] = value
    }

    async onSave(){
        const {editEmployee, employeeID, employeeName, employeeEmail, employeeWage} = this.localstate
        try {
            if(!employeeName || !employeeEmail || !employeeWage){
                toast.error('Bitte füllen Sie alle Daten aus.')
            } else {
                if(!employeeEmail.includes('@')){
                    toast.error('Die E-Mail Adresse ist leider falsch.')
                } else {
                    let result = null;
                    if(!editEmployee){
                        const data = {
                            name: employeeName,
                            email: employeeEmail,
                            wagePerHour: employeeWage
                        }
                        result = await MarketEmployeesStore.createItem(data)
                    } else {
                        const data = {
                            id: employeeID,
                            name: employeeName,
                            email: employeeEmail,
                            wagePerHour: employeeWage

                        }
                        result = await MarketEmployeesStore.updateItem(data)
                    }
                    
                    if(result){
                        if(!editEmployee){
                            toast.success('Mitarbeiter erfolgreich angelegt!')
                        } else {
                            toast.success('Mitarbeiter erfolgreich verändert!')
                        }
                        this.localstate.edit = null
                        this.localstate.editEmployee = null
                        this.localstate.employeeName = null
                        this.localstate.employeeEmail = null
                        this.localstate.employeeWage = null
                        this.localstate.employeeID = null

                    } else {
                        toast.error(`Es ist ein Fehler aufgetreten\n Err: ${result.err}`)
                    }
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }

    editEmployee(employee){
        this.localstate.editEmployee = true
        this.localstate.edit = true
        this.localstate.employeeID = employee.id
        this.localstate.employeeName = employee.name
        this.localstate.employeeEmail = employee.email
        this.localstate.employeeWage = employee.wagePerHour
    }

    async deleteEmployee(employee){
        const confirm = window.confirm('Bist du sicher, dass du diesen Mitarbeiter Löschen willst.')
        if(confirm){
            const result = await MarketEmployeesStore.deleteItem(employee)
    
            if(result){
                toast.success('Mitarbeiter erfolgreich gelöscht!')
            } else {
                toast.error('Ein Fehler ist aufgetreten. ' + result)
            }
        }
    }

    closeModal(){
        this.localstate.editEmployee = null
        this.localstate.edit = null
        this.localstate.employeeID = null
        this.localstate.employeeName = null
        this.localstate.employeeEmail = null
        this.localstate.employeeWage = null
    }

    render() {
        const {editEmployee, edit, employeeName, employeeEmail, employeeWage} = this.localstate
        return (
            <div>
                <NavigationBar title='Market Employees'>
                    <Button onClick={() => this.onChange('edit', !edit)}>Add employee</Button>
                </NavigationBar>
                <Container>
                <FetchView store={MarketEmployeesStore}>
                    <Table aria-label="simple table" stickyHeader style={{marginTop: '15px'}} >
                        <TableHead >
                            <TableRow>
                                <TableCell colSpan={2}>
                                    Nr
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Name
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Email
                                </TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableHead>
                        {MarketEmployeesStore.list.map(employee => (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    {employee.id}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    {employee.name}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    {employee.email}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Button onClick={() => this.editEmployee(employee)}>Edit</Button>
                                    <Button onClick={() => this.deleteEmployee(employee)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </FetchView>
                </Container>

                <Modal show={edit} onClose={() => this.closeModal()}>
                    <Container style={styles.modal}>
                        <Heading>{!editEmployee ? 'Add Employee' : 'Edit Employee'}</Heading>
                        <div style={styles.inputFields}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <Label>Employee Name</Label>
                                    </TableCell>
                                    <TableCell>
                                        <Input type="text"
                                        onChange={(e) => this.onChange('employeeName', e.target.value)}
                                        value={employeeName}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Label>Employee Email</Label>
                                    </TableCell>
                                    <TableCell>
                                    <Input type="email"
                                        onChange={(e) => this.onChange('employeeEmail', e.target.value)}
                                        value={employeeEmail}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Label>Wage per hour</Label>
                                    </TableCell>
                                    <TableCell>
                                    <Input type="number"
                                        onChange={(e) => this.onChange('employeeWage', e.target.value)}
                                        value={employeeWage}
                                        />
                                    </TableCell>
                                </TableRow>
                            </Table>
                            <Button 
                            className="is-success is-fullwidth is-medium" 
                            style={{marginTop: '1.5em'}}
                            onClick={() => this.onSave()}
                            >{!editEmployee ? 'Save' : 'Edit'}</Button>
                        </div>
                    </Container>
                </Modal>
            </div>
        )
    }
}

const styles = {
    modal: {
        borderRadius: 5,
        marginBlock: '20%',
        padding:20,
        width: "400px",
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',


    },
    inputFields: {
        
    }
}