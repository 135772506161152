import Store from './Store'
let existingStores = [];

function getArticleTranslationsForLanguage(language) {
    if (!existingStores[language]) {
        existingStores[language] = new Store('translations/languages/' + language + '/articles');
    }
    return existingStores[language];
}

export {getArticleTranslationsForLanguage};