import {
  Columns,
  Container,
  Box,
} from "react-bulma-components/dist";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import React, { Component } from "react";
import Authentication from "../../../shared/Authentication";

@observer
class HRChallenges extends Component {
  views = [
    {
      label: "Neue Einladung",
      description: "Lade Bewerber:innen zu Challenges ein.",
      url: "/hrchallenges/invite",
    },
    {
      label: "Neue Challenge",
      description:
        "Erstelle eine neue Bewerberchallenge basierend auf einer Position.",
      url: "/hrchallenges/challenge",
    },
    {
      label: "Challenges verwalten",
      description: "Verwalte bereits erstellte Challenges.",
      url: "/hrchallenges/challenges",
    },
  ];

  render() {
    return (
      <Container style={styles.container}>
        <div style={styles.viewHeader}>
          <h1 style={styles.viewTitle}>
            Guten Tag, {Authentication.user.firstName}.
          </h1>
          <h3 style={styles.subtitle}>Let's do this.</h3>
        </div>

        <Columns>
          {this.views.map((view, index) => (
            <Columns.Column className="is-one-third" key={index}>
              <Link to={view.url}>
                <Box>
                  <Columns>
                    <Columns.Column>
                      <div style={styles.header}>{view.label}</div>
                    </Columns.Column>
                  </Columns>
                  <div style={styles.description}>{view.description}</div>
                </Box>
              </Link>
            </Columns.Column>
          ))}
        </Columns>
      </Container>
    );
  }
}

const styles = {
  container: {
    padding: "4rem 0",
  },
  viewHeader: {
    marginBottom: "2rem",
  },
  viewTitle: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "3rem",
    marginBottom: "0",
  },
  subtitle: {
    fontSize: "2rem",
    lineHeight: 1,
  },
  header: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
};

export default HRChallenges;
