import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Button,
  Box,
  Columns,
  Heading,
} from "react-bulma-components/dist";
import { Textarea } from "react-bulma-components/lib/components/form";
import TimeSelector from "../ChallengeEditor/TimeSelector";
import FileUploadList from "../../FileUploadList";
import { FaChevronRight } from "react-icons/fa";
import "./index.css";
import BackButton from "components/dumb/BackButton";

@observer
class InviteConfirmation extends Component {
  @computed get updatedData() {
    return this.props.updatedData;
  }

  @computed get time() {
    return (
      this.updatedData.time || {
        label: `${this.props.challenge.timeLimit / 60 / 1000} Minuten`,
        value: this.props.challenge.timeLimit / 60 / 1000,
      }
    );
  }

  @computed get description() {
    return this.updatedData.description || this.props.challenge.description;
  }

  @computed get files() {
    return this.updatedData.files || this.props.challenge.challengeFiles;
  }

  render() {
    return (
      <Box style={styles.card}>
        <BackButton
          withoutLink={true}
          onClick={() => this.props.onGoBack()}
          text="Zurück zu Schritt 1 & 2"
        />
        <Heading className="invite__main-header">
          3. Bestätige die Challenge
        </Heading>
        <Heading className="invite__secondary-header">Bewerber:in</Heading>
        <Box style={styles.applicantBox}>
          <div>
            <b>Name:</b> {this.props.name}
          </div>
          <div>
            <b>E-Mail-Adresse:</b> {this.props.email}
          </div>
        </Box>
        <Heading className="invite__secondary-header">Challenge</Heading>
        <Heading className="invite__position">
          {this.props.challenge.title}
        </Heading>
        <Columns>
          <Columns.Column>
            <Heading className="invite__ternary-header">Timer</Heading>
            <div style={styles.timeSelector}>
              <TimeSelector
                time={this.time}
                onTimeSelect={(e) => this.props.onTimeSelect(e)}
              />
            </div>
            <Heading className="invite__ternary-header">Beschreibung</Heading>
            <Textarea
              value={this.description}
              onChange={(e) => this.props.onDescriptionChange(e.target.value)}
            />
          </Columns.Column>
          <Columns.Column>
            <Heading className="invite__ternary-header">Dateien</Heading>
            <FileUploadList
              files={this.files}
              onFileSelect={(e) => this.props.onFileChange(e)}
              onFileRemove={(file) => this.props.onFileRemove(file)}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column/>
          <Columns.Column style={styles.buttonWrapper}>
            <Button style={styles.sendButton} onClick={this.props.onSend}>
              Absenden <FaChevronRight />
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

const styles = {
  card: {
    marginTop: "3rem",
    maxWidth: "800px",
    margin: "0 auto",
  },
  buttonWrapper: {
    position: "relative",
    minHeight: "5rem",
  },
  sendButton: {
    position: "absolute",
    right: "0.75rem",
    bottom: "0.75rem",
  },
  applicantBox: {
    maxWidth: "50%",
  },
  timeSelector: {
    marginBottom: "2rem",
  },
};

export default InviteConfirmation;
