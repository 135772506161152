import {Dialog, List, ListItem,} from "@material-ui/core";
import {Container} from "react-bulma-components";
import {ArticlesStore} from "../../../stores";
import React, {Component} from "react";
import {Reasons} from "./ReasonOptions";
import Papa from "papaparse";
import {toast} from "react-toastify";
import {v4 as uuidv4} from 'uuid';
import {BulkReservation, ReservationObject} from "./ReservationObject";
import {DialogBar, DialogDescription, DialogInputField, DialogSelectionField} from "./ReservationDialogParts";

function ScrollableItemList(props) {
    const styles = {
        select: {
            padding: "10pt",
            margin: "auto"
        },
        itemList: {
            maxHeight: 200,
            overflow: 'auto',
            borderStyle: 'solid',
            borderWidth: 'thin',
            borderColor: 'lightgrey',
            borderRadius: '5px'
        }
    }
    return(
        <Container style={styles.select}>
            <div>Reserved items</div>
            <List style={styles.itemList}>
                {props.reservations.map((item, i) => (
                    <ListItem key={i}>
                        {i+1}: {item.amount}x {item.productNumber} ({item.amountReserved}/{item.amountPending + item.amountReserved} reserved)
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

function ErrorReport(props){
    const errorStyle = {
        color: 'red',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
    const articleErrors = props.articleErrors.length
    const amountErrors = props.amountErrors.length
    const totalErrors = articleErrors + amountErrors

    const generalErrorMessage = `${totalErrors} issues found. Nothing got reserved yet.`
    const articleErrorsMessage = `Invalid article numbers: ${props.articleErrors.join(", ")}`
    const amountErrorsMessage = `Invalid amounts found for these articles: ${props.amountErrors.join(", ")}`

    if(totalErrors > 0){
        return(
            <div style={errorStyle}>
                <p>{generalErrorMessage}</p>
                <p>Try again after fixing the issues in your csv file</p>
                <br/>
                {articleErrors > 0 && <p>{articleErrorsMessage}</p>}
                {amountErrors > 0 && <p>{amountErrorsMessage}</p>}
            </div>
        )
    }else{
        return null
    }
}

class ReserveMultipleDialog extends Component {
    state = {
        selectedDate:  this.props.reservation?.date || '',
        selectedReason:  this.defaultReasonOption(),
        selectedNote:  this.props.reservation?.note || '',
        selectedSKUHeader: null,
        selectedAmountHeader: null,
        csvData: null,
        csvHeaderFields: null,
        amountErrors: [],
        articleErrors: []
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.createContext = {title:"Reserve products in bulk",
            tooltip: "Only reserves if all rows could be verified",
            submitText:"Reserve all",
            function: this.handleSubmitCreate.bind(this)}

        this.updateContext = {title:"Change bulk reservation",
            tooltip: "Updates all reservations belonging to this bulk",
            submitText:"Update all",
            function: this.handleSubmitUpdate.bind(this)}
    }

    defaultReasonOption() {
        let option = null
        if(this.props.reservation){
            const reason = this.props.reservation.reason
            option = {label: reason, value: reason}
        }
        return option
    }

    defaultProductOption() {
        let option = null
        if(this.props.reservation){
            const productNumber = this.props.reservation.productNumber
            const label = productNumber + " - " + this.props.reservation.productName
            option = {label: label, value: productNumber}
        }
        return option
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleFileChange(file) {
        this.fileReader = new FileReader();
        if(file){
            this.fileReader.onloadend = this.handleFileRead.bind(this);
            this.fileReader.readAsText(file);
        }
    }

    mapToOptions(array) {
        return array.map(item => {
                return {label:item, value:item}
            })
    }

    handleFileRead() {
        try {
            const content = this.fileReader.result;
            let results = Papa.parse(content, {header: true});
            if (results.errors.length > 0) {
                throw('invalid format')
            }
            if (results.data.length === 0) {
                throw('no data found');
            }
            const headerFields = Papa.parse(content).data[0]
            if(headerFields.length < 2) {
                throw('too few columns')
            }
            const csvData = results.data
            const headerOptions = this.mapToOptions(headerFields)
            const contentOptions = this.mapToOptions(csvData)
            this.setState({
                csvHeaderFields: headerOptions,
                csvData: contentOptions,
                selectedSKUHeader: headerOptions[0],
                selectedAmountHeader: headerOptions[1]
            })

            toast.success('Successfully uploaded the csv file')
        } catch (error) {
            console.log(error);
            toast.error(error)
        }

    }

    isDateValid() {
        let today_min = new Date().toISOString().substring(0,10);
        let inAYear = new Date();
        inAYear.setDate(inAYear.getDate() + 365);
        let inAYear_min =  inAYear.toISOString().substring(0,10);

        return this.state.selectedDate > today_min && this.state.selectedDate < inAYear_min;
    };

    isFormCorrect() {
        const headerFieldsFilled = this.state.selectedSKUHeader !== null
            && this.state.selectedAmountHeader !== null

        const reservationFieldsFilled = this.state.selectedReason !== null
            && this.state.selectedDate !== ''
            && this.isDateValid();

        if(this.props.reservation){
            const reservation = this.props.reservation
            const anythingChanged = this.state.selectedNote !== reservation.note
                || this.state.selectedDate !== reservation.date
                || this.state.selectedReason?.value !== reservation.reason

            return reservationFieldsFilled && anythingChanged
        }

        return reservationFieldsFilled && headerFieldsFilled
    }

    handleSubmitCreate() {
        // general data for all the articles:
        const date = this.state.selectedDate
        const reason = this.state.selectedReason.value
        const note = this.state.selectedNote
        const groupId = uuidv4()

        let reservationObjects = []
        let invalidAmounts = []
        let missingArticles = []

        this.state.csvData.forEach(item => {
            const sku = item.value[this.state.selectedSKUHeader.value]
            const amount = parseInt(item.value[this.state.selectedAmountHeader.value])
            const article = ArticlesStore.list.find(article => article.ordernumber === sku)
            if(!article){
                missingArticles.push(sku)
            }
            if(!amount || amount <= 0){
                invalidAmounts.push(sku)
            }

            if(article && amount > 0){
                reservationObjects.push(
                    new ReservationObject(
                        {
                        amount: amount,
                        date: date,
                        reason: reason,
                        productNumber: sku,
                        productName: article.name,
                        note: note,
                        groupId: groupId
                    })
                )
            }
        })


        if(invalidAmounts.length > 0 || missingArticles.length > 0){
            toast.error("CSV contains invalid entries. Please Check and repeat the upload.")
            this.setState({
                amountErrors: invalidAmounts,
                articleErrors: missingArticles
            })
        }else{
            console.log("would reserve this now: ");
            console.log(reservationObjects);
            this.props.onCreate(reservationObjects)
        }

    };

    handleSubmitUpdate() {
        const updatedReservation = new BulkReservation({
            date: this.state.selectedDate,
            reason: this.state.selectedReason.value,
            note: this.state.selectedNote,
            groupId: this.props.reservation.groupId,
            reservations: this.props.reservation.reservations
        })

        this.props.onUpdate(updatedReservation)
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogBar
                    context={this.props.reservation ? this.updateContext : this.createContext}
                    onClose={this.props.onClose}
                    isValidForm={this.isFormCorrect()}
                />
                <div style={styles.container}>
                    <DialogDescription/>
                    {!this.props.reservation &&
                    <div>
                        <Container style={styles.container}>
                            <div>Products CSV</div>
                            <input
                                type="file"
                                accept='.csv'
                                onChange={e => this.handleFileChange(e.target.files[0])}
                            />
                        </Container>

                        <ErrorReport
                            articleErrors={this.state.articleErrors}
                            amountErrors={this.state.amountErrors}
                        />
                    </div>
                    }
                    {this.props.reservation && <ScrollableItemList reservations={
                        this.props.reservation.reservations}/>}

                    {this.state.csvHeaderFields &&
                    <div>
                        <DialogSelectionField
                            title="SKU header field name"
                            defaultValue = {this.state.csvHeaderFields[0]}
                            onChange={option => {this.setState({selectedSKUHeader: option})}}
                            options={this.state.csvHeaderFields}
                        />
                        <DialogSelectionField
                            title="Amount header field name"
                            defaultValue = {this.state.csvHeaderFields[1]}
                            onChange={option => {this.setState({selectedAmountHeader: option})}}
                            options={this.state.csvHeaderFields}
                        />
                    </div>
                    }
                    <DialogSelectionField
                        title="Reason"
                        isDisabled={false}
                        placeholder="What is the reservation for?"
                        value={this.state.selectedReason}
                        onChange={option => {this.setState({"selectedReason": option})}}
                        options={Reasons}
                    />
                    <DialogInputField
                        title="Expiry Date"
                        name="selectedDate"
                        value={this.state.selectedDate}
                        onChange={this.handleChange}
                        type='date'
                        min={new Date().toISOString().slice(0,10)}
                    />
                    <DialogInputField
                        title="Note"
                        name="selectedNote"
                        value={this.state.selectedNote}
                        onChange={this.handleChange}
                        placeholder="Optional info detailing the reason for this reservation"
                    />
                </div>
            </Dialog>)
    }
}

const styles = {
    container: {
        padding: "10pt",
        margin: "auto"
    }
}

export default ReserveMultipleDialog;