import React, {Component} from 'react';
import {Container, Button, Heading} from 'react-bulma-components/dist'
import {Field, Input, Label, Checkbox} from 'react-bulma-components/lib/components/form';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";

@observer
class RegistrationScreen extends Component {
    @observable localstate = {
        userCode: this.props.userCode,
        socialMediaName: "",
        fullName: "",
        email: "",
        disableFields: false,
        checkBoxTicked: false,
        loading: false
    }

    changeInput(key, value) {
        this.localstate[key] = value
    }

    handleCheckClick() {
        this.localstate.checkBoxTicked = !this.localstate.checkBoxTicked;
    }

    async register(){
        this.localstate.disableFields = true
        this.localstate.loading = true

        const body= {
            name: this.localstate.fullName,
            email: this.localstate.email,
            accountName: this.localstate.socialMediaName
        }
        
        const request = RestClient.prepareRequest('PUT', 'affiliate-user/' + this.localstate.userCode)
        request.setData(body)

        const response = await request.send();
        if (response.success){
            toast.success("You have successfully registered. You will now be redirected.")
            location.reload()
        } else {
            toast.error('Something went wrong. ' + response.error.errors[0].message)
            this.localstate.disableFields = false
        }
        this.localstate.loading = false
    }




    render() {
        const {checkBoxTicked, socialMediaName, fullName, email, disableFields, loading} = this.localstate
        return(
            <Container style={{padding: 20}}>
                <Heading>Welcome!</Heading>
                <div style={styles.text}>
                    Sign up here with KoRo if you want to collaborate with us.
                    <br />
                    We are looking for people that share our values
                    <br />
                    By signing up, you will receive a 5 % voucher that you can share with
                    your Instagram followers.
                </div>
                <div style={styles.text}>
                    You will receive 5 % comission on any sale generated through your voucher in the
                    form of a voucher for your next KoRo order. Note that you can only redeem your
                    voucher once you have earned more than 10 €.
                </div>
                <Heading>Registration</Heading>
                <Field>
                    <Label>Full name</Label>
                    <Input
                        placeholder="First Name Last Name"
                        value={fullName}
                        onChange={e => this.changeInput('fullName', e.target.value)}
                        disabled={disableFields}
                    />
                </Field>
                <Field>
                    <Label>Email address</Label>
                    <Input
                        placeholder="example@email.com"
                        value={email}
                        onChange={e => this.changeInput('email', e.target.value)}
                        disabled={disableFields}
                    />
                </Field>
                <Field>
                    <Label>User name of your primary social media platform</Label>
                    <Input
                        placeholder="user_name"
                        value={socialMediaName}
                        onChange={e => this.changeInput('socialMediaName', e.target.value)}
                        disabled={disableFields}
                    />
                </Field>
                <table>
                    <tr>
                        <td>
                            <Checkbox onClick={() => this.handleCheckClick()}/>
                        </td>
                        <td>
                            <div style={{paddingLeft: "10px"}}>I will not share the code outside my Instagram or Facebook profile,
                                and I understand that KoRo might cancel my voucher otherwise.
                            </div>
                        </td>
                    </tr>
                </table>
                <div style={styles.buttonWrapper}>
                    <Button
                        color='primary'
                        fullwidth
                        onClick={() => this.register()}
                        disabled={!checkBoxTicked || fullName.length===0 || email.length===0 || socialMediaName.length===0}
                        loading={loading}
                    >Generate Voucher - Let's go!</Button>
                </div>
            </Container>
        )
    }
}

const styles = {
    text: {
        display: "block",
        paddingTop: "20px",
        paddingBottom: "20px"
    },
    buttonWrapper:{
        paddingTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
    }
}

export default RegistrationScreen;