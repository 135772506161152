import React, {Component} from 'react';
import {Heading, Button} from 'react-bulma-components/dist'
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";
import Loader from "react-bulma-components/lib/components/loader";
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DatePicker from "react-datepicker";

@observer
class DelayedSupplierOrders extends Component {
    @observable localstate = {
        registered: false,
        instaName: "",
        loadingPage: false,
        loading: false,
        successfulLoad: false,
        orderlines: []
    };

    componentDidMount() {
        this.loadUserParams()
    }

    async loadUserParams(){
        const urlCode = this.props.match.params.urlCode
        const request = RestClient.prepareRequest('GET', 'delayed-suppliers/' + urlCode);
        this.localstate.loadingPage = true

        try{
            const response = await RestClient.sendRequest(request)
            this.localstate.orderlines = response.data
            this.localstate.successfulLoad = true
        } catch (e){
            toast.error("An error occurred. " + e)
            this.localstate.successfulLoad = false
        }
        this.localstate.loadingPage = false
    }

    async updateDeliveryDates() {
        const orderlines = this.localstate.orderlines
        const body = []
        for (const orderline of orderlines){
            body.push({orderlineID: orderline.id, newDeliveryDate: orderline.latestConfirmedDeliveryDate})
        }

        const request = RestClient.prepareRequest('PUT', 'delayed-suppliers/dates')
        request.setData(body)

        this.localstate.loading = true
        const response = await request.send();
        if (response.success){
            toast.success("Your delivery dates have been updated successfully. Thank you very much!")
        } else {
            toast.error('Something went wrong. ' + response.error.errors[0].message)
        }
        this.localstate.loading = false
    }

    changeDate(orderlineID, date) {
        const orderline = this.localstate.orderlines.find(line => line.id === orderlineID)
        const d = new Date(date);
        orderline.latestConfirmedDeliveryDate = d.toISOString().split('T')[0]
    }

    render() {
        const {loadingPage, successfulLoad, orderlines} = this.localstate
        if (loadingPage){
            return (
                <div style={styles.loadingContainer}>
                    <Loader style={styles.loader} />
                </div>
            )
        } else {
            if (successfulLoad){
                const supplierName = orderlines[0].supplierName
                return(
                    <div>
                        <NavigationBar title='Update Delivery Dates'/>
                        <Container style={styles.text}>
                            <div>Hello dear {supplierName} team! </div>
                            <div>
                                Unfortunately, your order has not arrived at our warehouse in time.
                                Please indicate your updated delivery date wherever possible. Thank you!
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Order Number</TableCell>
                                        <TableCell>Latest Confirmed Delivery date</TableCell>
                                        <TableCell>New Delivery Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderlines.map(orderline => {
                                        return(
                                            <TableRow>
                                                <TableCell>{orderline.articleName}</TableCell>
                                                <TableCell>{orderline.orderNr}</TableCell>
                                                <TableCell>{orderline.latestConfirmedDeliveryDate}</TableCell>
                                                <TableCell>
                                                    <DatePicker
                                                        selected={Date.parse(orderline.latestConfirmedDeliveryDate)}
                                                        dateFormat="yyyy-MM-dd"
                                                        onChange={date => this.changeDate(orderline.id, date)}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <div style={styles.buttonWrapper}>
                                <Button
                                    color='primary'
                                    fullwidth
                                    onClick={() => this.updateDeliveryDates()}
                                    loading={this.localstate.loading}
                                >Update delivery dates</Button>
                            </div>
                        </Container>
                    </div>
                )
            } else {
                return(
                    <div>
                        <Heading>
                            Something went wrong
                        </Heading>
                        <div>
                            Make sure the link is correct.
                        </div>
                    </div>
                )
            }
        }
    }
}

const styles = {
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader: {
        width: 150,
        height: 150
    },
    text: {
        paddingTop: "20pt",
        paddingBottom: "20pt"
    },
    buttonWrapper:{
        paddingTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
    }
}

export default DelayedSupplierOrders;