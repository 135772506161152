import React from 'react';
import TableCell from "@material-ui/core/TableCell/TableCell";
import styles from './styles';

class StyledTableCell extends React.Component {
    render() {
        const {children, style} = this.props;
        return (
            <TableCell style={{...styles.tableCell, ...style}} align='left'>{children}</TableCell>
        );
    }
}

export default StyledTableCell;