import React, {Component} from 'react';
import {Tabs, Container} from 'react-bulma-components/dist'
import {observer} from 'mobx-react';
import {observable} from 'mobx';


@observer
class TabScreen extends Component {
    @observable localstate = {
        navigate: null
    };

    navigate(item) {
        const {BASE_URL, Navigation} = this.props;
        Navigation.history && Navigation.history.push(BASE_URL + item.url);
    }

    render() {
        const {BASE_URL, subpages, Navigation} = this.props;
        const url = Navigation.location.pathname;
        let activeItem = null;
        return (<div><Container>
            <Tabs type='toggle' fullwidth={true}>
                {subpages.map(item => {
                    const cleanedUrl= BASE_URL + item.url.replace('*', '');
                    const active = (url === BASE_URL + item.url || (url.indexOf(cleanedUrl)>-1 && url.length>cleanedUrl.length));
                    if (active) {
                        activeItem = item;
                    }
                    return (<Tabs.Tab key={item.url} onClick={() => this.navigate(item)} active={url === BASE_URL + item.url}>
                        {item.label}
                    </Tabs.Tab>)
                })}
            </Tabs>
            <div></div>
        </Container>
            {activeItem && activeItem.component}
        </div>)
    }
}

export {TabScreen};
