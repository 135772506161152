import {Link} from "react-router-dom";
import React, {Component} from "react";
import {Heading,Columns, Container} from 'react-bulma-components/dist'
import { FaChevronLeft } from 'react-icons/fa';


class NavigationBar extends Component {
    render() {
        const {title, backURL, children, itemsLeft} = this.props;
        return (    <div style={styles.navigationBar}>
            <Container fluid>
                <Columns style={{padding:30}}>
                    <Columns.Column size={3} style={styles.navigationItem} >
                        {backURL ? <Link to={backURL}> <FaChevronLeft /></Link> : null }
                        {itemsLeft && itemsLeft }
                    </Columns.Column>
                    <Columns.Column size={6} style={styles.pagetitle}>
                        <Heading style={{color: 'black'}} size={5} >{title}</Heading>
                    </Columns.Column>
                    <Columns.Column size={3} style={styles.buttons}>
                        {children}
                    </Columns.Column>
                </Columns>
            </Container>
        </div>);
    }
}


const styles = {
    vertialSelection: {
        display:'flex',
        padding: 10,
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #eee'
    },
    pagetitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dot: {
        height: 10,
        width: 10,
        backgroundColor: 'blue',
        display: 'flex',
        marginLeft: 10,
        borderRadius: 100

    },
    buttons: {

        display: 'flex',
        justifyContent: 'flex-end'
    },
    navigationBar: {
        display: 'flex',
        background: '#f3f3f3',
        justifyContent:'center',
        minHeight: 98,
        overflow: 'hidden',

        color: 'black',
        alignItems:'center',
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee',

    },
    navigationItem: {
        alignItems: 'center',
        display: 'flex'
    },

    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}


export default NavigationBar;