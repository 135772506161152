import {AppBar, Button, DialogContentText, IconButton, Toolbar, Tooltip, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Container} from "react-bulma-components";
import Select from "react-select";
import {Input} from "react-bulma-components/lib/components/form";
import React from "react";

function DialogBar(props) {
    return (<AppBar style={{position: 'relative'}}>
        <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                <CloseIcon/>
            </IconButton>
            <Typography variant="h6" style={{flex: 1}}>
                {props.context.title}
            </Typography>
            <OptionalTooltip context={props.context}>
                <Button
                    color="inherit"
                    autoFocus
                    onClick={props.context.function}
                    disabled={!props.isValidForm}>
                    {props.context.submitText}
                </Button>
            </OptionalTooltip>
        </Toolbar>
    </AppBar>)
}

function DialogDescription(){
    return(
        <DialogContentText>
            Reserving a product will make it unavailable to customers through the shop and can ensure that
            enough stock is available for bulk orders from retail. Reservations can be edited and removed at any time. By
            default, they will be removed on the set expiration date. After removal of a reservation products will be available
            to the online shop again.
        </DialogContentText>
    )
}

function OptionalTooltip(props){
    if(props.context.tooltip){
        return(
            <Tooltip title={props.context.tooltip}>
                <span>
                    {props.children}
                </span>
            </Tooltip>
        )
    }else{
        return props.children
    }
}

function DialogSelectionField(props) {
    return (
        <Container style={{padding: "10pt", margin: "auto"}}>
            <div>{props.title}</div>
            <Select
                isDisabled={props.isDisabled}
                placeholder={props.placeholder}
                isSearchable={"true"}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={props.onChange}
                options={props.options}
                maxMenuHeight={170}
            />
        </Container>
    )
}

function DialogInputField(props) {
    return (
        <Container style={{padding: "10pt", margin: "auto"}}>
            <div>{props.title}</div>
            <Input
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                min={props.min}
                placeholder={props.placeholder}
            />
        </Container>
    )
}

export {DialogBar, DialogInputField, DialogSelectionField, DialogDescription}