import {observer} from "mobx-react";
import React, {Component} from "react";
import {observable} from "mobx";
import {DeliveryOrderStore} from "../../../stores/DeliveryOrderStore";
import FetchView from "../../dumb/FetchView/FetchView";
import {Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Tabs, Container, Button} from 'react-bulma-components/dist'
import NavigationBar from "../NavigationBar/NavigationBar";
import moment from 'moment';

@observer
class DeliveryDashboard extends Component {
    @observable localstate = {
        refresh: null
    };

    sortList(a, b) {
        const dateA = moment(a.dateslot);
        const dateB = moment(b.dateslot);
        if (dateA < dateB) {
            return 1;
        }
        if (a.timeslot<b.timeslot) {
            return 1;
        }
        return -1;
    }

    componentDidMount() {
        setInterval(() => {
            this.localstate.refresh = new Date();
        }, 15000);
    }

    render() {
        const {refresh} = this.localstate;
        return (<Container><FetchView store={DeliveryOrderStore} refresh={refresh}>
            <NavigationBar title='Delivery Orders Overview'>
            </NavigationBar>
            <Container style={{overflowX: 'scroll'}}>
            <Table aria-label="simple table" stickyHeader style={{marginTop: '15px'}} >
                <TableHead >
                    <TableRow>
                        <TableCell>
                            Datum
                        </TableCell>
                        <TableCell>
                            Timeslot
                        </TableCell>
                        <TableCell>
                            OrderNr
                        </TableCell>
                        <TableCell>
                            Delivery Address
                        </TableCell>
                        <TableCell>
                            Dish
                        </TableCell>
                        <TableCell>
                            Note
                        </TableCell>
                        <TableCell>
                            CustomerName
                        </TableCell>
                        <TableCell>
                            CompanyName
                        </TableCell>
                    </TableRow>
                </TableHead>

            {DeliveryOrderStore.list.sort(this.sortList).map(item => {
                return <TableRow>
                    <TableCell>{item.dateslot}</TableCell>
                    <TableCell>{item.timeslot}</TableCell>
                    <TableCell>{item.orderNr}</TableCell>
                    <TableCell>{item.deliveryAddress}</TableCell>
                    <TableCell>{item.dish}</TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell>{item.customerName}</TableCell>
                    <TableCell>{item.companyName}</TableCell>
                </TableRow>
            })}
            </Table>
            </Container>
        </FetchView></Container>);
    }

}

export default DeliveryDashboard;