import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { getInventoryByStorage, MarketProductsStore } from "../../../../stores";
import FetchView from "../../../dumb/FetchView/FetchView";
import { Container } from "react-bulma-components";
import {
  Input,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { toast } from "react-toastify";

@observer
class MarketInventory extends Component {
  @observable localstate = {
    product: {},
    incoming: {},
  };
  storage = null;
  store = null;

  constructor(props) {
    super(props);
    this.storage = props.storage;
    this.store = getInventoryByStorage(this.storage.id);
  }

  onChange(sku, quantity) {
    Object.assign(this.localstate.product, { [sku]: quantity });
  }

  comingItems(k, v) {
    Object.assign(this.localstate.incoming, { [k]: v });
  }

  saveChanges(market, store) {
    const formattedItems = []
    if (!Object.keys(this.localstate.product).length) {
      market.list.map((x) => {
        const instore = store.list.find((y) => y.sku === x.sku);

        Object.assign(this.localstate.product, {
          [x.sku]: instore.quantity,
        });
      });
    }

    for (let key in this.localstate.product) {
      let calcStock = 0;

      if (this.localstate.incoming[key]) {
        calcStock =
          +this.localstate.product[key] + +this.localstate.incoming[key];
      } else {
        calcStock = +this.localstate.product[key] + 0;
      }

      Object.assign(this.localstate.product, { [key]: calcStock });

      
      const instore = store.list.find((y) => y.sku === key)
      if(instore.id){
        formattedItems.push({'id': instore.id, 'sku': key, 'quantity': calcStock})
      } else {
        formattedItems.push({'id': null, 'sku': key, 'quantity': calcStock})
      }
    }
    this.saveToDB(formattedItems, store)

    this.localstate.incoming = {};
  }

  async saveToDB(formattedItems, store){
    try {
      for (const entry in formattedItems) {
          if (formattedItems[entry].id) {
            await store.updateItem(formattedItems[entry]);
          } else {
            await store.createItem(formattedItems[entry]);
          }
      }

      toast.success("Erfolgreich angelegt.");
      this.props.onSave();
    } catch (error) {
      toast.error(error);
    }
  }

  render() {
    return (
      <Container style={styles.container}>
        <FetchView store={MarketProductsStore}>
          <FetchView store={this.store}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Incoming Quantity</TableCell>
                </TableRow>
              </TableHead>

              {MarketProductsStore.list.map((x) => {
                const instore = this.store.list.find((y) => y.sku === x.sku);

                if (instore !== undefined) {
                  return (
                    <TableRow key={x.sku}>
                      <TableCell>{x.sku}</TableCell>
                      <TableCell>
                        <Input
                          type="number"
                          onChange={(e) =>
                            this.onChange(x.sku, e.target.value)
                          }
                          value={
                            this.localstate.product[x.sku]
                              ? this.localstate.product[x.sku]
                              : instore.quantity
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Input
                          type="number"
                          onChange={(e) =>
                            this.comingItems(x.sku, e.target.value)
                          }
                          value={
                            this.localstate.incoming[x.sku]
                              ? this.localstate.incoming[x.sku]
                              : 0
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </Table>
            <button
              className={`button is-success is-medium`}
              style={styles.button}
              onClick={() => this.saveChanges(MarketProductsStore, this.store)}
            >
              Save
            </button>
          </FetchView>
        </FetchView>
      </Container>
    );
  }
}

const styles = {
  container: {
    borderRadius: 5,
    padding: 20,
    background: "white",
    minWidth: "400px",
  },
  button: {
    marginTop: "1.5em",
    width: "100%",
    color: ''
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
};
export default MarketInventory;
