import React, {Component} from 'react';
import {Container, Button} from 'react-bulma-components/dist'
import Progress from "react-bulma-components/lib/components/progress";
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import Papa from "papaparse";
import {toast} from "react-toastify";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

import NavigationBar from "../NavigationBar/NavigationBar";
import RestClient from "../../../shared/Network/RestClient";


@observer
class CancelOrders extends Component {
    @observable localstate = { 
        loading: false,      
        orders: [],
        canceled: [],
        finished: false
    };

    handleFileRead() {
        try {
            const content = this.fileReader.result;
            let results = Papa.parse(content, {header: false, delimiter: " "});
            if (results.errors.length > 0) {
                throw('invalid format')
            }
            if (results.data.length === 0) {
                throw('no data found');
            }
            this.localstate.orders = results.data;
            toast.success('Successfully uploaded the csv file')
        } catch (error) {
            console.log(error);
            toast.error(error)
        }
    }

    handleFileChange = (file) => {
        if(file){
            this.fileReader = new FileReader();
            this.fileReader.onloadend = this.handleFileRead.bind(this);
            this.fileReader.readAsText(file);
            this.localstate.loading = false;
            this.localstate.orders = [];
            this.localstate.canceled = [];
            this.localstate.finished = false;
        }
    }

    cancelOrders = async () => {
        const orders = this.localstate.orders;
        this.localstate.loading = true;
        for (let order of orders) {
            const request = RestClient.prepareRequest('DELETE', 'orders/'+order);
            try {
                const response = await request.send();
                if (response.success) {
                    let canceled = this.localstate.canceled;
                    canceled.push({order: order, error: null});
                    this.localstate.canceled = canceled;
                } else {
                    let canceled = this.localstate.canceled;
                    canceled.push({order: order, error: "Could not cancel"});
                    this.localstate.canceled = canceled;
                }
            } catch (e) {
                let canceled = this.localstate.canceled;
                canceled.push({order: order, error: "Server error"});
                this.localstate.canceled = canceled;
            }
        }
        this.localstate.finished = true;
    }

    
    render() {
       const orders = this.localstate.orders;
       const canceled = this.localstate.canceled;
       const loading = this.localstate.loading;
       const finished = this.localstate.finished;
        return (
        <div>
            <NavigationBar title = "Cancel orders" />
            <Container style={styles.container}>
            <input style={styles.input}
                type="file"
                accept='.csv'
                onChange={e => this.handleFileChange(e.target.files[0])}
            />
            <p style={styles.desc}>Upload a CSV file with <b>shop</b> order numbers to cancel. Only <b>one</b> column and <b>no header!</b></p>
            {this.localstate.loading ? "" : <Button
                color='primary'
                onClick={this.cancelOrders}
                disabled={this.localstate.orders.length < 1}
            >Cancel orders</Button>}
            {loading ? <Progress color='primary' value={canceled.length} max={orders.length} /> : ""}
            {finished ? <p>Finished! The following orders could not be canceled:</p> : ""}
            {finished ? 
            <Table style={styles.table} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>OrderNr</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {canceled.map((cancel) => {
                        if(cancel.error) {
                            return <TableRow>
                                        <TableCell>{cancel.order}</TableCell>
                                        <TableCell>{cancel.error}</TableCell>
                                    </TableRow>
                        }
                    })}
                </TableBody>
            </Table>
            :
            <Table style={styles.table} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>OrderNr</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map(order => (
                            <TableRow>
                                <TableCell>{order}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
            </Container>
        </div>
       )
    }
}

const styles = {
    container: {
        textAlign: 'center',
    },
    input: {
        textAlign: 'center',
        marginTop: '1em'
    },
    loader: {
        width: 150,
        height: 150
    },
    loaderContainer: {
        padding: '6em',
        display: 'flex',
        justifyContent: 'center'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '2rem',
        marginTop: '5%'
    },
    table: {
        width: 'auto',
        margin: 'auto'
    },
    desc: {
        margin: '2em'
    }
}


export default CancelOrders;