import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {IconWithText} from "../../dumb";
import {FaTrash, FaPlusSquare} from "react-icons/fa";
import NavigationBar from "../NavigationBar/NavigationBar";
import FetchView from "../../dumb/FetchView/FetchView";
import {StockNotificationStore} from "../../../stores/StockNotificationStore";
import {ArticlesStore} from "../../../stores";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Modal, Heading,Section, Button, Container} from 'react-bulma-components/dist';
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import RestClient from "../../../shared/Network/RestClient";
import {toast} from "react-toastify";
import Select from "react-select";
import {TableBody} from "@material-ui/core";

@observer
class StockNotifications extends Component {
    @ observable localstate = {
        showModal: false,
        notif: {
            firstName: "",
            lastName: "",
            email: "",
            sku: "",
            stock: null
        },
        loading: false,
        disabled: true
    }

    componentDidMount() {
        this.getCurrentUser()
    }

    async getCurrentUser() {
        const request = RestClient.prepareRequest('GET', 'me');
        try {
            const response = await RestClient.sendRequest(request);
            this.localstate.notif.firstName = response.data.firstName;
            this.localstate.notif.lastName = response.data.lastName;
            this.localstate.notif.email = response.data.email;
        } catch (e) {
            toast.error("Couldn't load user" + e)
        }
    }

    deleteProduct(nr){
        const confirmed = confirm('Are you sure you want to delete this notification request?');
        if (confirmed) {
            StockNotificationStore.deleteItem(nr)
        }
    };

    openModal(){
        this.localstate.showModal = true
    }

    closeModal(){
        this.localstate.showModal = false
    }

    changeVal(key, val){
        this.localstate.notif[key] = val
        //this.localstate.disabled = Object.values(this.localstate.notif).some(item => item === "")
    }

    changeArt(val){
        this.localstate.notif.sku = val.value
        this.localstate.notif.stock = parseInt(val.data.pixi.stock)
    }


    renderField(key, i){
        let label
        if (key === "firstName"){
            label = "First Name"
        } else if (key === "lastName"){
            label = "Last Name"
        } else if (key === "email"){
            label = "Email"
        } else {
            label = "SKU"
        }
        return(
            <Field key={i}>
                <Label>{label}</Label>
                <Control>
                    <Input
                        type="text"
                        value={this.localstate.notif[key]}
                        onChange={(e) => this.changeVal(key, e.target.value)}>
                    </Input>
                </Control>
            </Field>
        )
    }

    async save() {
        const {notif} = this.localstate;
        this.localstate.loading = true;
        let success;
        success = await StockNotificationStore.createItem(notif);
        this.localstate.loading = false;
        console.log('Success')
        console.log(success);
        if (success.success){
            toast.success("Request was successfully created.")
        } else {
            toast.error("An error occurred. " + success.error.message.errors[0].message)
        }
        this.localstate.showModal = false
        this.localstate.notif.sku = ""
        this.localstate.notif.stock = null
    }

    render(){
        const disabled = Object.values(this.localstate.notif).some(item => item === "")
        const {showModal, loading} = this.localstate
        return(
            <div>
                <NavigationBar title='Stock Notification'>
                    <Button onClick={() => this.openModal()}>
                        <IconWithText icon={<FaPlusSquare/>} text= "Add notification request"/>
                    </Button>
                </NavigationBar>
                <Modal show={showModal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                    <FetchView store={ArticlesStore}>
                        <Modal.Content style={styles.innerModal}>
                            <Section>
                                <Heading>Add notification request</Heading>
                                {["firstName", "lastName", "email"].map((item, i) => {
                                    return this.renderField(item, i)
                                })}
                                <Field>
                                    <Label>product</Label>
                                    <Control>
                                        <Select
                                            options = {ArticlesStore.list.map(item => {
                                                return {label: item.name, value: item.ordernumber, data: item}
                                            })}
                                            onChange = {(e) => this.changeArt(e)}
                                        />
                                    </Control>
                                </Field>
                                <Button className='is-primary' fullwidth
                                        onClick={() => this.save()}
                                        loading={loading}
                                        disabled={disabled}
                                >Save notification request</Button>
                            </Section>
                        </Modal.Content>
                    </FetchView>
                </Modal>
                <Container>
                    <Section>
                        <FetchView store={StockNotificationStore}>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>first name</TableCell>
                                        <TableCell>last name</TableCell>
                                        <TableCell>email</TableCell>
                                        <TableCell>product</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {StockNotificationStore.list.map((item, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{item.firstName}</TableCell>
                                        <TableCell>{item.lastName}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.sku}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => this.deleteProduct(item)}><FaTrash/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </FetchView>
                    </Section>
                </Container>
            </div>
        );
    };
}

const styles = {
    innerModal: {
        backgroundColor: 'white'
    }
}


export default StockNotifications;