import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container, Heading, Button, Section} from 'react-bulma-components/dist';
import {Checkbox} from "react-bulma-components/lib/components/form";
import {Input} from "react-bulma-components/lib/components/form";
import Papa from "papaparse";
import {toast} from "react-toastify";
import Select from "react-select";
import { generalFields, csvFields } from "./RequestedOrderDataFields";
import { ShopwareOrder, AddressDetails, OrderLine } from "./ShopwareOrder";
import RestClient from "../../../shared/Network/RestClient";
import FetchView from "../../dumb/FetchView/FetchView";
import {SWParamsStore} from "../../../stores/SWParamsStore";

const paymentIdWhenExternalInvoice = 198 // Ersatzlieferung

@observer
class OrdersImport extends Component {
    basestate = {
        csvData: null,
        nrOrdersPlaced: 0,
        erroneousOrderIds: [],
        headerFieldsMapping: {},
        useShippingAddressAsBillingAddress: false,
        useExternalInvoice: false,
        generalFieldValues: { "customerNumber": "" },
        csvHeaderFields: [],
        orderIdentifier: "",
        articledetails: [],
        billingdetails: [],
        loadingOrderPlacing: false,
    }
    
    @observable localstate = this.basestate;

    resetState() {
        this.localstate = this.basestate
        generalFields.forEach(orderField => {
            this.localstate.generalFieldValues[orderField.name] = ""
        })
        csvFields.forEach(headerField => {
            this.localstate.headerFieldsMapping[headerField.name] = ""
        })
    }

    // fetches all active articles
    async getArticleDetails() {
        const request = RestClient.prepareRequest('GET', 'articles/active/withdetails');
        const response = await RestClient.sendRequest(request);
        this.localstate.articledetails = response.data;
    }

    setDefaultSelectedValues(){
        this.localstate.orderIdentifier = this.localstate.csvHeaderFields[0].value
        let index = 1 // skip the order identifier header
        for (const [key] of Object.entries(this.localstate.headerFieldsMapping)) {
            const csvHeader = this.localstate.csvHeaderFields[index]
            if(csvHeader && csvHeader.value){
                this.localstate.headerFieldsMapping[key] = csvHeader.value
                index++
            }else{ break }
        }
    }

    async getBillingAddress() {
        const customerNumber = this.localstate.generalFieldValues.customerNumber
        const request = RestClient.prepareRequest('GET', 'customers/address/' + customerNumber);
        const response = await RestClient.sendRequest(request);
        if(response.data.length === 0){
            throw("Customer not found or has no billing adress")
        }
        this.localstate.billingdetails = response.data;
    }

    // read the uploaded csv, extract headers and data
    handleFileRead() {
        console.log("Uploading CSV...");
        try {
            const content = this.fileReader.result;
            const results = Papa.parse(content, {header: true});
            if (results.errors.length > 0) {
                throw('invalid format')
            }
            if (results.data.length === 0) {
                throw('no data found');
            }
            const headers = Papa.parse(content).data[0]
            const headersDisplay = []
            headers.map(item => (
                headersDisplay.push({value: item, label: item})
            ))
            const emptyOption = {value: "", label: " - "}
            headersDisplay.push(emptyOption)
            this.localstate.csvHeaderFields = headersDisplay
            this.setDefaultSelectedValues()
            this.localstate.csvData = results.data
            toast.success('Successfully uploaded the csv file')
        } catch (error) {
            console.log(error);
            toast.error(error)
        }

    }

    handleFileChange(file) {
        this.fileReader = new FileReader();
        this.resetState();
        if(file){
            this.fileReader = new FileReader();
            this.fileReader.onloadend = this.handleFileRead.bind(this);
            this.fileReader.readAsText(file);
        }
    }

    updateSelectedValue(e) {
        this.localstate.orderIdentifier = e
    }

    updateGeneralFieldValues(key, value){
        this.localstate.generalFieldValues[key] = value
    }

    updateMapping(key, value){
        this.localstate.headerFieldsMapping[key] = value
    }

    getParameterOptions(field){
        const parameterName = field.parameterName
        const labelColumnNames = field.labelColumnNames
        const valueColumnName = field.valueColumnName
        if (SWParamsStore.status.fetched){
            const parameters = SWParamsStore.list[parameterName]
            const options = []
            parameters.map(parameter => {
                let label = ""
                labelColumnNames.forEach((columnName, id) => {
                    if(parameter[columnName] !== ""){
                        if(id > 0){
                            label += " - "
                        }
                        let labelValue = parameter[columnName]
                        if(labelValue[0]==="<"){ // strip from html
                            labelValue = labelValue.substring(
                                labelValue.indexOf(">") + 1,
                                labelValue.lastIndexOf("<")
                            );
                        }
                        label += labelValue
                    }
                })
                if(label !== ""){
                    options.push({value: parameter[valueColumnName], label: label})
                }
            })
            return options
        }
    }

    buildOrdersFromCSV(parsedCSV) {
        const {orderIdentifier, headerFieldsMapping} = this.localstate // header name to identify rows belonging to the same order

        let groupedOrders = {}

        parsedCSV.forEach(row => {
            const orderIdentity = row[orderIdentifier]
            if(groupedOrders.hasOwnProperty(orderIdentity)){
                let previouslyFoundOrder = groupedOrders[orderIdentity]
                previouslyFoundOrder.details.push(this.getOrderLine(row))
            }else{
                let newOrder = new ShopwareOrder()
                newOrder.shipping = this.getAddressFromCSV(row)
                if(this.localstate.useShippingAddressAsBillingAddress){
                    newOrder.billing = newOrder.shipping
                }else{
                    newOrder.billing = this.getAddressFromBillingDetails()
                }
                newOrder.details.push(this.getOrderLine(row))
                this.setOrderFields(newOrder, row)
                groupedOrders[orderIdentity] = newOrder
                if(headerFieldsMapping.referenceNr !== ""){
                    newOrder.additionalFields.referenceNr = row[headerFieldsMapping.referenceNr]
                }
            }
        })
        for (const entry of Object.entries(groupedOrders)) {
            const order = entry[1]
            order.calculateInvoiceAmount();
        }

        return groupedOrders
    }

    getAddressFromBillingDetails(){
        const {billingdetails} = this.localstate
        let address = new AddressDetails()

        address.customerId = billingdetails[0].id
        address.countryId = billingdetails[0]["country_id"]
        address.company = billingdetails[0].company || ""
        address.salutation = billingdetails[0].salutation
        address.firstName = billingdetails[0].firstname
        address.lastName = billingdetails[0].lastname
        address.street = billingdetails[0].street
        address.zipCode = billingdetails[0].zipCode
        address.city = billingdetails[0].city

        return address
    }

    getAddressFromCSV(csvRow){
        const {headerFieldsMapping, generalFieldValues, billingdetails} = this.localstate
        let address = new AddressDetails()

        address.customerId = billingdetails[0].id
        address.company = csvRow[headerFieldsMapping.company] || ""
        address.firstName = csvRow[headerFieldsMapping.firstName] || ""
        address.lastName = csvRow[headerFieldsMapping.lastName] || ""
        address.street = csvRow[headerFieldsMapping.street] || ""
        address.zipCode = csvRow[headerFieldsMapping.zipCode] || ""
        address.city = csvRow[headerFieldsMapping.city] || ""
        address.referenceNr = csvRow[headerFieldsMapping.referenceNr] || ""

        const givenCountry = csvRow[headerFieldsMapping.country]
        if(givenCountry){
            if (SWParamsStore.status.fetched) {
                const countryOptions = SWParamsStore.list['countries']
                console.log(countryOptions)
                const foundCountry = countryOptions.find(option => option["countryname"] === givenCountry )
                if(!foundCountry){
                    throw('Country ' + givenCountry + ' does not exist')
                }else{
                    address.countryId = foundCountry["id"]
                }
            }
        }else{
            address.countryId = generalFieldValues.country
        }

        for (const [key, value] of Object.entries(address)) {
            const field = csvFields.find(field => field.name===key )
            if(field && field.isRequired && value === ""){
                throw("Field '" + key + "' may not be empty")
            }
        }

        return address
    }

    setOrderFields(order, csvRow) {
        const {generalFieldValues, headerFieldsMapping, useExternalInvoice} = this.localstate
        order.orderTime = new Date().toISOString()
        generalFields
            .filter(field => field.isOrderField)
            .forEach(field => {
                order[field.name] = generalFieldValues[field.name];
            })
        order.customerId = order.billing.customerId

        if(useExternalInvoice) {
            order.paymentId = paymentIdWhenExternalInvoice
        }

        const givenDispatchID = csvRow[headerFieldsMapping.dispatchID]
        const dispatchFieldInfo = generalFields.find(field => field.label === "Dispatch Method")
        if(givenDispatchID){
            if (SWParamsStore.status.fetched) {
                const dispatchMethods = SWParamsStore.list[dispatchFieldInfo.parameterName]
                const foundMethod = dispatchMethods.find(method => method[dispatchFieldInfo.valueColumnName].toString() === givenDispatchID )
                if(!foundMethod){
                    throw(dispatchFieldInfo.name + ' ' + givenDispatchID + ' does not exist')
                }else{
                    order[dispatchFieldInfo.name] = foundMethod.id
                }
            }
        }
    }

    getOrderLine(csvRow) {
        const {headerFieldsMapping, articledetails} = this.localstate
        let orderLine = new OrderLine()

        orderLine.articleNumber = csvRow[headerFieldsMapping.articleNumber]
        orderLine.price = parseFloat(csvRow[headerFieldsMapping.price])
        orderLine.quantity = parseInt(csvRow[headerFieldsMapping.quantity])

        const shopwareArticle = articledetails.find(x => x.ordernumber === orderLine.articleNumber)
        if(!shopwareArticle){
            throw("could not find article with articleNumber " + orderLine.articleNumber)
        }
        orderLine.articleName = shopwareArticle.name
        orderLine.taxId = shopwareArticle.taxID
        orderLine.taxRate = shopwareArticle.tax
        orderLine.articleId = shopwareArticle.id

        return orderLine
    }

    isFormIncomplete() {
        const identifierMissing = this.localstate.orderIdentifier === ""
        let headerFieldMissing = false
        for (let [key, value] of Object.entries(this.localstate.headerFieldsMapping)) {
            let field = csvFields.find(field => field.name===key )
            if(field.isRequired && value === ""){
                headerFieldMissing = true
                break;
            }
        }
        const generalFieldMissing = Object.entries(this.localstate.generalFieldValues)
            .some(([key, value]) => {
                if(key === "paymentId" && this.localstate.useExternalInvoice){
                    return false
                }else{
                    return value === ""
                }
            })

        const ordersHaveAlreadyBeenSent = this.localstate.nrOrdersPlaced > 0

        return identifierMissing || headerFieldMissing || generalFieldMissing || ordersHaveAlreadyBeenSent

    }

    async placeOneOrder(o){
        const request = RestClient.prepareRequest('POST', 'orders')
        request.setData(o)
        try {
            return await request.send()
        } catch (e) {
            return e
        }
    }

    async placeOrders(){
        const {csvData} = this.localstate
        this.localstate.loadingOrderPlacing = true
        try{
            await this.getArticleDetails()
            await this.getBillingAddress()
            const orders = this.buildOrdersFromCSV(csvData)
            let successCount = 0
            this.localstate.erroneousOrderIds = [];

            console.log(this.localstate);
            for (const [key, order] of Object.entries(orders)) {
                const result = await this.placeOneOrder(order);
                if(result.success && result.data.success){
                    successCount++
                    this.localstate.nrOrdersPlaced++
                }else{
                    this.localstate.erroneousOrderIds.push(key)
                }
            }
            if(successCount > 0){
                toast.success(successCount + " orders have been placed successfully.")
            }
            this.localstate.loadingOrderPlacing = false
            if(this.localstate.erroneousOrderIds.length > 0){
                toast.error("There was an error with the following orders: " + this.localstate.erroneousOrderIds.toString())
            }
        }catch(error){
            toast.error(error)
            this.localstate.loadingOrderPlacing = false
        }
    }

    render() {
        const {csvData,
            csvHeaderFields,
            loadingOrderPlacing,
            nrOrdersPlaced,
            erroneousOrderIds,
            useShippingAddressAsBillingAddress,
            useExternalInvoice
        } = this.localstate
        return (<div>
            <NavigationBar title='Import orders from CSV'>
            </NavigationBar>
            <Container>
                <Section>
                    <Heading size={5}>1. Input File (CSV)</Heading>
                    <table style={styles.matchingTable}>
                        <tbody>
                        <tr>
                            <td style={styles.label}>CSV-File</td>
                            <td style={styles.valueField}>
                                <input
                                    type="file"
                                    accept='.csv'
                                    onChange={e => this.handleFileChange(e.target.files[0])}
                                />
                            </td>
                        </tr>
                        {csvData && <tr>
                            <td style={styles.label}>Identifier</td>
                            <td style={styles.valueField}>
                                <Select
                                    className = "basic-multi-select"
                                    options = {csvHeaderFields}
                                    defaultValue = {csvHeaderFields[0]}
                                    onChange = {e => this.updateSelectedValue(e.value)}
                                    placeholder = "Identifier der Bestellung"
                                />
                            </td>
                        </tr>}
                        {csvData && <tr>
                            <td style={styles.label}>For which customer?</td>
                            <td style={styles.valueField}>
                                <Input
                                    onChange = {e => this.updateGeneralFieldValues("customerNumber", e.target.value)}
                                    value = {this.localstate.generalFieldValues.customerNumber}
                                    type="number"
                                    options = {csvHeaderFields.slice()}
                                    placeholder='Customer Number'
                                />
                            </td>
                        </tr>}
                        {csvData && <tr>
                            <td style={styles.label}>Use shipping address (from csv) as billing address?</td>
                            <td style={styles.valueField}>
                                <Checkbox onChange={() => this.localstate.useShippingAddressAsBillingAddress = !useShippingAddressAsBillingAddress}
                                          defaultChecked={ useShippingAddressAsBillingAddress}/>
                            </td>
                        </tr>}
                        {csvData && <tr>
                            <td style={styles.label}>Use external invoice? (= use 'Ersatzlieferung' payment type)</td>
                            <td style={styles.valueField}>
                                <Checkbox onChange={() => this.localstate.useExternalInvoice = !useExternalInvoice}
                                          defaultChecked={ useExternalInvoice }/>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </Section>
                <FetchView store={SWParamsStore}>
                    {csvData && <Section>
                        <Heading size={5}>2. Match Table Attributes</Heading>
                        <table style={styles.matchingTable}>
                            <tbody>
                            {csvFields.map((item, key) => (
                                <tr key={key}>
                                    <td style={styles.label}>{item.label}{item.isRequired && "*"}</td>
                                    <td style={styles.valueField}>
                                        <Select
                                            className = "basic-multi-select"
                                            defaultValue = {csvHeaderFields[key+1] || csvHeaderFields[csvHeaderFields.length-1]}
                                            onChange = {e => this.updateMapping(item.name, e.value)}
                                            options = {csvHeaderFields.slice()}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Section> }
                    {csvData && <Section>
                        <Heading size={5}>3. Define additional attributes</Heading>
                        <table style={styles.matchingTable}>
                            <tbody>
                            {generalFields.map((field, id)=> (
                                <tr key={id}>
                                    <td style={styles.label}>{field.label}</td>
                                    <td style={styles.valueField}>
                                        <Select
                                            isDisabled={field.name === "paymentId" && useExternalInvoice}
                                            className = "basic-multi-select"
                                            onChange = {e => this.updateGeneralFieldValues(field.name, e.value)}
                                            options = {this.getParameterOptions(field)}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Section> }
                    { nrOrdersPlaced > 1 && <p>Successfully sent orders: {nrOrdersPlaced}</p>}
                    { erroneousOrderIds.length !== 0 && <p>Unsuccessful orders: {erroneousOrderIds.toString()}</p>}
                    {csvData && <Section>
                        <Button onClick={this.placeOrders.bind(this)}
                                color='primary'
                                fullwidth
                                loading={loadingOrderPlacing}
                                disabled={this.isFormIncomplete()}
                        >Place Orders</Button>
                    </Section> }
                </FetchView>
            </Container>
        </div>);
    }
}

const styles = {
    matchingTable:{
        width: "100%",
        maxWidth: "800px"
    },
    label:{
        width: "20%",
        padding: "10pt"
    },
    valueField: {
        padding: "10pt"
    }
}

export default OrdersImport;