import React, { Component } from "react";
import {
  Box,


} from "react-bulma-components/dist";

class ChallengeCard extends Component {
  render() {
    return (
      <Box onClick={this.props.onClick}>
        <div style={styles.header}>{this.props.challenge.title}</div>
        <div>{this.props.challenge.description}</div>
      </Box>
    );
  }
}

const styles = {
  header: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
};

export default ChallengeCard;
