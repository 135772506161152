import {observer} from "mobx-react";
import React, {Component} from 'react';
import {observable} from "mobx";
import Translator from "../../../../shared/Translator";
import {Button, Container, Box} from 'react-bulma-components/dist'
import FetchView from "../../../dumb/FetchView/FetchView";
import {toast} from "react-toastify";
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TranslationCache from "../../../../shared/TranslationCache";
import {AttributeValueRow} from "./AttributeValueRow";
import {AttributeValuesList} from "./AttributeValuesList";

const INITIAL_STATE =  {
    status: {
        translating: false,
        translated: false,
        saving: false,
        saved: false
    },
    translation: {
        article: null,
        translation: {
            name: '',
            description: '',
            description_long: '',
            metaTitle: '',
            value: '',
            truevariantsquantity: { // any attribute of type list
                splitAttributes: [], // whole attribute list content
                originalList: [], // german values
                translatedList: [], // translated values
                valueTypes: [] // which editor is needed to edit the value
            }
        },
        autotranslated: {
            name: false,
            description: false,
            description_long: false,
            metaTitle: false,
            value: false,
            truevariantsquantity: false
        },
        changedNow :{
            name: false,
            description: false,
            description_long: false,
            metaTitle: false,
            value: false,
            truevariantsquantity: false
        }
    }

};

@observer
class ArticleTranslation extends Component {
    translator = null;
    translationStore = null;

    @observable localstate = INITIAL_STATE;

    constructor(props) {
        super(props);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let autotranslatePropChanged = this.props.autotranslate !== prevProps.autotranslate;
        let autotranslateEnabled = this.props.autotranslate;
        let itemChanged = this.props.id !== prevProps.id;
        if (autotranslateEnabled && (itemChanged || autotranslatePropChanged)) {
            await this.translateWithDeepl(this.props.userID);
        }
    }

    async init() {
        const {language, item, translation={}, definition} = this.props;

        this.localstate = INITIAL_STATE;
        window.scrollTo(0, 0);

        this.translator = new Translator(language.short);

        // await ArticleStore.fetchData();
        // const article = ArticleStore.list.find(x => x.id === parseFloat(articleID));
        try {
            if (item) {
                let updatedTranslation = {};
                let changedNow = {};
                Object.keys(definition).forEach(key => {
                    if (item[key] && (!translation || !translation[key])) { // no translation yet
                        if(definition[key].type === 'list'){
                            let valuesObj = this.splitAndExtractValuesOfAttributeList(item[key], definition[key]);
                            let foundTranslation = false;
                            valuesObj.originalList.forEach((text, index) => {
                                let cachedTranslation = TranslationCache.query(language.value, text);
                                if(cachedTranslation){
                                    valuesObj.translatedList[index] = cachedTranslation.translation;
                                    foundTranslation = true;
                                }
                            })
                            updatedTranslation[key] = valuesObj;
                            changedNow[key] = foundTranslation;

                        }else{
                            let cache = TranslationCache.query(language.value, item[key]);
                            if(cache){
                                updatedTranslation[key] = cache.translation;
                                changedNow[key] = true;
                            }else{
                                updatedTranslation[key] = null;
                                changedNow[key] = false;
                            }
                        }
                    } else if (item[key] && translation[key]) { // translation exists
                        if(definition[key].type === 'list'){
                            let translatedValues = this.splitAndExtractValuesOfAttributeList(item[key], definition[key]);
                            let {originalList} = this.splitAndExtractValuesOfAttributeList(translation[key], definition[key]);
                            translatedValues.translatedList = originalList;
                            updatedTranslation[key] = translatedValues;
                        }else{
                            updatedTranslation[key] = translation[key];
                        }
                    }
                });
                this.localstate.translation.changedNow = changedNow;
                this.localstate.translation.article = item;
                this.localstate.translation.translation = updatedTranslation;
            } else {
                throw new Error('Invalid item');
            }
        } catch (e) {
            throw (e);
        }
    }

    async translateWithDeepl() {
        this.localstate.status.translating=true;
        const {article, translation} = this.localstate.translation;
        const {definition} = this.props;

        let toTranslate = [];
        let articleTranslationMapping = [];
        Object.keys(definition).forEach(key => {
            let hasNoTranslation = translation[key]?.translatedList ? translation[key].translatedList.includes('') : (!translation[key] || translation[key] === '');
            if (article[key] && hasNoTranslation) {
                if(definition[key].type === 'list') {
                    let values = [];
                    let indices = [];
                    translation[key].translatedList.map((value, index) => {
                        if(value === ''){
                            values.push(translation[key].originalList[index]);
                            indices.push(index);
                        }
                    })
                    let prevLength = toTranslate.length;
                    toTranslate = toTranslate.concat(values);
                    articleTranslationMapping.push({key: key, start: prevLength, amount: values.length, indices: indices})
                }else{
                    toTranslate.push(article[key].replace(/\r\n|\r|\n/g, '<br>'));
                    articleTranslationMapping.push({key: key, start: toTranslate.length-1, amount: 1, indices: null});
                }
            }
        });
        const {data} = await this.translator.translateText(toTranslate);

        articleTranslationMapping.forEach(obj => {
            this.localstate.translation.autotranslated[obj.key] = true;
            this.localstate.translation.changedNow[obj.key] = true;
            if(definition[obj.key].type === 'list'){
                for (let i = 0; i < obj.amount; i++){
                    this.localstate.translation.translation[obj.key].translatedList[obj.indices[i]] = data[obj.start+i].text;
                }
            }else{
                this.localstate.translation.translation[obj.key] = data[obj.start].text;
            }
        })
        this.localstate.status.translated=true;
        this.localstate.status.translating=false;
    }

    async saveTranslation() {
        try {
            const {definition, language} = this.props;

            const {article, translation} = this.localstate.translation;
            let issues = [];
            Object.keys(definition).forEach(key => {
                let attributeExists = !!article[key];
                let hasNoTranslation = translation[key]?.translatedList ? translation[key].translatedList.includes('') : (!translation[key] || translation[key] === '');
                if(attributeExists && hasNoTranslation){
                    issues.push(definition[key].header);
                }
            });
            this.localstate.status.saving = true;

            let bodyToSend = article.id ?  {
                id: article.id
            } : {};


            Object.keys(translation).forEach(key => {
                let attributeExists = !!article[key];
                let hasNoTranslation = translation[key]?.translatedList ? translation[key].translatedList.includes('') : (!translation[key] || translation[key] === '');
                if(attributeExists && hasNoTranslation){
                    // ignore the untranslated attributes
                    return;
                }

                if (definition[key]) {
                    let translatedValue = translation[key];
                    if(definition[key].type === 'list'){
                        translatedValue = this.rebuildTranslatedAttributeList(translation[key].splitAttributes, definition[key].translate_indices, translation[key].translatedList, definition[key].ordered_separators);
                    }

                    if (definition[key].postAs) {
                        bodyToSend[definition[key].postAs] = translatedValue;
                    }else if (definition[key].core === false) {
                        bodyToSend["__attribute_" + key] = translatedValue;
                    }else {
                        bodyToSend[key] = translatedValue;
                    }
                }

                if(definition[key]?.type === 'list'){
                    translation[key].translatedList.forEach((value, index) => {
                        if(value !== '' && value.length < 100){
                            TranslationCache.remember(language.value, translation[key].originalList[index], value);
                        }
                    })
                }else if(article[key].length < 100){
                    TranslationCache.remember(language.value, article[key], translation[key]);
                }
            });
            await this.props.onSave(bodyToSend, issues);
        } catch (e) {
            toast.error(e);
        }
        this.localstate.status.saving = false;

    }

    splitAndExtractValuesOfAttributeList(attributeListString, attributeDefinition) {
        if (!attributeListString) {
            return [];
        }
        let splitAttributes = attributeListString.split(attributeDefinition.ordered_separators[0]);
        splitAttributes = splitAttributes.map(key => {
            return key.split(attributeDefinition.ordered_separators[1]);
        });

        let extractedValues = [];
        let accordingTypes = [];
        splitAttributes.forEach((list, k) => {
            list.forEach((value, i) => {
                let index = attributeDefinition.translate_indices.indexOf(i);
                if(index > -1){
                    let indexOfExistingValue = extractedValues.indexOf(value);
                    if(indexOfExistingValue < 0){
                        // doesn't exist yet
                        let newLength = extractedValues.push(value.replace(/(?:\r\n|\r|\n)/g, '<br>'));
                        accordingTypes.push(attributeDefinition.edit_types[index]);
                        splitAttributes[k][i] = newLength-1; // save index where translation will be found later
                    }else{
                        splitAttributes[k][i] = indexOfExistingValue;
                    }
                }
            })
        })
        let emptyTranslations = Array(extractedValues.length).fill('');
        return {splitAttributes: splitAttributes, originalList: extractedValues, valueTypes: accordingTypes, translatedList: emptyTranslations};
    }

    rebuildTranslatedAttributeList(splitAttributes, indicesToBeTranslated, translatedList, separators) {
        let tmp = splitAttributes.map(function(arr) {
            return arr.slice();
        });
        tmp.forEach((list, k) => {
            list.forEach((value, i) => {
                if(indicesToBeTranslated.includes(i)){
                    tmp[k][i] = translatedList[value];
                }
            })
        })
        return tmp.map(list => list.join(separators[1])).join(separators[0]);
    }

    onChange(key, value) {
        if(this.props.definition[key].type === 'list'){
            this.localstate.translation.translation[key].translatedList = value;
        }else{
            this.localstate.translation.translation[key] = value;
        }

        this.localstate.translation.changedNow[key] = true;
    }

    skipTranslation() {
        this.props.onSkip && this.props.onSkip();
    }

    render() {
        const {language, item, definition, showFields} = this.props;
        const showAllFields = showFields === 'all';
        const {article, translation, autotranslated, changedNow} = this.localstate.translation;
        const {translating, saving} = this.localstate.status;
        const defArray = Object.keys(definition);
        return (
            <div>
                <Container>

                    <FetchView call={this.init.bind(this)} refresh={[language, item]}>
                        {article && translation &&
                            <div>
                                {defArray.map(key => {
                                    const def = definition[key];
                                    let hasNoTranslation = translation[key]?.translatedList ? translation[key].translatedList.includes('') : !translation[key];
                                    let hasChanged = changedNow[key];
                                    let attributeExists = !!article[key];

                                    return attributeExists && (hasChanged || hasNoTranslation || showAllFields || showFields.includes(key)) && (
                                        <Box key={key}>
                                            <div style={styles.header}>
                                                {def.header}
                                                {autotranslated[key] && def.type !== 'list' && <div style={styles.warning}>Important: this field was translated by DEEPL, pls check carefully</div>}
                                                {autotranslated[key] && def.type === 'list' && <div style={styles.warning}>Important: some of these fields were translated by DEEPL, pls check carefully</div>}
                                            </div>
                                            {def.type === 'list'
                                                ? <AttributeValuesList
                                                    key={key}
                                                    isDisabled={saving || translating}
                                                    type={def.type}
                                                    attributeData={translation[key]}
                                                    attributeValue={article[key]}
                                                    id={key}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                : <AttributeValueRow
                                                    key={key}
                                                    isDisabled={saving || translating}
                                                    type={def.type}
                                                    translatedValue={translation[key]}
                                                    attributeValue={article[key]}
                                                    id={key}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            }
                                        </Box>
                                    )
                                })}
                                <Button fullwidth className='is-primary' loading={saving}  onClick={this.saveTranslation.bind(this)}>Save translation</Button>
                                <div style={styles.skipButton}>
                                 <Button fullwidth disabled={saving}  onClick={this.skipTranslation.bind(this)}>Not sure? Skip translation</Button>
                                </div>
                            </div>
                        }
                    </FetchView>
                </Container>
            </div>
        );
    }

}

const styles = {
    header: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 20,
        marginBottom: 20,
        flexDirection: 'column'
    },
    warning: {
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
        color: 'orange'
    },
    skipButton: {
        marginTop: '15px'
    },
    button: {
        width: '100%'
    }
}

export default ArticleTranslation;