import React, {Component} from 'react';
import {AttributeValueRow} from "./AttributeValueRow";

class AttributeValuesList extends Component {

    constructor(props) {
        super(props);
        this.onAttributeValueChange = this.onAttributeValueChange.bind(this);
    }

    onAttributeValueChange(index, value) {
        let newTranslatedList = this.props.attributeData.translatedList.slice();
        newTranslatedList[index] = value;
        this.props.onChange(this.props.id, newTranslatedList);
    }

    render() {
        return(
            <div>
                {this.props.attributeData.originalList.map((value, index) => {
                    return(
                        <AttributeValueRow
                            key={index}
                            isDisabled={this.props.isDisabled}
                            type={this.props.attributeData.valueTypes[index]}
                            translatedValue={this.props.attributeData.translatedList[index]}
                            attributeValue={value}
                            id={index}
                            onChange={this.onAttributeValueChange}
                        />
                    )
                })}
            </div>
        );
    }
}

export {AttributeValuesList};