import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Button, Container, Modal, Heading } from "react-bulma-components";
import { Label, Textarea } from "react-bulma-components/lib/components/form";
import { Table, TableCell, TableRow } from "@material-ui/core";
import { FiDownload } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";
import { UserStore } from "stores/UserStore";
import FetchView from "components/dumb/FetchView/FetchView";
import { MarketStatementApprovalStore } from "../../../../stores/Markets/MarketStatementApprovalStore";
import { toast } from "react-toastify";
@observer
export default class StatementApproval extends Component {
  @observable localstate = {
    status: {
      loadingApproved: false,
      loadingDenied: false,
      denyingStatement: false,
    },
    form: {
      id: null,
      MarketStallStatementId: null,
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      approved: null,
      note: null,
    },
  };

  handleChange(text) {
    this.localstate.form.note = text;
  }

  openS3Url(url) {
    window.open(url);
  }

  async approvalOfStatement(id, user, approved, approval) {
    try {
      if (
        approval
          ? confirm("Are you sure you want to change it?")
          : confirm(
              `Are you sure you want to ${
                approved ? "approve" : "deny"
              } this Statement?`
            )
      ) {
        const { form } = this.localstate;

        if (approved) {
          this.localstate.status.loadingApproved = true;
        } else {
          this.localstate.status.loadingDenied = true;
        }

        form.MarketStallStatementId = id;
        form.userEmail = user[0].email;
        form.userFirstName = user[0].firstName;
        form.userLastName = user[0].lastName;
        form.approved = approved;

        let result;
        if (approval) {
          form.id = approval.id;
          result = await MarketStatementApprovalStore.updateItem(form);
        } else {
          result = await MarketStatementApprovalStore.createItem(form);
        }
        if (result) {
          toast.success(
            `Successfully ${approved ? "approved" : "denied"} Statement.`
          );
          this.clearStates();
        }
      }
    } catch (err) {
      toast.error("Something went wrong " + err);
    }
  }

  clearStates() {
    Object.keys(this.localstate.form).forEach((key) => {
      this.localstate.form[key] = null;
    });
    Object.keys(this.localstate.status).forEach((key) => {
      this.localstate.status[key] = false;
    });
    this.props.clearStates();
    this.localstate.approval = null;
  }

  handleNoteModal() {
    const key = "denyingStatement";
    this.localstate.status[key] = !this.localstate.status[key];
  }
  render() {
    const { edit, form, market, approval } = this.props;
    const { note } = this.localstate.form;
    const marketDate = form.marketDate.split("T")[0];
    const { loadingApproved, loadingDenied, denyingStatement } =
      this.localstate.status;
    return (
      <>
        <Modal show={edit} onClose={() => this.props.clearStates()}>
          <FetchView store={UserStore}>
            <Container style={styles.container}>
              <Heading>{`${market.name}`}</Heading>
              <Table>
                <TableRow>
                  <TableCell>
                    <Label>Datum</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{marketDate}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Umsatz Bar</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.turnoverCash}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Umsatz Karte</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.turnoverCard}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Umsatz Gesamt</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.turnoverTotal}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zu Beginn</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.cashBeginn}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zum Ende</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.cashEnd}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Hin</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.travelCostsTo}</Label>
                  </TableCell>
                  {!form.urlReceiptTravelCostsTo ? (
                    <TableCell>
                      <RiCloseCircleLine color={"#ff3860"} size={20} />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={styles.downloadReceiptWrapper}
                      onClick={() =>
                        this.openS3Url(form.urlReceiptTravelCostsTo)
                      }
                    >
                      <FiDownload color={"#23d160"} size={20} />
                      <span style={styles.downloadReceiptSpan}>Download!</span>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Zurück</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.travelCostsBack}</Label>
                  </TableCell>
                  {!form.urlReceiptTravelCostsBack ? (
                    <TableCell>
                      <RiCloseCircleLine color={"#ff3860"} size={20} />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={styles.downloadReceiptWrapper}
                      onClick={() =>
                        this.openS3Url(form.urlReceiptTravelCostsBack)
                      }
                    >
                      <FiDownload color={"#23d160"} size={20} />
                      <span style={styles.downloadReceiptSpan}>Download!</span>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Spesen</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.expense}</Label>
                  </TableCell>
                  {!form.urlReceiptExpense ? (
                    <TableCell>
                      <RiCloseCircleLine color={"#ff3860"} size={20} />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={styles.downloadReceiptWrapper}
                      onClick={() => this.openS3Url(form.urlReceiptExpense)}
                    >
                      <FiDownload color={"#23d160"} size={20} />
                      <span style={styles.downloadReceiptSpan}>Download!</span>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kosten für den Marktstand</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.marketCosts}</Label>
                  </TableCell>
                  {!form.urlReceiptMarketCosts ? (
                    <TableCell>
                      <RiCloseCircleLine color={"#ff3860"} size={20} />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={styles.downloadReceiptWrapper}
                      onClick={() => this.openS3Url(form.urlReceiptMarketCosts)}
                    >
                      <FiDownload color={"#23d160"} size={20} />
                      <span style={styles.downloadReceiptSpan}>Download!</span>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Summe gew. Rabatte</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.amountDiscount}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Differenz</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.difference}</Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Einzahlungsbeleg (z.B. Sparkasse)</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.amountDeposited}</Label>
                  </TableCell>
                  {!form.urlReceiptDeposit ? (
                    <TableCell>
                      <RiCloseCircleLine color={"#ff3860"} size={20} />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={styles.downloadReceiptWrapper}
                      onClick={() => this.openS3Url(form.urlReceiptDeposit)}
                    >
                      <FiDownload color={"#23d160"} size={20} />
                      <span style={styles.downloadReceiptSpan}>Download!</span>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Anmerkung</Label>
                  </TableCell>
                  <TableCell>
                    <Label>{form.note}</Label>
                  </TableCell>
                </TableRow>
              </Table>
              <Button
                loading={loadingApproved}
                color={"primary"}
                disabled={approval && approval.approved ? true : false}
                fullwidth
                onClick={() =>
                  this.approvalOfStatement(
                    form.id,
                    UserStore.list,
                    true,
                    approval
                  )
                }
              >
                Approve
              </Button>
              <Button
                loading={loadingDenied}
                color={"danger"}
                disabled={approval && !approval.approved ? true : false}
                fullwidth
                style={styles.dangerButton}
                onClick={() => this.handleNoteModal()}
              >
                Deny
              </Button>
            </Container>
          </FetchView>
        </Modal>
        {denyingStatement && (
          <Modal show={denyingStatement} onClose={() => this.handleNoteModal()}>
            <Container style={styles.noteContainer}>
              <Heading>Note</Heading>
              <Table>
                <TableRow>
                  <Textarea
                    onChange={(e) => this.handleChange(e.target.value)}
                    value={note}
                    placeholder="Tell us why you want to deny this Statement."
                  />
                </TableRow>
              </Table>
              <Button
                style={styles.addNoteButton}
                color={"primary"}
                fullwidth
                onClick={() =>
                  this.approvalOfStatement(
                    form.id,
                    UserStore.list,
                    false,
                    approval
                  )
                }
              >
                Add Note
              </Button>
            </Container>
          </Modal>
        )}
      </>
    );
  }
}

const styles = {
  downloadReceiptWrapper: {
    display: "flex",
    cursor: "Pointer",
    color: "#23d160",
  },
  downloadReceiptSpan: {
    marginLeft: "1em",
  },
  dangerButton: {
    marginTop: "1em",
  },
  container: {
    background: "white",
    paddingInline: "5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
  },
  noteContainer: {
    width: "500px",
    background: "white",
    paddingInline: "1em",
    marginBlock: "15em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
  },
  addNoteButton: {
    marginTop: "2em",
  },
};
