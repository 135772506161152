import React, {Component} from 'react';
import {Box, Button, Icon, Heading } from 'react-bulma-components/dist'
import {  Field, Input, Label, Control} from 'react-bulma-components/lib/components/form';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {PageStructureNotLoggedIn} from "../../dumb";
import {
    Link
} from "react-router-dom";
import RestClient from "../../../shared/Network/RestClient";

@observer
class PasswordForgotten extends Component {
    @observable localstate = {
        mail: null,
        resetting_password: false
    };

    onChange(k, v) {
        this.localstate[k] = v;
    }

    async reset_password() {
        const mail = this.localstate.mail;

        if (mail) {
            const mailComponents = mail.split('@')
            if (mailComponents.length > 1 && mailComponents[1] === "korodrogerie.de") {
                this.localstate.resetting_password = true;
                const request = RestClient.prepareRequest("PUT", "auth/passwordreset");
                request.setData({email: mail})
                try {
                    await request.send();
                    alert("Check your mailbox.");
                } catch(error) {
                    alert("ERROR: Request failed.");
                }
            } else {
                alert("E-mail-adress is not part of KoRo.");
            }
        } else {
            alert("Please enter your E-mail-adress.");
        }
    }

    render() {
        const {mail} = this.localstate;
        return (
            <PageStructureNotLoggedIn>
                <Heading>Passwort vergessen</Heading>
                <Box>
                    <Field>
                        <Label>eMail</Label>
                        <Control iconLeft iconRight>
                            <Input type="text" value={mail} placeholder='name@firma.de' onChange={(e) => this.onChange('mail', e.target.value)}/>
                            <Icon align='left' icon='bars'/>
                        </Control>
                    </Field>
                    {this.localstate.resetting_password ? <div/> : <Button color='primary' fullwidth={true} onClick={this.reset_password.bind(this)}>
                        Passwort zurücksetzen
                    </Button>}
                    <Link to='/login'>Zurück zum Login</Link>
                </Box>
            </PageStructureNotLoggedIn>);
    }
}

export default PasswordForgotten;