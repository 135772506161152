const {REACT_APP_ENV, REACT_APP_BACKEND_NAME} = process.env;

function getBackendApiUrl() {
  const hostName = window.location.hostname
  if(REACT_APP_ENV === 'production' || hostName.includes('live') || hostName === 'oldtoolbox.korodrogerie.de'){
    return 'https://korohelper-backend-live.herokuapp.com/'
  }else if(REACT_APP_BACKEND_NAME) {
    return REACT_APP_BACKEND_NAME
  }else if(REACT_APP_ENV === 'development'){
    return 'https://korohelper-backend-develop.herokuapp.com/'
  }else if(REACT_APP_ENV === 'staging'){
    return 'https://korohelper-backend-master.herokuapp.com/'
  }else{
    return 'http://localhost/'
  }
}

export default {
  API: {
    ENDPOINT: getBackendApiUrl()
  }
};
