import React, {Component} from 'react';
import {Modal, Heading, Button, Container, Columns} from 'react-bulma-components/dist'

import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import RestClient from "../../../shared/Network/RestClient";
import moment from 'moment';
import { toast } from "react-toastify";
import {Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@material-ui/core";
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {IconWithText} from "../../dumb";
import {FaPaperPlane, FaSearch, FaTrash} from "react-icons/fa";
import {Control, Field, Input, Label, Textarea} from "react-bulma-components/lib/components/form";
import FetchView from "../../dumb/FetchView/FetchView";
import {ArticlesStoreSW} from "../../../stores";
import {SWParamsStore} from "../../../stores/SWParamsStore";

@observer
class OrdersScreen extends Component {
    @observable localstate = {
        product: [],
        articles: [],
        from: moment().subtract(1, 'W').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        customergroup: [],
        onlyNotSent: 0,
        results: [],
        loading: false,
        selected: [],
        modal: false,
        page: 0,
        rowsPerPage: 10,
        pageM: 0,
        rowsPerPageM: 5,
        modalMail: false,
        content:
            'Hallo *|FNAME|* *|LNAME|*,'  +
            '\n \n' +
            '-- \n' +
            'service@korodrogerie.de | +49 30 9210790 70\n' +
            '–––\n' +
            'KoRo Handels GmbH \n' +
            'Koppenplatz 9 | 10115 Berlin | DE\n' +
            'Geschäftsführer: Constantinos Calios, Piran Asci\n' +
            'Ust.-ID.: DE 281415364\n' +
            'Gerichtsstand: Berlin | HRB: 156 598 B | Amtsgericht Charlottenburg\n',
        subject: "Benachrichtigung zu Deiner Bestellung *|ONUMBER|* ",
        loadingM: false,
        loadingCancel: false,
        framestyle: "none"
    };

    async sendRequestToServer() {
        const {product, from, to, customergroup, onlyNotSent} = this.localstate;
        // make that dateframe includes the "to" date
        const InclTo = moment(to, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");

        const params = product.concat(',') + '?from=' + from + '&to=' + InclTo + "&customergroup=" +
            customergroup.concat(',') + "&onlynotsent=" + onlyNotSent;
        const request = RestClient.prepareRequest('GET', 'orders/products/' + params);
        this.localstate.loading = true;
        try {
            const response = await RestClient.sendRequest(request);
            this.localstate.results = response.data;
            this.localstate.loading = false;
        } catch (e) {
            this.localstate.loading = false;
            toast.error("Ein Fehler ist aufgetreten." + e)
        }
    }

    async sendMails(){
        const {selected, content, subject} = this.localstate;
        let body = {
            recipients: selected.map(item => ({
                firstname: item.firstname,
                lastname: item.lastname,
                mail: item.email,
                ordernumber: item.ordernumber
            })),
            subject: subject,
            text: content
        }
        const request = RestClient.prepareRequest('POST', "mails")
        request.setData(body)
        this.localstate.loadingM = true
        try {
            await request.send()
            this.localstate.loadingM = false;
            toast.success("Mails erfolgreich versendet!")
            this.localstate.modalMail = false;
        } catch (e) {
            this.localstate.loadingM = false;
            toast.error("Ein Fehler ist aufgetreten." + e)
        }
    }

    async cancelArticlesInOrder() {
        const {product, selected} = this.localstate;
        const reply = confirm('Are you 100% sure, that you want to cancel '+product.length+' product(s) from ' + selected.length + ' order(s)? ' + product.join(', '));

        if (reply) {
            const orders = selected.map(item => {
                return item.ordernumber
            })
            const body = {
                articles: product,
                orders: orders
            }

            console.log(body);

                const request = RestClient.prepareRequest('PUT', "orders/orderlines")
                request.setData(body)
                this.localstate.loadingCancel = true
                try{
                    const response = await request.send()
                    toast.success(response.data.message)
                    if(response.data.problematicOrderlines && response.data.problematicOrderlines.length > 0) {
                        console.log("These orderlines could not be cancelled:")
                        console.log(response.data.problematicOrderlines);
                    }
                } catch (e) {
                    toast.error("An error occurred. " + e)
                }
                this.localstate.loadingCancel = false

        }


    }



    handleCustgroupSelect(e){
        if (e !== null){
            let list = [];
            e.map(item => (
                list.push(item.value)
            ));
            this.localstate.customergroup = list;
        } else {
            this.localstate.customergroup = [];
        }
    }

    handleProductSelect(e){
        if (e !== null){
            let list = [];
            e.map(item => (
                list.push(item.value)
            ));
            this.localstate.product = list;
        } else {
            this.localstate.product = [];
        }
    }

    FormatArticlesStore(a) {
        let list = [];
        a.list.map(item =>(
            list.push({value: item.ordernumber, label: item.name})
        ))
        return(list)
    }


    setEndDate(date){
        const d = new Date(date);
        this.localstate.to = d.toISOString().split('T')[0]
    }

    setStartDate(date){
        const d = new Date(date);
        this.localstate.from = d.toISOString().split('T')[0]
    }

    handleSearch(p, c){
        if (c.length === 0 && p === null){
            toast.error("Bitte wähle mindestens eine Kundengruppe und ein Produkt aus.")
        } else if (c.length > 0 && p === null){
            toast.error("Bitte wähle ein Produkt aus.")
        } else if (c.length === 0 && p !== null){
            toast.error("Bitte wähle eine Kundengruppe.")
        } else{
            this.sendRequestToServer()
            this.localstate.framestyle = "";
        }
        this.localstate.selected = []
    }



    clickBox(item) {
        let selected = this.localstate.selected;
        if (selected.includes(item)){
            selected.splice(selected.findIndex(x => x.ordernumber === item.ordernumber), 1);
        } else {
            selected.push(item);
        }
        this.localstate.selected = selected;
    }

    openModal() {
        this.localstate.modal = true
    }

    closeModal() {
        this.localstate.modal = false
    }


    selectAll(){
        const r = this.localstate.results
        if (this.localstate.selected.length === r.length){
            this.localstate.selected = []
        } else {
            this.localstate.selected = r.slice()
        }
    }

    handleChangePage(event, newPage) {
        this.localstate.page = newPage
    }

    handleChangeRowsPerPage(event) {
        this.localstate.rowsPerPage = event.target.value;
        this.localstate.page = 0;
    };

    handleLabelDisplayedRows({from, to, count}) {
        return(
            <span>{from} - {to} von {count}</span>
        );
    };


    handleChangePageM(event, newPage) {
        this.localstate.pageM = newPage
    }

    handleChangeRowsPerPageM(event) {
        this.localstate.rowsPerPageM = event.target.value;
        this.localstate.pageM = 0;
    };

    handleLabelDisplayedRowsM({from, to, count}) {
        return(
            <span>{from} - {to} von {count}</span>
        );
    };

    confirmRecipients(){
        this.localstate.modal = false;
        this.localstate.modalMail = true;
    }

    closeModalMail() {
        this.localstate.modalMail = false;
    }

    changeSubject(e) {
        this.localstate.subject = e;
    }

    changeContent(e) {
        this.localstate.content = e;
    }

    handleClickOnlyNotSent(){
        if (this.localstate.onlyNotSent === 1){
            this.localstate.onlyNotSent = 0
        } else {
            this.localstate.onlyNotSent = 1
        }
    }

    makeOptions(k){
        if (SWParamsStore.status.fetched){
            if (k === "customergroup"){
                const groups = SWParamsStore.list.customerGroups
                if (groups){
                    const options = []
                    groups.map(item => (
                        options.push({value: item.groupkey, label: item.description})
                    ))
                    return options
                }
            }
        }
    }



    render() {
        const {results, loading, customergroup, from, to, product,
            selected, modal, page, rowsPerPage, pageM, rowsPerPageM,
            modalMail, content, subject
        } = this.localstate;
        const fromm = Date.parse(from);
        const too = Date.parse(to);

        return (<div>
            <NavigationBar title='Bestellungen mit Artikel'>
                <Button onClick={() => this.openModal()} disabled={this.localstate.selected.length === 0}>
                    <IconWithText icon={<FaPaperPlane/>} text= "Mails versenden"/>
                </Button>
                <Button
                    onClick={() => this.cancelArticlesInOrder()}
                    disabled={this.localstate.selected.length === 0}
                    loading={this.localstate.loadingCancel}
                ><IconWithText icon={<FaTrash/>} text = "Artikel aus Bestellung stornieren"/></Button>
            </NavigationBar>
            <Modal show={modal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                <Modal.Content style={styles.innerModal}>
                    <Container style={styles.innerinnerModal}>
                        <Heading>
                            Folgenden {selected.length} Personen Emails versenden?
                        </Heading>
                        <Container style={styles.modalTable}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ordernumber</TableCell>
                                        <TableCell>firstname</TableCell>
                                        <TableCell>lastname</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {selected.slice(pageM*rowsPerPageM, (pageM + 1)*rowsPerPageM).map(item => (
                                    <TableRow>
                                        <TableCell>{item.ordernumber}</TableCell>
                                        <TableCell>{item.firstname}</TableCell>
                                        <TableCell>{item.lastname}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'Alle anzeigen', value: selected.length }]}
                                colSpan={3}
                                component="div"
                                count={selected.length}
                                rowsPerPage={rowsPerPageM}
                                labelRowsPerPage="Zeilen pro Seite"
                                labelDisplayedRows={this.handleLabelDisplayedRowsM.bind(this)}
                                page={pageM}
                                onChangePage={this.handleChangePageM.bind(this)}
                                onChangeRowsPerPage={this.handleChangeRowsPerPageM.bind(this)}
                            />
                        </Container>
                        <Button style={styles.button} onClick={() => this.confirmRecipients()}>OK (weiter zu Nachricht)</Button>
                    </Container>
                </Modal.Content>
            </Modal>
            <Modal show={modalMail} onClose={this.closeModalMail.bind(this)} closeOnBlur={true}>
                <Modal.Content style={styles.innerModal}>
                    <Container style={styles.innerinnerModal}>
                        <Field>
                            <Control>
                                <Label>Betreff</Label>
                                <Input type="text" value={subject} placeholder='Betreff' onChange={(e) => this.changeSubject(e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Label>Mail</Label>
                                <Textarea type="text" value={content} placeholder='Mail' onChange={(e) => this.changeContent(e.target.value)}/>
                            </Control>
                        </Field>
                        <Button style={styles.button} onClick={() => this.sendMails()}> Mails versenden </Button>
                    </Container>
                </Modal.Content>
            </Modal>
            <Container style={{padding: 20}}>
                <FetchView store={ArticlesStoreSW}>
                    <FetchView store={SWParamsStore}>
                        <Container style={styles.searchpars}>
                            <Columns style={{flex:1}}>
                                <Columns.Column size={3}>
                                    <div>Kundengruppe</div>
                                    <Select
                                        isMulti
                                        className = "basic-multi-select"
                                        options = {this.makeOptions("customergroup")}
                                        onChange = {e => this.handleCustgroupSelect(e)}
                                        defaultvalue = {customergroup}
                                        placeholder = "Kundengruppe"
                                    />
                                </Columns.Column>

                                <Columns.Column size={2}>
                                    <div>Von</div>

                                    <DatePicker
                                        selected={fromm}
                                        onChange={date => this.setStartDate(date)}
                                        selectsStart
                                        startDate={fromm}
                                        endDate={too}
                                        dateFormat="dd.MM.yyy"
                                    />
                                </Columns.Column>
                                < Columns.Column size={2}>
                                    <div>Bis</div>

                                    <DatePicker
                                        selected={too}
                                        onChange={date => this.setEndDate(date)}
                                        selectsEnd
                                        startDate={fromm}
                                        endDate={too}
                                        minDate={fromm}
                                        dateFormat="dd.MM.yyy"
                                        style={{ border: "solid 1px pink" }}
                                    />
                                </Columns.Column>
                                < Columns.Column size={3}>
                                    <div>Produkt</div>

                                    <Select
                                        isMulti
                                        className="basic-multi-select"
                                        options={this.FormatArticlesStore(ArticlesStoreSW)}
                                        placeholder="Produkt"
                                        onChange = {e => this.handleProductSelect(e)}
                                    />
                                </Columns.Column>
                                < Columns.Column size={2}>
                                    <div>Suche</div>

                                    <Button
                                        color='primary'
                                        fullwidth onClick={() => this.handleSearch(product, customergroup)} style={styles.button} loading={loading}>
                                        <IconWithText icon={<FaSearch/>} text="Suche"/>
                                    </Button>
                                </Columns.Column>
                            </Columns>
                        </Container>
                        <Container style={styles.searchparsCheck}>
                            <Checkbox onClick={() => this.handleClickOnlyNotSent()}/>
                            <div style={{paddingTop:"10px"}}>Nur Bestellungen, die noch nicht verschickt sind?</div>
                        </Container>
                    </FetchView>
                </FetchView>
                <Container style={{display: this.localstate.framestyle, overflowX: 'scroll'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox onClick={() => this.selectAll(results)} checked={selected.length === results.length}/>
                                    ({selected.length})
                                </TableCell>
                                <TableCell>Bestellnummer</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Bestellte Anzahl</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {!loading && results.slice(page*rowsPerPage, (page + 1)*rowsPerPage).map(item => (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox onClick={() => this.clickBox(item)} checked={selected.includes(item)} />
                                    </TableCell>
                                    <TableCell> {item.ordernumber} </TableCell>
                                    <TableCell> {item.firstname} </TableCell>
                                    <TableCell> {item.lastname} </TableCell>
                                    <TableCell> {item.email} </TableCell>
                                    <TableCell> {item.quantity}</TableCell>
                                </TableRow>
                            )
                        )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, { label: 'Alle anzeigen', value: results.length }]}
                        colSpan={5}
                        component="div"
                        count={results.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage="Zeilen pro Seite"
                        labelDisplayedRows={this.handleLabelDisplayedRows.bind(this)}
                        page={page}
                        onChangePage={this.handleChangePage.bind(this)}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                    />
                </Container>
            </Container>
        </div>);
    }
}

const styles = {
    innerModal: {
        backgroundColor: 'white',
    },
    innerinnerModal: {
        padding: "10pt"
    },
    modalTable:{
        paddingBottom:"10pt"
    },
    searchpars:{
        display: "flex"
    },
    searchparsCheck:{
        display: "flex",

    },
    select: {
        width: "35%",
        padding: "15pt",
        paddingBottom: "5pt",
        margin: "auto",
        borderColor: "black"
    },
    datepicks:{
        width: "min-content",
        padding:"15pt",
        display: "flex",
        borderColor: "green"
    },
    button:{
        marginRight: "15pt",
        marginLeft: "auto",
        marginTop: "0pt",
        display: "block"
    },
    searchcontainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '10px'
    }
}

export default OrdersScreen;