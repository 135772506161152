import React, { Component } from "react";
import { Button, Container, Modal } from "react-bulma-components";
import {
  Input,
  InputFile,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Table, TableCell, TableRow } from "@material-ui/core";
import RestClient from "../../../../shared/Network/RestClient";
import { toast } from "react-toastify";
import NavigationBar from "../../NavigationBar/NavigationBar";
import Header from "../../Header/Header";
import FetchView from "../../../dumb/FetchView/FetchView";
import moment from "moment";
import {MarketEmployeeStore} from "../../../../stores/Markets/MarketEmployeeStore";
import Select from "react-select";
import BackButton from "../../../dumb/BackButton";

@observer
export default class StatementInput extends Component {
  @observable localstate = {
    status: {
      saving: false
    },
    datum: moment().format('YYYY-MM-DD'),
    marketID: null,
    marketName: null,
    form: {
      cashBeginn: null,
      cashEnd: null,
      turnoverCash: null,
      turnoverCard: null,
      turnoverTotal: null,
      cashProfit: null,
      difference: undefined,
      travelCostsTo: 0,
      amountDeposited: 0,
      marketCosts: 0,
      travelCostsBack: 0,
      expense: 0,
      amountDiscount: 0,
      note: null,
      urlReceiptTravelCostsTo: undefined,
      urlReceiptTravelCostsBack: undefined,
      urlReceiptExpense: undefined,
      urlReceiptDeposit: undefined,
      urlReceiptMarketCosts: undefined
    },
    receipts: {
      receiptTravelCostsTo: null,
      receiptTravelCostsBack: null,
      receiptExpenses: null,
      receiptDeposit: null,
      receiptMarketCosts: null
    },
    availableEmployees: [],
    employees: []
  };

  constructor(props) {
    super(props);
    this.localstate.datum = new Date(props.match.params.date);
    this.localstate.marketID = props.match.params.id;
  }


  onChange(key, value) {
    this.localstate.form[key] = value

    const {form} = this.localstate;

    if (form.cashBeginn!=null && form.cashEnd!=null && form.travelCostsTo!=null && form.travelCostsBack!=null && form.expense!=null && form.amountDiscount!=null && form.cashEnd!=null) {
      form.difference = parseFloat(
          parseFloat(form.cashBeginn) + parseFloat(form.turnoverCash) -
          form.travelCostsTo -
          form.travelCostsBack -
          form.expense - form.amountDiscount - parseFloat(form.cashEnd) - form.marketCosts
      ).toFixed(2)
    }
  }

  addReceipt(key, value) {
    this.localstate.receipts[key] = value;
  }

  async loadSellerID() {
    const {datum, marketID} = this.localstate;
    const request = RestClient.prepareRequest(
      "GET",
      "markets/"+ marketID +"/sales/" + datum
    );

    await MarketEmployeeStore.fetchData();
    this.localstate.availableEmployees = MarketEmployeeStore.list.map(x => {
      return {
        value: x.id,
        label: x.name
      }
    });

    const result = await RestClient.sendRequest(request);
    if (result.data.seller) {
      this.localstate.marketName =  result.data.seller.name;
    }
    if (!result.data.data || result.data.data.length === 0) {
      throw new Error("Für dieses Datum gibt es keine Umsätze. Bitte wähle ein anderes Datum!");
    } else {
      this.calcTurnover(result.data.data);
    }
  }


  calcTurnover(data) {
    let dailyCashTurnover = 0;
    let dailyCardTurnover = 0;
    data.forEach((payment) => {
      if (payment.paymentType === "CASH") {
        dailyCashTurnover += payment.total*1.07;
      } else if (payment.paymentType === "POS") {
        dailyCardTurnover += payment.total*1.07;
      }
    });

    dailyCashTurnover = parseFloat(dailyCashTurnover.toFixed(2));
    dailyCardTurnover = parseFloat(dailyCardTurnover.toFixed(2));

    this.localstate.form.turnoverCash = dailyCashTurnover;
    this.localstate.form.turnoverCard = dailyCardTurnover;
    this.localstate.form.turnoverTotal = (dailyCashTurnover + dailyCardTurnover).toFixed(2);
  }

  async pushDataToBackend() {
    if (!confirm('Bist du sicher, dass alles stimmt? Du kannst deine Eingaben später nicht mehr ändern...')){
      return false;
    }

    this.localstate.form.cashProfit = parseFloat(
      this.localstate.form.cashEnd -
      this.localstate.form.travelCostsTo -
      this.localstate.form.travelCostsBack -
      this.localstate.form.expense).toFixed(2);
      if(this.localstate.form.note === null){
        this.localstate.form.note = 'Keine Notiz'
      }

    const isNull = Object.keys(this.localstate.form).map((key) => {
      if (this.localstate.form[key] !== null) {
        return this.localstate.form[key];
      } else {
        return null;
      }
    });
    if (!isNull.includes(null)) {
      try {
        this.localstate.status.saving = true;

        await this.postReceiptsToBackend();
          await this.postStatementToBackend();
          this.localstate.status.saving=false;
          toast.success('Danke dir! Das Market Statement wurde erfolgreich übertragen!')
          this.props.history.push('/public/markets/statements');
      } catch (error) {
        toast.error('Es ist ein Fehler aufgetreten.\n' + error)
        this.localstate.status.saving=false;

      }
    } else {
      toast.error("Bitte fülle alle Daten aus.");
    }
  }

  async postStatementToBackend() {
    const {marketID, datum, form} = this.localstate;

    const request = RestClient.prepareRequest("POST", "markets/"+marketID+"/statements/" + datum);

    request.setData(form);
    try {
      const response = await request.send();
      return true;
    } catch (error) {
      toast.error('Es ist ein Fehler aufgetreten.\n' + error)
    }
  }

  async postEmployeeToBackend(employeeName, workingTimeEmployee, marketDate) {
    Object.keys(employeeName).map(async (key) => {
      const name = employeeName[key];
      const time = workingTimeEmployee[key];

      const request = RestClient.prepareRequest("POST", "markets/employees");
      request.setData({
        name,
        time,
        marketDate,
      });

      try {
        await request.send();
      } catch (error) {
        toast.error('Es ist ein Fehler aufgetreten.\n' + error)
      }
    });
  }

  async postReceiptsToBackend() {
   const receiptList = ['receiptTravelCostsTo', 'receiptTravelCostsBack', 'receiptExpenses', 'receiptDeposit', 'receiptMarketCosts'];

  for (const key of receiptList) {
      if (!this.localstate.receipts[key]) {
        continue;
      }

      const formData = new FormData();
      formData.append("image", this.localstate.receipts[key]);

      const request = RestClient.prepareRequest(
          "POST",
          "markets/receipts",
          "multipart/form-data"
      );
      request.setData(formData);
      try {
        const response = await request.send();
        const s3Url = response.data
        if (key === "receiptTravelCostsTo") {
          this.localstate.form.urlReceiptTravelCostsTo = s3Url;
        }
        if (key === "receiptTravelCostsBack") {
          this.localstate.form.urlReceiptTravelCostsBack = s3Url;
        }
        if (key === "receiptExpenses") {
          this.localstate.form.urlReceiptExpense = s3Url;
        }
        if (key === "receiptDeposit") {
          this.localstate.form.urlReceiptDeposit = s3Url;
        }
        if (key === "receiptMarketCosts") {
          this.localstate.form.urlReceiptMarketCosts = s3Url;
        }


      } catch (error) {
        throw new Error('Es ist ein Fehler aufgetreten.\n' + error)
      }
    }
    return true;
  }

  setDate(datum) {
    this.localstate.datum = datum;
  }

  handleEmployeeSelect(e) {
      if (e !== null){
        let list = [];
        e.map(item => (
            list.push(item.value)
        ));
        this.localstate.employees = list;
      } else {
        this.localstate.employees = [];
      }
  }

  render() {


      const {datum,marketName, availableEmployees, form, status} = this.localstate;
    let summeNotEingezahlt;
    let hinweisDeposit = null;
    if (form.cashEnd && form.amountDeposited) {
      summeNotEingezahlt = form.cashEnd - form.amountDeposited;
      if (summeNotEingezahlt>150) {
        hinweisDeposit = "Du hast noch mehr als 150€ Bargeld, bitte zahle das restliche Geld bis auf 20-30€ ein. "
      }
    }

    let differenceText = "";
      if (form.difference>0) {
        differenceText = "Es fehlen " + form.difference +  "€ rechnerisch in der Kasse. Zähle bitte nochmal nach und prüfe deine Eingaben :) "
      }

      return (
          <div>
            <Header></Header>
            <NavigationBar title={marketName ? "Marktabschluss für " + marketName : "Marktabschluss"}></NavigationBar>
          <Container style={styles.modal}>
            <BackButton text="Zurück zur Übersicht" to="/public/markets/statements" />
            <div style={styles.fieldWrapper}>
                <FetchView call={this.loadSellerID.bind(this)} refresh={datum}>
                  <Table>
                    <TableRow>
                      <TableCell>
                        <Label>Datum</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{moment(datum).format('DD.MM.Y')}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Bar</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverCash} €`}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Karte</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverCard} €`}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Gesamt</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverTotal} €`}</Label>
                      </TableCell>
                    </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zu Beginn</Label>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      style={styles.numberInput}
                      onChange={(e) =>
                        this.onChange("cashBeginn", e.target.value)
                      }
                      value={form.cashBeginn}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zum Ende</Label>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      style={styles.numberInput}
                      onChange={(e) => this.onChange("cashEnd", e.target.value)}
                      value={form.cashEnd}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Hin</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                    <Input
                      type="number"
                      style={styles.numberInput}
                      onChange={(e) =>
                        this.onChange("travelCostsTo", e.target.value)
                      }
                      value={form.travelCostsTo}
                    />

                    <InputFile
                      style={styles.inputFileGap}
                      label="Beleg hochladen"
                      boxed={true}
                      onChange={(e) =>
                        this.addReceipt("receiptTravelCostsTo", e.target.files[0])
                      }
                    /></div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Zurück</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                    <Input
                      type="number"
                      style={styles.numberInput}
                      onChange={(e) =>
                        this.onChange("travelCostsBack", e.target.value)
                      }
                      value={form.travelCostsBack}
                    />
                    <InputFile
                      style={styles.inputFileGap}
                      boxed={true}
                      label="Beleg hochladen"
                      onChange={(e) =>
                        this.addReceipt("receiptTravelCostsBack", e.target.files[0])
                      }
                    /></div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Spesen</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                      <Input
                        style={styles.numberInput}
                        type="number"
                        onChange={(e) => this.onChange("expense", e.target.value)}
                        value={form.expense}
                      />
                      <InputFile
                          label="Beleg hochladen"
                          style={styles.inputFileGap}
                          boxed={true}
                          onChange={(e) =>
                              this.addReceipt("receiptExpenses", e.target.files[0])
                          }
                      />
                    </div>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Kosten für den Marktstand</Label>
                      </TableCell>
                      <TableCell>
                        <div style={styles.fileContainer}>
                          <Input
                              type="number"
                              style={styles.numberInput}
                              onChange={(e) =>
                                  this.onChange("marketCosts", e.target.value)
                              }
                              value={form.marketCosts}
                          />
                          <InputFile
                              style={styles.inputFileGap}
                              boxed={true}
                              label="Beleg hochladen"
                              onChange={(e) =>
                                  this.addReceipt("receiptMarketCosts", e.target.files[0])
                              }
                          /></div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Summe gew. Rabatte</Label>
                      </TableCell>
                      <TableCell>
                        <Input
                            type="number"
                            style={styles.numberInput}
                            onChange={(e) =>
                                this.onChange("amountDiscount", e.target.value)
                            }
                            default={0}
                            value={form.amountDiscount}
                        />
                      </TableCell>
                    </TableRow>
                    {differenceText && <TableRow>
                  <TableCell>
                    <Label>Differenz</Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      {differenceText ? <div style={styles.difference}>{differenceText}</div> :  ''}
                    </Label>
                  </TableCell>
                </TableRow>}
                <TableRow>
                  <TableCell>
                    <Label>Einzahlungsbeleg (z.B. Sparkasse)</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                    <Input
                        type="number"
                        style={styles.numberInput}
                        onChange={(e) =>
                            this.onChange("amountDeposited", e.target.value)
                        }
                        placeholder="Summe eingezahlt"
                        default={0}
                        value={form.amountDeposited}
                    />
                    <InputFile
                        boxed={true}
                        style={styles.inputFileGap}
                      label="Beleg hochladen"
                      onChange={(e) =>
                        this.addReceipt("receiptDeposit", e.target.files[0])
                      }
                    />
                    </div>
                  </TableCell>
                </TableRow>
                  {hinweisDeposit && <TableRow>
                    <TableCell>
                      <Label>Hinweis</Label>
                    </TableCell>
                    <TableCell>
                      <Label>
                        {hinweisDeposit ? <div style={styles.difference}>{hinweisDeposit}</div> :  ''}
                      </Label>
                    </TableCell>
                  </TableRow>}

                    { /* <TableRow>
                      <TableCell>
                        <Label>MitarbeiterInnen</Label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select
                            isMulti
                            className = "basic-multi-select"
                            options = {availableEmployees }
                            onChange = {e => this.handleEmployeeSelect(e)}
                            placeholder = "MitarbeiterInnen auswählen"
                        />
                      </TableCell>
                    </TableRow> */ }
                <TableRow>
                  <TableCell>
                    <Label>Anmerkung</Label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Textarea
                      type="text"
                      style={styles.inputFieldGap}
                      onChange={(e) => this.onChange("note", e.target.value)}
                      value={form.note}
                    />
                  </TableCell>
                </TableRow>

                  </Table>
                  <Button
                      color="primary"
                      fullwidth={true}
                      onClick={() => this.pushDataToBackend()}
                      disabled={status.saving}
                      loading={status.saving}
                  >
                    Speichern
                  </Button>
                </FetchView>

            </div>
  

          </Container>
          </div>
      );
  }
}

const styles = {
  modal: {
    paddingBottom: 40
  },
  difference: {
    color: 'orange',
    maxWidth: 300
  },
  fileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems:'center'
  },
  numberInput: {
    width: "160px",
    marginRight: 10,
    marginBottom: 10
  },
  fieldWrapper: {
    marginBlock: "2.5em",
  },
  inputFileGap: {
    maxWidth: 160
  },

  logo: {
    width: "5em",
  },
  marketName: {
    fontSize: "2rem",
    textTransform: "uppercase",
    marginLeft: "auto",
  },
};
