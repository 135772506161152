const csvFields = [
    {label: "First Name", name: "firstName", isRequired: true},
    {label: "Last Name", name: "lastName", isRequired: true},
    {label: "Street Name and Nr.", name: "street", isRequired: true},
    {label: "Zip Code", name: "zipCode", isRequired: true},
    {label: "City", name: "city", isRequired: true},
    {label: "Country", name: "country", isRequired: false}, // default the country chosen in generalFields
    {label: "Article Number", name: "articleNumber", isRequired: true},
    {label: "Price", name: "price", isRequired: true},
    {label: "Quantity", name: "quantity", isRequired: true},
    {label: "Company", name: "company", isRequired: false},
    {label: "Dispatch Method ID", name: "dispatchID", isRequired: false},
    {label: "Reference Nr", name: "referenceNr", isRequired: false}
];

const generalFields = [
    {label: "Country", name: "country", valueColumnName: "id", parameterName: "countries", labelColumnNames: ["countryname"], isOrderField: false },
    {label: "Payment", name: "paymentId", valueColumnName: "id", parameterName: "paymentMethods", labelColumnNames: ["description"], isOrderField: true },
    {label: "Dispatch Method", name: "dispatchId", valueColumnName: "id", parameterName: "dispatchMethods", labelColumnNames: ["name", "comment"], isOrderField: true },
    {label: "Shop Name", name: "shopId", valueColumnName: "id", parameterName: "shops", labelColumnNames: ["name"], isOrderField: true },
    {label: "Currency", name: "currency", valueColumnName: "currency", parameterName: "currencies", labelColumnNames: ["currency"], isOrderField: true },
    {label: "Payment Status", name: "paymentStatusId", valueColumnName: "id", parameterName: "paymentStates", labelColumnNames: ["description"], isOrderField: true },
    {label: "Order Status", name: "orderStatusId", valueColumnName: "id", parameterName: "orderStates", labelColumnNames: ["description"], isOrderField: true }
];

export { generalFields, csvFields };