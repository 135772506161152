import RestClient from "./Network/RestClient";

class Translator {
    language = null;

    constructor(language) {
        this.language = language;
    }


    async translateText(strings) {
        const request = RestClient.prepareRequest('POST', 'translations/languages/'+this.language+'/translate');
        request.setData(strings);
        return await request.send();
    }

    async translateObject(obj) {
        let stringsToTranslate = [];
        Object.keys(obj).forEach(key => {
            const text = obj[key];
            if (text && isNaN(text)) {
                stringsToTranslate.push(article[key]);
            }
        });
        return this.translateText(stringsToTranslate);
    }
}

export default Translator;