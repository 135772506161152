import React, {Component} from 'react';
import {observer} from "mobx-react";
import {observable} from 'mobx';
import RestClient from "../../../shared/Network/RestClient";
import {toast} from "react-toastify";
import NavigationBar from "../NavigationBar/NavigationBar";
import {Button, Columns, Container} from "react-bulma-components/dist";
import Select from "react-select";
import {Checkbox, Slider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@material-ui/core";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from "moment";
import {AmiriFontDatastring, KoRoLogoImgURL} from "./PricelistParams";
import {IconWithText} from "../../dumb";
import {FaFileCsv, FaFilePdf} from "react-icons/all";
import {Input} from 'react-bulma-components/lib/components/form';
import {CsvBuilder} from 'filefy';
import FetchView from "../../dumb/FetchView/FetchView";
import {SWParamsStore} from "../../../stores/SWParamsStore";

@observer
class Pricelist extends Component {
    @observable localstate = {
        language: 1,
        customergroup: "H",
        uvpref: "EK",
        currency: "€",
        loading: false,
        products: [],
        considered: [],
        heading: "KoRo Preisliste",
        datePrescr: "Stand: ",
        disclaimer: "Alle Preise sind unverbindlich, Änderungen liegen allein im Ermessen von KoRo.",
        artikelnummerTr: "Artikelnummer",
        nameTr: "Titel",
        preisTr: "Netto Preis",
        uvpTr: "UVP",
        pageTr: "Seite: ",
        available: "verfügbar",
        selected: [],
        page: 0,
        rowsPerPage: 10,
        searchWord: '',
        framestyle: "none",
        margin: 0.2,
        marginKoRo: 0.2,

        categoryID: [],
    };


    sanitizeuvpref(){
        if (this.localstate.uvpref === "XXX") {
            return "EK"
        } else {
            return this.localstate.uvpref
        }
    }

    sanitizeCust(c){
        if (c === "MAR"){
            return "RT"
        } else {
            return c
        }
    }

    async sendRequestToServer(language, customergroupDirty, categoryID){
        const uvpref = this.sanitizeuvpref()
        const customergroup = this.sanitizeCust(customergroupDirty)
        let params = ''
        if(!categoryID){
            params = customergroup + "?" + "language=" + language + "&uvpref=" + uvpref
        } else {
            params = customergroup + "?" + "language=" + language + "&uvpref=" + uvpref + "&categorieid=" + categoryID
        }
        const request = RestClient.prepareRequest('GET', 'articles/active/pricelist/' + params)
        this.localstate.loading = true;
        try {
            const response = await RestClient.sendRequest(request);
            const {margin, marginKoRo} = this.localstate
            if (customergroupDirty === "MAR"){
                const products = response.data
                products.forEach(function (item, index) {
                    const pr = (1 - margin) * item.uvp / item.tax // the value for tax from database is 1+tax
                    const lowerpricelimit = item.purchaseprice/(1-marginKoRo)
                    products[index].price = Math.max(pr, lowerpricelimit)
                })
                this.localstate.products = products
                this.localstate.considered = products
            } else {
                this.localstate.products = response.data;
                this.localstate.considered = response.data;
            }
            this.localstate.loading = false;
        } catch (e) {
            this.localstate.loading = false;
            toast.error("An error occurred." + e)
        }
    };


    handleCustgroupSelect(e){
        this.localstate.customergroup = e.value
    }

    handleLangSelect(e){
        const l = e.value
        this.localstate.language = l

        const pricelistParams = {
          1: {
            heading: 'KoRo Preisliste',
            datePrescr: 'Stand: ',
            disclaimer:
              'Alle Preise sind unverbindlich. \nÄnderungen liegen allein im Ermessen von KoRo.',
            artikelnummerTr: 'Artikelnummer',
            nameTr: 'Titel',
            preisTr: 'Preis',
            uvpTr: 'UVP',
            pageTr: 'Seite: ',
            available: 'Verfügbar',
          },
          2: {
            heading: 'KoRo Pricelist',
            datePrescr: 'As constituted on: ',
            disclaimer:
              'All prices are not binding. \nAll changes are at the discretion of KoRo.',
            artikelnummerTr: 'Item number',
            nameTr: 'Title',
            preisTr: 'Price',
            uvpTr: 'RRP',
            pageTr: 'Page: ',
            available: 'Available',
          },
          3: {
            heading: 'KoRo Catalogue professionnel',
            datePrescr: 'Stock: ',
            disclaimer:
              'Tous les prix sont sans engagement. \nLes modifications sont à la seule discrétion de KoRo.',
            artikelnummerTr: 'Reference',
            nameTr: 'Nom produit',
            preisTr: 'Prix',
            uvpTr: 'Prix de vente conseillé',
            pageTr: 'Page: ',
            available: 'disponible',
          },
          4: {
            heading: 'KoRo prijslijst',
            datePrescr: 'Zoals samengesteld op: ',
            disclaimer:
              'Alle prijzen zijn niet bindend. \nAlle wijzigingen zijn ter beoordeling van KoRo.',
            artikelnummerTr: 'Artikelnummer',
            nameTr: 'Titel',
            preisTr: 'Prijs',
            uvpTr: 'MER',
            pageTr: 'Pagina: ',
            available: 'beschikbaar',
          },
          7: {
            heading: 'KoRo hinnasto',
            datePrescr: 'Tila: ',
            disclaimer:
              'Hinnat eivät ole sitovia. \nKoRo:lla on oikeus tehdä muutoksia.',
            artikelnummerTr: 'Tuotenumero',
            nameTr: 'Otsikko',
            preisTr: 'Hinta',
            uvpTr: 'YVA',
            pageTr: 'Sivu: ',
            available: 'saatavilla',
          },
          9: {
            heading: 'Listino KoRo per rivenditori',
            datePrescr: 'Aggiornato: ',
            disclaimer:
              'Tutti i prezzi sono non vincolanti. \nLe modifiche sono a sola discrezione di KoRo.',
            artikelnummerTr: 'Nr. articolo',
            nameTr: 'Titolo',
            preisTr: 'Prezzo',
            uvpTr: 'Prezzo di vendita consigliato',
            pageTr: 'Pagina: ',
            available: 'disponibile',
          },
          11: {
            heading: 'Lista de precios de KoRo',
            datePrescr: 'Estado: ',
            disclaimer:
              'Todos los precios están sujetos a cambios sin previo aviso. \nLos cambios son a discreción de KoRo.',
            artikelnummerTr: 'Número de artículo',
            nameTr: 'Título',
            preisTr: 'Precio',
            uvpTr: 'PVPR',
            pageTr: 'Página: ',
            available: 'Disponible',
          },
          14: {
            heading: 'KoRo Prisliste',
            datePrescr: 'Opdateret den: ',
            disclaimer:
              "Alle priser er ikke bindende. \nAlle ændringer er efter KoRo's skøn.",
            artikelnummerTr: 'Varenummer',
            nameTr: 'Titel',
            preisTr: 'Pris',
            uvpTr: 'RRP',
            pageTr: 'Side: ',
            available: 'Tilgængelig',
          },
          15: {
            heading: 'Lista de Preços da KoRo',
            datePrescr: 'Como constituído em: ',
            disclaimer:
              'Todos os preços não são vinculados. \nAll alterações ficam à descrição da KoRo.',
            artikelnummerTr: 'Número do item',
            nameTr: 'Título',
            preisTr: 'Preço',
            uvpTr: 'PRVP',
            pageTr: 'Página: ',
            available: 'Disponível',
          },
          16: {
            heading: 'Cennik KoRo',
            datePrescr: 'Stan na dzień: ',
            disclaimer:
              'Ceny nie są wiążące. \nWszystkie zmiany zależą od uznania KoRo.',
            artikelnummerTr: 'Numer artykułu',
            nameTr: 'Nazwa',
            preisTr: 'Cena',
            uvpTr: 'RRP',
            pageTr: 'Strona: ',
            available: 'Dostępność',
          },
        };

        this.localstate.heading = pricelistParams[l].heading
        this.localstate.datePrescr = pricelistParams[l].datePrescr
        this.localstate.disclaimer = pricelistParams[l].disclaimer
        this.localstate.artikelnummerTr = pricelistParams[l].artikelnummerTr
        this.localstate.nameTr = pricelistParams[l].nameTr
        this.localstate.preisTr = pricelistParams[l].preisTr
        this.localstate.uvpTr = pricelistParams[l].uvpTr
        this.localstate.pageTr = pricelistParams[l].pageTr
        this.localstate.available = pricelistParams[l].available

        const langUVPmapping = {
            1: 'EK',
            2: 'UKK',
            3: 'FR',
            4: 'NLK',
            7: 'FIK',
            9: 'ITK',
            11: 'ESP',
            14: 'DKK',
            15: 'PTK',
            16: 'PLK',
        }

        this.localstate.uvpref = langUVPmapping[l]
    }

    handleUVPRefSelect(e){
        this.localstate.uvpref = e.value;
    }

    GetData(){
        if(this.localstate.categoryID.length === 0 || this.localstate.categoryID.includes(0)){
            this.sendRequestToServer(this.localstate.language, this.localstate.customergroup)
        } else {
            this.sendRequestToServer(this.localstate.language, this.localstate.customergroup, this.localstate.categoryID)
        }
        this.localstate.page=0;
        this.localstate.selected=[];
        this.localstate.searchWord="";
        this.localstate.framestyle = "";
        if (this.localstate.uvpref === "UKK"){
            this.localstate.currency = "£";
        } else if (this.localstate.uvpref === "CHK"){
            this.localstate.currency = "CHF";
        } else if (this.localstate.uvpref === "PLK"){
                this.localstate.currency = "zł"
        } else if (this.localstate.uvpref === "DKK"){
            this.localstate.currency = "kr"
        } else {
            this.localstate.currency = "€";
        }
    };

    dynamicSort(property) {
        let sortOrder = 1;

        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }
        }
    }

    exportPDF() {
        const {heading, datePrescr, disclaimer, artikelnummerTr, nameTr, preisTr, uvpTr, pageTr, available} = this.localstate
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size, { filters: ["ASCIIHexEncode"] });

        const title = heading;
        let columns = [
            {title: artikelnummerTr, dataKey: "articleno"},
            {title: nameTr, dataKey: "name"},
            {title: "EAN", dataKey: "ean"},
            {title: preisTr, dataKey: "price"},
            ]
        let data;
        if (this.localstate.uvpref === "XXX"){
            data = this.localstate.selected.map(elt =>{
                const pr = elt.price.toFixed(2) + this.localstate.currency
                let sign = "-"
                if(elt.stock > 30){
                    sign = "+"
                }
                return [elt.ordernumber, elt.name, elt.ean, pr, sign]
            });
        } else {
            columns.push({title: uvpTr, dataKey: "uvp"})
            data = this.localstate.selected.map(elt =>{
                const pr = this.localstate.currency + " " + elt.price.toFixed(2)
                const uvp = this.localstate.currency + " " + elt.uvp.toFixed(2)
                let sign = "-"
                if(elt.stock > 30){
                    sign = "+"
                }
                return [elt.ordernumber, elt.name, elt.ean, pr, uvp, sign]
            });
        }
        columns.push({title: available, dataKey: "instock"})

        const imgData = KoRoLogoImgURL;

        doc.addFileToVFS("Amiri-Regular.ttf", AmiriFontDatastring);
        doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
        doc.setFont("Amiri"); // set font



        doc.setFontSize(24);
        doc.addImage(imgData, 'PNG', 470, 20, 90, 80)
        doc.text(title, marginLeft, 40);

        doc.setFontSize(12);
        doc.text(datePrescr + moment().format('DD.MM.YYYY'), marginLeft, 80)
        doc.text(disclaimer, marginLeft, 95)

        doc.autoTable(
            columns, data, {
                styles: {font: "Amiri", overflow: "linebreak"},
                startY:120,
                columnStyles: {
                    price: {cellWidth: 60},
                    uvp: {cellWidth: 60}
                }
            }
        )
        const pageCount = doc.internal.getNumberOfPages(); //Total Page Number
        for(let i = 0; i < pageCount; i++) {
            doc.setPage(i);
            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
            doc.setFontSize(10);
            doc.text(pageTr + pageCurrent + '/' + pageCount, 500, 810);
        }
        doc.save("KoRo_pricelist.pdf")
    };

    exportCSV() {
        const currdate = moment().format('DD.MM.YYYY')
        const {heading, datePrescr, disclaimer, artikelnummerTr, nameTr, preisTr, uvpTr, currency} = this.localstate
        const data = this.localstate.selected.map(elt =>
            [
                elt.ordernumber,
                elt.name,
                elt.ean.toString(),
                elt.price.toFixed(2),
                elt.uvp.toFixed(2),
                this.calculateMargin(elt.uvp, elt.tax, elt.price),
                this.calculateMargin(elt.price, 1, elt.purchaseprice)
            ]
        );
        const cols = [artikelnummerTr, nameTr, "EAN", preisTr + `[${currency}]`, uvpTr + " [" + currency + "]", "Margin Retailer [%]", "Margin KoRo[%]"]
        const builder = new CsvBuilder('KoRo_pricelist.csv')
        builder.setDelimeter(';')
        builder.addRow([heading])
        builder.addRow([datePrescr, currdate])
        builder.addRow([disclaimer])
        builder.addRow(cols)
        builder.addRows(data)
        builder.exportFile()
    }

    selectAll(){
        const r = this.localstate.considered
        if (this.localstate.selected.length === r.length){
            this.localstate.selected = []
        } else {
            this.localstate.selected = r.slice()
        }
    };

    clickBox(item) {
        let selected = this.localstate.selected;
        if (selected.includes(item)){
            selected.splice(selected.findIndex(x => x.ordernumber === item.ordernumber), 1);
        } else {
            selected.push(item);
        }
        this.localstate.selected = selected;
    };

    handleChangePage(event, newPage) {
        this.localstate.page = newPage
    }

    handleChangeRowsPerPage(event) {
        this.localstate.rowsPerPage = event.target.value;
        this.localstate.page = 0;
    };

    handleLabelDisplayedRows({from, to, count}) {
        return(
            <span>{from} - {to} out of {count}</span>
        );
    };

    changeMargin(e){
        this.localstate.margin = e/100
    }

    changeMarginKoRo(e){
        this.localstate.marginKoRo = e/100
    }

    changeSearchWord(e){
        let product = []
        this.localstate.considered = []
        this.localstate.searchWord = e;
        const eLC = e.toLowerCase().split(',');
        for(let searchItem of eLC){
            product.push(this.localstate.products.filter(item => item.name.toLowerCase().includes(searchItem) || item.ordernumber.toLowerCase().includes(searchItem)));
            this.localstate.page = 0;
        }
        for(let item of product) {
            this.localstate.considered.push(...item)

        }
    }

    hideTbl(c){
        if( c.length > 0){
            return styles.hiddentbl
        }else {
            return styles.nothing
        }
    }

    showUVP(e){
        if(this.localstate.uvpref === "XXX"){
            return "---"
        } else {
            return e
        }
    }

    defineCellColor(isMargin){
        if(this.localstate.customergroup ==="MAR" && this.localstate.margin*100 > isMargin){
            return styles.bad
        } else {
            return {}
        }
    }

    calculateMargin(uvp, tax, price){
        return (((uvp/tax) - price) * 100 / (uvp/tax)).toFixed(2)
    }

    showSlider() {
        if (this.localstate.customergroup === "MAR"){
            return styles.slidersON
        }
        else{
            return styles.slidersOFF
        }
    }

    makeOptions(key) {
        if (SWParamsStore.status.fetched){
            const par = SWParamsStore.list[key]
            if (par){
                const options = []
                if (key === "customerGroups"){
                    par.map(item => (
                        options.push({value: item.groupkey, label:item.description})
                    ))
                }
                options.push({value: "MAR", label: "Prices based on Margin"})
                return options;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    makeUVPcustgroups(custgroups){
        const options = []
        if (custgroups.length > 0){
            for (const cust of ["EK", "ATK", "CHK", "FR", "NLK", "FIK", "ITK", "UKK", "ESP", "PTK", "PLK", "DKK", "ORT"]){
                const custDB = custgroups.find(item => item.value === cust)
                options.push(custDB)
            }
            options.push({value: "XXX", label: "no RRP (UVP)"})
            return options
        } else {
            return [];
        }

    }

    getCategories(){
        const options = []
        if(SWParamsStore.status.fetched){
            const category = SWParamsStore.list['categories']
            if(category){
                options.push({value: 0, label: 'no Category'})
                category.map(item => {
                    options.push({value: item.id, label: item.name})
                })
                return options
            } else {
                return []
            }
        } else {
            return []
        }
    }

    handleCategory(e){
        if(!e || e.length === 0){
            this.localstate.categoryID = []
        } else {
            if(e.length < this.localstate.categoryID.length){
                for(let category of e){
                    this.localstate.categoryID = this.localstate.categoryID.filter(id => category.value == id)
                }
            }
            
            for(let category of e){
                if(!this.localstate.categoryID.includes(category.value)){
                    this.localstate.categoryID.push(category.value)
                }
            }
        }
    }



    render() {
        const {categoryID, customergroup, language, uvpref, currency, loading, products, considered, selected, page, rowsPerPage, searchWord} = this.localstate
        const custgroups = this.makeOptions("customerGroups")
        const uvpcustgroups = this.makeUVPcustgroups(custgroups)
        const categories = this.getCategories()

        const languages = [
            {value: 1, label: "German"},
            {value: 2, label: "English"},
            {value: 3, label: "French"},
            {value: 4, label: "Dutch"},
            {value: 7, label: "Finnish"},
            {value: 9, label: "Italian"},
            {value:11, label: "Spanish"},
            {value:14, label: "Danish"},
            {value:15, label: "Portuguese"},
            {value:16, label: "Polish"},
        ];

        const sliderMarks = [
            {value: 0, label: "0%"},
            {value: 25, label: "25%"},
            {value: 50, label: "50%"},
            {value: 75, label: "75%"},
            {value: 100, label: "100%"}
        ]

        const framestyle = {display: this.localstate.framestyle, overflowX: 'scroll'}

        return(
            <div>
                <NavigationBar title = "Create Pricelists" itemsLeft={ <Input
                    onChange={(e) => this.changeSearchWord(e.target.value)}
                    value={ searchWord }
                    disabled={products.length === 0}
                    placeholder= "Search for articles by name or number"
                />}>
                    <Button onClick={() => this.exportPDF()} disabled={selected.length === 0} style={styles.exportbutton}>
                        <IconWithText icon={<FaFilePdf/>} text={"Export pricelist"}/>
                    </Button>
                    <Button onClick={() => this.exportCSV()} disabled={selected.length === 0} style={styles.exportbutton}>
                        <IconWithText icon={<FaFileCsv/>} text={"Export pricelist"}/>
                    </Button>
                </NavigationBar>
                <Container style={styles.container}>
                    <Container >
                        <FetchView store={SWParamsStore}>
                            <Columns>
                                <Columns.Column size={3}>
                                    <div style={styles.sliderText}>Customergroup</div>
                                    <Select
                                        className = "basic-multi-select"
                                        options = {custgroups}
                                        onChange = {e => this.handleCustgroupSelect(e)}
                                        value = {custgroups.find(item => item.value === customergroup)}
                                        placeholder = "Select a customergroup..."
                                    />
                                </Columns.Column>
                                <Columns.Column size={2}>
                                    <div>Language</div>
                                    <Select
                                        className = "basic-multi-select"
                                        options = {languages}
                                        onChange = {e => this.handleLangSelect(e)}
                                        value = {languages.find(item => item.value === language)}
                                        placeholder = "Select a language..."
                                    />
                                </Columns.Column>
                                <Columns.Column size={2}>
                                    <div>RRP (UVP) - Reference</div>
                                    <Select
                                        className = "basic-multi-select"
                                        options = {uvpcustgroups}
                                        onChange = {e => this.handleUVPRefSelect(e)}
                                        value = {uvpcustgroups.find(item => item.value === uvpref)}
                                        placeholder = "What's the reference for the RRP(UVP)?"
                                    />
                                </Columns.Column>
                                <Columns.Column size={2}>
                                    <div>Categories</div>
                                    <Select
                                        className = "basic-multi-select"
                                        isMulti
                                        options = {categories}
                                        onChange = {e => this.handleCategory(e)}
                                        value = {categories.find(item => item.value === categoryID)}
                                        placeholder = "Select a category..."
                                    />
                                </Columns.Column>
                                <Columns.Column size={2}>
                                    <div>Get started</div>
                                    <Button
                                        onClick={() => {this.GetData()}}
                                        disabled={!customergroup || !language}
                                        loading={loading}
                                        fullwidth
                                        color='primary'
                                    >Get data</Button>
                                </Columns.Column>
                            </Columns>
                        </FetchView>
                    </Container>
                    <Container style={this.showSlider()}>
                        <Container style={styles.sliderL}>
                            <Container>Margin for Retailer</Container>
                            <Slider
                                value = {this.localstate.margin*100}
                                onChange={(e, nv) => this.changeMargin(nv)}
                                marks = {sliderMarks}
                                aria-labelledby="continuous-slider"
                                valueLabelDisplay= "auto"
                                disabled={customergroup !== "MAR"}
                            />
                        </Container>
                        <Container style={styles.sliderR}>
                            <Container style={styles.sliderText}>Minimal margin for KoRo</Container>
                            <Slider
                                value = {this.localstate.marginKoRo*100}
                                onChange={(e, nv) => this.changeMarginKoRo(nv)}
                                marks = {sliderMarks}
                                aria-labelledby="continuous-slider"
                                valueLabelDisplay= "auto"
                                disabled={customergroup !== "MAR"}
                            />
                        </Container>
                    </Container>
                    <Container style={framestyle}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            onClick={() => this.selectAll(considered)}
                                            checked = {selected.length === considered.length}
                                        />
                                        ({selected.length})
                                    </TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Article number</TableCell>
                                    <TableCell>Purchase price</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>RRP (UVP)</TableCell>
                                    <TableCell>Margin (Retailer)</TableCell>
                                    <TableCell>Margin (KoRo)</TableCell>
                                    <TableCell>Stock</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {!loading && considered.slice(page*rowsPerPage, (page + 1)*rowsPerPage).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox
                                            onClick={() => this.clickBox(item)}
                                            checked={selected.includes(item)}
                                        />
                                    </TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.ordernumber}</TableCell>
                                    <TableCell>€ {item.purchaseprice.toFixed(2)}</TableCell>
                                    <TableCell>{currency} {item.price.toFixed(2)}</TableCell>
                                    <TableCell>{currency} {this.showUVP(item.uvp.toFixed(2))}</TableCell>
                                    <TableCell style={this.defineCellColor(this.calculateMargin(item.uvp, item.tax, item.price))}>{this.calculateMargin(item.uvp, item.tax, item.price)} %</TableCell>
                                    <TableCell>{((item.price - item.purchaseprice)*100/item.price).toFixed(2)} %</TableCell>
                                    <TableCell>{item.stock}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'Show all', value: considered.length }]}
                            colSpan={5}
                            component="div"
                            count={considered.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Rows per page"
                            labelDisplayedRows={this.handleLabelDisplayedRows.bind(this)}
                            page={page}
                            onChangePage={this.handleChangePage.bind(this)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </Container>
                </Container>
            </div>
        )
    }
}


const styles ={
    exportbutton: {
        marginRight: "6pt"
    },
    select: {
        width: "20%",
        padding: "15pt",
        margin: "auto"
    },
    slidersON:{
        display: "flex"
    },
    container: {
        padding: 20
    },
    slidersOFF:{
        display: "none"
    },
    sliderL:{
        padding: "15pt",
        //display: "flex"
    },
    sliderR:{
        paddingTop: "15pt",
        paddingLeft: "80pt"
    },
    sliderText:{
        height: "20pt"
    },
    button: {
        borderColor: "#cccccc",
        height: "38px",
        marginTop: "35pt",
        marginLeft: "15pt"
    },
    horiz: {
        display: "flex",
        width: "100%",
        alignItems: "bottom"
    },
    search:{
        paddingTop: "15pt",
        paddingLeft: "15pt",
        paddingRight: "15pt",
        width: "50%"
    },
    pagination:{
        paddingTop: "15pt",
        alignSelf: "flex-end",
        width:"50%"
    },
    hiddentbl: {
        display: "none"
    },
    bad: {
        backgroundColor: 'LightCoral',
        color: 'DarkRed'
    }
}

export default Pricelist;
