import React, { Component } from "react";
import Countdown from "./Countdown";
import { Button, Heading } from "react-bulma-components/dist";
import FileUploadList from "../../FileUploadList";
import { observer } from "mobx-react";
import { Textarea } from "react-bulma-components/lib/components/form";

@observer
class Timer extends Component {
  render() {
    return (
      <div className="timer" style={styles.timer}>
        <Countdown
          secondsLeft={this.props.timeRemaining}
          started={this.props.started}
          totalTime={this.props.totalTime}
        />
        {this.props.started ? (
          <div className="challenge__file-view">
            <div style={styles.container}>
              <FileUploadList
                onFileSelect={(e) => this.props.onFileChange(e)}
                onFileRemove={(file) => this.props.onRemoveFile(file)}
                files={this.props.files}
              />
            </div>
            <div style={styles.container}>
              <Textarea
                value={this.props.comment}
                onChange={(e) => this.props.onCommentChange(e.target.value)}
                placeholder="Magst du uns noch etwas mitteilen? (optional)"
              />
            </div>
            <Button
              onClick={this.props.onFinishChallenge}
              className="challenge__download-button"
            >
              <Heading>Lösung hochladen</Heading>
              <p className="challenge__button-subtitle">
                und Challenge beenden
              </p>
            </Button>
          </div>
        ) : (
          <Button
            className="challenge__download-button"
            onClick={this.props.onDownloadClick}
          >
            <Heading>Unterlagen herunterladen</Heading>
            <p className="challenge__button-subtitle">und Timer starten</p>
          </Button>
        )}
      </div>
    );
  }
}

const styles = {
  timer: {
    textAlign: "center",
  },
  container: {
    marginBottom: "2rem",
  },
};

export default Timer;
