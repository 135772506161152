import React, {Component} from 'react';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container, Box, Columns} from "react-bulma-components/dist";
import {observer} from "mobx-react";
import {observable} from 'mobx';
import tabs from '../../../config/tabs';
import {Link} from "react-router-dom";
import Authentication from "../../../shared/Authentication";

@observer
class HomeScreen extends Component{
    @observable localstate = {
    };


    render(){
        return (
            <div>
                <NavigationBar title='KoRo Toolbox'>
                </NavigationBar>

                <Container style={styles.container}>
                    <Columns>
                        {tabs.map((item) => {
                            if (Authentication.hasAccess(item)) {
                                return <Columns.Column className='is-one-third' key={item.url}>
                                    <Link to={item.url}>
                                        <Box>
                                            <Columns>
                                                <Columns.Column>
                                                    <div style={styles.header}>{item.label}</div>
                                                </Columns.Column>
                                            </Columns>
                                            <div style={styles.description}>
                                                {item.description}
                                            </div>
                                        </Box>

                                    </Link>
                                </Columns.Column>
                            }
                        })}
                    </Columns>
                </Container>
            </div>
        );
    }
}


const styles = {
    container: {
        padding: '20px'
    },
    description: {

    },
    header: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        display: 'flex',
        flex: 1,
        backgroundColor: '#eee',
        height: '40px',
        width: '40px',
        borderRadius: '40px',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default HomeScreen;
