import React, {Component} from 'react';
import {Modal, Box, Container, Button, Heading } from 'react-bulma-components/dist'
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";

@observer
class DashboardScreen extends Component {
    @observable localstate = {
        userData: this.props.userData,
        userCode: this.props.userCode,
        loading: false,
        showModal: false
    }

    async claimReward(){
        const {userCode} = this.localstate
        const request = RestClient.prepareRequest('GET', 'affiliate-user/' + userCode + '/reward')
        this.localstate.loading = true
        try{
            const response = await request.send();
            if (response.success){
                this.localstate.userData.rewardVouchers = JSON.parse(response.data.rewardVouchers)
                toast.success("Your Voucher has been generated. Check below to view the voucher.")
            } else {
                toast.error("Something went wrong. " + response.error)
            }

            this.localstate.loading = false
        } catch (e) {
            this.localstate.loading = false
            toast.error("Something went wrong " + e)
        }

    }

    formatVouchers(){
        const vouchers = this.localstate.userData.rewardVouchers
        if (vouchers) {
            return <div>
                <Heading>Your reward vouchers</Heading>
                <p>You can redeem your voucher on our Koro online shop.</p>
                <br/>
                {vouchers.map((item) => {
                    return <div>{item}</div>
                })}
            </div>
        } else {
            return <div>
                <Heading>You have no reward vouchers...</Heading>
                <div>
                    Once you have earned more than 10 €, you can claim your reward by pressing
                    the green button. Your reward voucher will then appear here. <br/>
                    Vouchers can be redeemed in our Koro online shop.
                </div>
            </div>
        }
    }

    openModal(){
        this.localstate.showModal = true
    }

    closeModal(){
        this.localstate.showModal = false
    }

    render() {
        const {fullName, numberOfOrders, publicVoucher, payableAmount, totalMoneyClaimed} = this.localstate.userData
        const {showModal} = this.localstate
        return(
            <Container style={{padding: 20}}>
                <Modal show={showModal} closeOnBlur={true} onClose={this.closeModal.bind(this)}>
                    <Modal.Content style={styles.innerModal}>
                        <Container style={{padding:10}}>
                            {this.formatVouchers()}
                        </Container>
                    </Modal.Content>
                </Modal>
                <Heading>Welcome {fullName}!</Heading>
                <div style={styles.text}>
                    This is the voucher that you can share with your followers and friends on Instagram or Facebook.
                </div>
                <Box style={styles.voucherBox}>{publicVoucher}</Box>
                <div style={styles.text}>
                    You will receive 5% commission on any sale generated through this voucher.
                    So, make sure to bookmark this website to have this voucher always available
                    and to claim your reward later.
                    Please remember that sharing your voucher outside your own profiles is not accepted and will
                    lead to your voucher being deactivated.
                </div>
                <Heading>Your Earnings</Heading>
                <div>
                    For technical reasons, the statistics appear 2 weeks delayed.
                </div>
                <table>
                    <tbody>
                    <tr>
                        <td style={styles.circleWrapper}>
                            <strong style={{...styles.circleStyle, ...{fontSize: "medium"}}}>{numberOfOrders}</strong>
                        </td>
                        <td style={styles.explanationText}>orders have been placed with your voucher</td>
                    </tr>
                    <tr>
                        <td style={styles.circleWrapper}>
                            <b style={{...styles.circleStyle, ...{fontSize: "small"}}}>{payableAmount}</b>
                        </td>
                        <td style={styles.explanationText}>
                            You have earned {payableAmount} € since you last claimed your reward.
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.circleWrapper}>
                            <b style={{...styles.circleStyle, ...{fontSize: "small"}}}>{totalMoneyClaimed}</b>
                        </td>
                        <td style={styles.explanationText}>You have already received {totalMoneyClaimed} € in total.</td>
                    </tr>
                    </tbody>
                </table>
                <div style={styles.buttonWrapper}>
                    <Button
                        color='primary'
                        disabled={payableAmount < 10}
                        style={{width: "80%"}}
                        onClick={() => this.claimReward()}
                        loading={this.localstate.loading}
                    >Claim your reward</Button>
                </div>
                <div style={styles.buttonWrapper}>
                    <Button
                        style={{width: "80%"}}
                        onClick={() => this.openModal()}
                    >Show my reward vouchers</Button>
                </div>
            </Container>
        )
    }
}

const styles = {
    circleStyle: {
        height: "45px",
        width: "45px",
        display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
        backgroundColor: "lightgray",
        borderRadius: "50%",
    },
    circleWrapper: {
        paddingBottom: "10px",
        paddingTop: "10px",
        paddingRight: "10px"
    },
    explanationText: {
        display: "table-cell",
        verticalAlign: "middle",
    },
    text: {
        paddingTop: "20px",
        paddingBottom: "20px"
    },
    voucherBox:{
        width: "80%",
        marginBottom: "0px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto"
    },
    buttonWrapper:{
        paddingTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
    },
    innerModal: {
        backgroundColor: 'white'
    }
}

export default DashboardScreen;