import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {IconWithText} from "../../dumb";
import {FaTrash, FaPlusSquare} from "react-icons/fa";
import NavigationBar from "../NavigationBar/NavigationBar";
import FetchView from "../../dumb/FetchView/FetchView";
import {ScheduledOrdersStore} from "../../../stores/ScheduledOrdersStore";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Modal, Heading,Section, Button, Container} from 'react-bulma-components/dist';
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import {toast} from "react-toastify";
import {TableBody} from "@material-ui/core";
import moment from "moment";

@observer
class ScheduledOrders extends Component {
    @ observable localstate = {
        showModal: false,
        scheduledOrder: {
            ordernumber: "",
            releaseDate: ""
        },
        loading: false,
        disabled: true
    }

    deleteScheduledOrder(nr){
        const confirmed = confirm('Are you sure you want to delete this scheduled order? The order will be released immediately.');
        if (confirmed) {
            ScheduledOrdersStore.deleteItem(nr)
        }
    };

    openModal(){
        this.localstate.showModal = true
    }

    closeModal(){
        this.localstate.showModal = false
    }

    changeVal(key, val){
        this.localstate.scheduledOrder[key] = val
    }

    renderField(key, i){
        let label
        let type
        if (key === "ordernumber"){
            label = "Shop Ordernr"
            type = "text"
        } else if (key === "releaseDate"){
            label = "Should be released on"
            type = "date"
        }
        return(
            <Field key={i}>
                <Label>{label}</Label>
                <Control>
                    <Input
                        type={type}
                        value={this.localstate.scheduledOrder[key]}
                        onChange={(e) => this.changeVal(key, e.target.value)}>
                    </Input>
                </Control>
            </Field>
        )
    }

    async save() {
        const {scheduledOrder} = this.localstate;
        if(moment(scheduledOrder.releaseDate) < moment()) {
            toast.error("The releasedate cannot be in the past")
            return
        }
        this.localstate.loading = true;
        let success;
        success = await ScheduledOrdersStore.createItem(scheduledOrder);
        this.localstate.loading = false;
        console.log(success);
        if (success.success){
            toast.success("Order " + scheduledOrder.ordernumber + " is now locked until " + moment(scheduledOrder.releaseDate).format("DD.MM.YYYY"));
        } else {
            toast.error("An error occurred. " + success.error.message)
        }
        this.localstate.showModal = false
        this.localstate.scheduledOrder.ordernumber = ""
        this.localstate.scheduledOrder.releaseDate = ""
    }

    render(){
        const disabled = Object.values(this.localstate.scheduledOrder).some(item => item === "")
        const {showModal, loading} = this.localstate
        return(
            <div>
                <NavigationBar title='Scheduled Orders'>
                    <Button onClick={() => this.openModal()}>
                        <IconWithText icon={<FaPlusSquare/>} text= "Add scheduled order"/>
                    </Button>
                </NavigationBar>
                <Modal show={showModal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                   
                    <Modal.Content style={styles.innerModal}>
                        <Section>
                            <Heading>Add scheduled order</Heading>
                            {["ordernumber", "releaseDate"].map((item, i) => {
                                return this.renderField(item, i)
                            })}
                            <Button className='is-primary' fullwidth
                                    onClick={() => this.save()}
                                    loading={loading}
                                    disabled={disabled}
                            >Save scheduled order</Button>
                        </Section>
                    </Modal.Content>
                   
                </Modal>
                <Container>
                    <Section>
                        <FetchView store={ScheduledOrdersStore}>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ordernumber</TableCell>
                                        <TableCell>releasedate</TableCell>
                                        <TableCell>released</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {ScheduledOrdersStore.list.map((item, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{item.ordernumber}</TableCell>
                                        <TableCell>{moment(item.releaseDate).format("DD.MM.YYYY")}</TableCell>
                                        <TableCell>{item.released.toString()}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => this.deleteScheduledOrder(item)}><FaTrash/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </FetchView>
                    </Section>
                </Container>
            </div>
        );
    };
}

const styles = {
    innerModal: {
        backgroundColor: 'white'
    }
}


export default ScheduledOrders;