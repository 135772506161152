import React, {Component} from 'react';
import {Tabs, Container} from 'react-bulma-components/dist'
import ProductsScreen from "./ProductsScreen/ProductsScreen";
import TemplateScreen from "./TemplateScreen/TemplateScreen";
import Navigation from "../../../shared/Navigation";
import SupplierScreen from "./SupplierScreen/SupplierScreen";
import OverviewScreen from "./OverviewScreen/OverviewScreen";
import MailsScreen from "./MailsScreen/MailsScreen";
import {observer} from 'mobx-react';
import {observable} from 'mobx';

const subpages = [{
    "label": "Overview",
    "url": "",
    "component": <OverviewScreen/>
},{
    "label": "Products",
    "url": "/products",
    "component": <ProductsScreen/>
}, {
    "label": "Suppliers",
    "url": "/suppliers",
    "component": <SupplierScreen/>
}, {
    "label": "Mails",
    "url": "/mails",
    "component": <MailsScreen/>
},  {
    "label": "Templates",
    "url": "/templates",
    "component": <TemplateScreen/>
}]

@observer
class SourcingTool extends Component {
    @observable localstate = {
        navigate: null
    };

    navigate(item) {
        Navigation.history && Navigation.history.push('/sourcing' + item.url);
    }

    render() {
        const url = Navigation.location.pathname;
        let activeItem = null;
        return (<div><Container>
            <Tabs type='toggle' fullwidth={true}>
                {subpages.map(item => {
                    if (url === '/sourcing' + item.url) {
                        activeItem = item;
                    }
                    return (<Tabs.Tab key={item.url} onClick={() => this.navigate(item)} active={url === '/sourcing' + item.url}>
                        {item.label}
                    </Tabs.Tab>)
                })}
            </Tabs>
        </Container>
            {activeItem && activeItem.component}
        </div>)
    }
}

export default SourcingTool;