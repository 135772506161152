import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import NavigationBar from '../NavigationBar/NavigationBar';
import { Button, Container, Modal } from 'react-bulma-components';
import GenericForm from '../GenericForm/GenericForm';
import { SubscriptionsStore } from 'stores/SubscriptionsStore';
import FetchView from '../../dumb/FetchView/FetchView';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment'


@observer
class Subscription extends Component {
  @observable localstate = {
    status: {
      edit: false,
    },
  };

  onSave() {
    this.localstate.status.edit = false
  }

  editSubscription(subscription){
    this.localstate.status.edit = subscription;
  }

  async deleteSubscription(subscription){
    try {
      if(confirm('Are you absolutely sure, that you want to delete this Subscription?')){
        await SubscriptionsStore.deleteItem(subscription);
        toast.success('Successfully deleted this Subscription!')
      }
      
    } catch (err) {
      toast.error('An Error occured. ' + err)
    }
  }

  render() {
    const { edit } = this.localstate.status;
    return (
      <>
        <NavigationBar title="Subscriptions Overview">
          <Button onClick={() => (this.localstate.status.edit = true)}>
            Add Subscription
          </Button>
        </NavigationBar>
        <FetchView store={SubscriptionsStore}>
          <Container>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Nr.</TableCell>
                  <TableCell colSpan={2}>Bestellnummer</TableCell>
                  <TableCell colSpan={2}>Intervall in Tagen</TableCell>
                  <TableCell colSpan={2}>Order duplicated</TableCell>
                  <TableCell colSpan={2}>next Order duplication</TableCell>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SubscriptionsStore.list.map((subs, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={2}>{index + 1}</TableCell>
                    <TableCell colSpan={2}>{subs.ordernumber}</TableCell>
                    <TableCell colSpan={2}>
                      {subs.intervalInDays}{' '}
                      {subs.intervalInDays === 1 ? 'Tag' : 'Tage'}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {subs.orderDuplicatedAt ? moment(subs.orderDuplicatedAt).format('DD.MM.YYYY') : 'Not Duplicated'}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {moment(subs.nextOrderDuplicationAt).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Button onClick={() => this.editSubscription(subs)}>
                        Edit
                      </Button>
                      <Button onClick={() => this.deleteSubscription(subs)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Container>
        </FetchView>

        <Modal show={edit} onClose={this.onSave.bind(this)}>
          {edit && (
            <GenericForm
              store={SubscriptionsStore}
              id={edit.id}
              onSave={this.onSave.bind(this)}
            />
          )}
        </Modal>
      </>
    );
  }
}

export default Subscription;
