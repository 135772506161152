import React, {Component} from 'react';
import {Heading, Container} from 'react-bulma-components/dist'
import {Input} from "react-bulma-components/lib/components/form";
import Loader from "react-bulma-components/lib/components/loader";
import {observable} from 'mobx';
import {observer} from 'mobx-react';

import RestClient from "../../../shared/Network/RestClient";


@observer
class ReplacementOrderScreen extends Component {
    @observable localstate = {       
        orderNr: "",
        loading: false,
        result: ""
    };

    updateState(k, v) {
        this.localstate[k] = v;
    }

    checkIfEnter (keyCode) {
        if(keyCode === 13) {
            this.localstate.loading = true;
            this.localstate.result = "";
            this.checkForReplacementOrder(this.localstate.orderNr);
        }
    }

    async checkForReplacementOrder (orderNr) {
        try {
            const request = RestClient.prepareRequest('GET', 'replacementOrders/'+orderNr);
            const response = await RestClient.sendRequest(request);
            let result = "No replacement order found!";
            if(response.success) {
                if (response.data.length > 0 && response.data[0].replacement_order) {
                    result = response.data[0].replacement_order;
                }

                if (response.data.length > 0 && response.data[0].paymentID === 198) {
                    result = "This is a replacement order!";
                }
            }
            this.localstate.result = result;
            this.localstate.loading = false;
            this.localstate.orderNr = "";
        } catch (e) {
            console.log(e);
            this.localstate.loading = false;
            this.localstate.orderNr = "";
        }
    }

    render() {
        const loading = this.localstate.status;
        const orderNr = this.localstate.orderNr;
        const result = this.localstate.result;
        return (
            <Container style={styles.container}>
                <Heading>Check for replacement orders</Heading>
                <Input
                    onChange={(e) => this.updateState('orderNr', e.target.value)}
                    onKeyDown={(e) => this.checkIfEnter(e.keyCode)}
                    value={orderNr}
                    disabled={loading}
                    placeholder= "Shop order number or KOR number"
                    style={styles.input}
                    autoFocus
                />
                {this.localstate.loading ? 
                <div style={styles.loaderContainer}>
                    <Loader style={styles.loader} />
                </div>
                :
                <div style={styles.resultContainer}><span style={result.includes("replacement") ? {color: "red"}:{color: "green"}}>{result}</span></div>
                }
            </Container>
       )
    }
}

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px'
    },
    input: {
        textAlign: 'center'
    },
    loader: {
        width: 150,
        height: 150
    },
    loaderContainer: {
        padding: '6em',
        display: 'flex',
        justifyContent: 'center'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '2rem',
        marginTop: '5%'
    }
}


export default ReplacementOrderScreen;