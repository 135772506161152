import React, {Component} from 'react';
import FetchView from "../../dumb/FetchView/FetchView";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Modal, Button, Container} from 'react-bulma-components/dist'
import NavigationBar from "../NavigationBar/NavigationBar";
import {toast} from "react-toastify";
import {Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {UserStore} from "../../../stores/UserStore";
import Authentication from "../../../shared/Authentication";
import UserEdit from "../GenericForm/GenericForm";

// default imports until here

@observer
class UserManagement extends Component {
    @observable localstate = {
        edit: null
    };

    async deleteUser(item) {
        try {
            const reply = confirm('Do you really want to delete this user?');
            if (reply) {
                await UserStore.deleteItem(item);
                toast.success('The user has been deleted successfully');
            }
        } catch (e) {
            toast.error(e);
        }
    }

    async editUser(item) {
        this.localstate.edit = item;
    }

    onSave() {
        this.localstate.edit = null;
    }


    render() {
        const {edit} = this.localstate;
        return <div>
            <NavigationBar title='Usermanagement'>
                <Button color='primary' onClick={this.editUser.bind(this)}>Add User</Button>
            </NavigationBar>
            <Container style={styles.container}>
                <FetchView store={UserStore}>
                        <Table aria-label="simple table" style={{overflow:'scroll'}}>
                            <TableHead>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Mail</TableCell>
                                <TableCell>Groups</TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            { UserStore.list.map(item => {
                                return (<TableRow>
                                    <TableCell>{item.firstName}</TableCell>
                                    <TableCell>{item.lastName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                        {item.isAdmin  && <TableCell>ADMIN / EVERYTHING</TableCell>}
                                        {!item.isAdmin && <TableCell>{item.usergroups && item.usergroups.join(',')}</TableCell>}
                                    <TableCell>
                                        <Button onClick={() => this.editUser(item)}>Edit</Button>
                                        {item.id !== Authentication.user.id && <Button onClick={() => this.deleteUser(item)}>Del</Button>}
                                    </TableCell>
                                </TableRow>);
                            })}
                        </Table>
                </FetchView>
            </Container>
            <Modal show={edit} onClose={this.onSave.bind(this)}>
                {edit && <UserEdit store={UserStore} id={edit.id} onSave={this.onSave.bind(this)}/>}
            </Modal>
        </div>;
    }
}

const styles = {
    container: {
        padding:20,
        overflowX: 'scroll'
    }
}

export default UserManagement;