import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container} from 'react-bulma-components/dist';
import {Input} from "react-bulma-components/lib/components/form";
import {
    Button,
    Table, TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {toast} from "react-toastify";
import {IconWithText} from "../../dumb";
import {FaPlus, FaUpload} from "react-icons/all";
import FetchView from "../../dumb/FetchView/FetchView";
import Loader from "react-bulma-components/lib/components/loader";
import ReserveOneDialog from "./ReserveOneDialog";
import {BulkReservation, ReservationObject} from "./ReservationObject";
import {ArticlesStore} from "../../../stores";
import ReserveMultipleDialog from "./ReserveMultipleDialog";
import ReservationService from "./ReservationService";

@observer
class StockReservation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedReservation: null,
            articles: [],
            filteredArticles: [],
            openNewReservationDialog: false,
            openBulkReservationDialog: false,
        };
    }

    @observable localstate = {
        data: [],
        headers: [],
        loading: false,
        products: [],
        filtered: [],
        selected: [],
        page: 0,
        rowsPerPage: 10,
        searchWord: '',
        progressText: ''
    };

    filterItem(item, word) {
        return item.name.toLowerCase().includes(word) ||
            item.articleNumber.toLowerCase().includes(word) ||
            item.reason.toLocaleLowerCase().includes(word) ||
            item.note.toLocaleLowerCase().includes(word)
    }

    changeSearchWord(e){
        this.localstate.searchWord = e;
        const eLC = e.toLowerCase();
        this.localstate.filtered = this.localstate.products.filter(
            item => {
                const itemIsBatch = item.groupId && item.groupId !== ""
                if(itemIsBatch){
                    const found =  item.reservations.filter(
                        item => this.filterItem(item, eLC)
                    )
                    if(found.length > 0){
                        return item
                    }
                }else{
                    return this.filterItem(item, eLC)
                }
            }
            )
        this.localstate.page = 0;
    };

    handleChangePage(event, newPage) {
        this.localstate.page = newPage
    };

    handleChangeRowsPerPage(event) {
        this.localstate.rowsPerPage = event.target.value;
        this.localstate.page = 0;
    };

    handleLabelDisplayedRows({from, to, count}) {
        return(
            <span>{from} - {to} out of {count}</span>
        );
    };

    async init() {
        await ArticlesStore.fetchData();
        await this.refreshReservationData();
    }

    async refreshReservationData(){
        this.localstate.searchWord = '';
        this.localstate.loading = true;

        const data = await ReservationService.getAll()
        this.localstate.products = data;
        this.localstate.filtered = data;

        this.localstate.loading = false;
    };

    toggleSingleReservationDialog() {
        this.setState(prevState => ({
            openNewReservationDialog: !prevState.openNewReservationDialog,
            selectedReservation: prevState.openNewReservationDialog ? null : prevState.selectedReservation
        }));
    }

    toggleBatchReservationDialog() {
        this.setState(prevState => ({
            openBulkReservationDialog: !prevState.openBulkReservationDialog,
            selectedReservation: prevState.openBulkReservationDialog ? null : prevState.selectedReservation
        }));
    }

    handleClickEdit(item) {
        const date = new Date(item.expiryDate).toISOString().slice(0,10)

        if(item.groupId && item.groupId !== ""){
            let bulkReservation = new BulkReservation({
                date: date,
                reason: item.reason,
                note: item.note,
                groupId: item.groupId,
                reservations: item.reservations.map(item => new ReservationObject({
                    id: item.id,
                    orderlineKey: item.orderlineKey,
                    amount: item.amountReserved + item.amountPending,
                    amountPending: item.amountPending,
                    amountReserved: item.amountReserved,
                    date: date,
                    reason: item.reason,
                    productNumber: item.articleNumber,
                    productName: item.name,
                    note: item.note,
                    groupId: item.groupId
                }))
            })
            this.setState({selectedReservation: bulkReservation}, this.toggleBatchReservationDialog)

        }else{
            let selectedReservation = new ReservationObject({
                id: item.id,
                orderlineKey: item.orderlineKey,
                date: date,
                amount: item.amountReserved + item.amountPending,
                reason: item.reason,
                productNumber: item.articleNumber,
                productName: item.name,
                note: item.note,
                groupId: item.groupId
            })

            this.setState({"selectedReservation": selectedReservation}, this.toggleSingleReservationDialog)
        }
    };

    async handleClickDelete(item) {
        const itemIsBatch = item.groupId && item.groupId !== ""
        if(itemIsBatch){
            await this.deleteBatch(item)
        }else{
            await this.deleteOne(item)
        }
    }

    async deleteOne(item){
        if (confirm("Do you really want to delete this entry?")) {
            this.localstate.loading = true;

            const response = await ReservationService.delete(item.id)
            if(response.success){
                toast.success("Deleted a reservation.");
            }else{
                toast.error("Could not delete the reservation: " + response.error)
            }
            await this.refreshReservationData()
            this.localstate.loading = false;
        }
    }

    async deleteBatch(item){
        const amount = item.reservations.length
        let errorCount = 0
        if (confirm("Do you really want to delete this bulk reservation containing " + amount + " entries?")) {
            this.localstate.loading = true;

            let itemNr = 1
            for (const reservation of item.reservations) {
                this.localstate.progressText = `Deleting reservation ${itemNr}/${item.reservations.length}`
                itemNr++
                const response = await ReservationService.delete(reservation.id)
                if (!response.success) {
                    errorCount++
                }
            }
            this.localstate.progressText = ''
            if (errorCount > 0) {
                toast.error("Could not delete " + errorCount + "/" + amount + " entries.")
            } else {
                toast.success("Deleted " + amount + " reservations.");
            }

            await this.refreshReservationData()
            this.localstate.loading = false;
        }
    }

    async handleCreateOne(reservation) {
        this.localstate.loading = true;
        this.toggleSingleReservationDialog();

        const response = await ReservationService.create(reservation)
        if(response.success){
            toast.success("Successfully created a reservation")
        }else{
            toast.error("Could not create the reservation. " + response.error)
        }

        await this.refreshReservationData()
        this.localstate.loading = false;
    }

    async handleUpdateOne(reservation) {
        this.localstate.loading = true;
        this.toggleSingleReservationDialog();

        const response = await ReservationService.update(reservation)
        if(response.success){
            toast.success("Successfully updated a reservation")
        }else{
            toast.error("Could not update the reservation. " + response.error)
        }

        await this.refreshReservationData()
        this.localstate.loading = false;
    }

    async handleCreateBatch(reservations) {
        this.localstate.loading = true;
        this.toggleBatchReservationDialog()
        let errorCount = 0
        let itemNr = 1
        for (const reservation of reservations){
            this.localstate.progressText = `Creating reservation ${itemNr}/${reservations.length}`
            itemNr++
            const response = await ReservationService.create(reservation)
            if(!response?.success){
                errorCount++
            }
        }
        this.localstate.progressText = ''
        if(errorCount > 0){
            toast.error("Could not create " + errorCount + "/" + reservations.length + " reservations.")
        }else{
            toast.success("Successfully created " + reservations.length + " reservations.")
        }
        await this.refreshReservationData()
        this.localstate.loading = false;
    }

    async handleUpdateBatch(bulkReservation) {
        this.localstate.loading = true;
        this.toggleBatchReservationDialog()
        let errorCount = 0
        const amount = bulkReservation.reservations.length
        let itemNr = 1
        for (const reservation of bulkReservation.reservations){
            this.localstate.progressText = `Updating reservation ${itemNr}/${bulkReservation.reservations.length}`
            itemNr++
            const response = await ReservationService.update(reservation)
            if(!response.success){
                errorCount++
            }
        }
        this.localstate.progressText = ''
        if(errorCount > 0){
            toast.error("Could not update " + errorCount + "/" + amount + " reservations.")
        }else{
            toast.success("Successfully updated " + amount + " reservations.")
        }
        await this.refreshReservationData()
        this.localstate.loading = false;
    }

    render() {
        const { loading, products, filtered, page, rowsPerPage, searchWord, progressText } = this.localstate
        return (<div>
            <NavigationBar title='Reserve Stock' itemsLeft={  <Input
                onChange={(e) => this.changeSearchWord(e.target.value)}
                value={ searchWord }
                disabled={products && products.length === 0}
                placeholder= "Search for reservations by article name, number or reason"
            />}>
                <Button onClick={this.toggleBatchReservationDialog.bind(this)} style={styles.exportbutton}>
                    <IconWithText icon={<FaUpload/>} text={"Batch Reservation"}/>
                </Button>
                <Button onClick={this.toggleSingleReservationDialog.bind(this)} style={styles.exportbutton}>
                    <IconWithText icon={<FaPlus/>} text={"Add new reservation"}/>
                </Button>
            </NavigationBar>
            <Container style={{padding:"20px"}}>
                <FetchView call={this.init.bind(this)}>
                    {filtered && products &&
                    <Container style={{overflowX: 'scroll'}}>
                        <div style={styles.loadingContainer}>
                            {loading &&
                                <div>
                                    <Loader style={styles.loader} />
                                    <p>{progressText}</p>
                                </div>
                            }</div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Article number</TableCell>
                                    <TableCell>Amount reserved</TableCell>
                                    <TableCell>Amount pending</TableCell>
                                    <TableCell>Reason for reservation</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell>Expiry date</TableCell>
                                    <TableCell>Creation date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {!loading && filtered.slice(page*rowsPerPage, (page + 1)*rowsPerPage).map((item, i) => {
                                const strikeValue = item.shouldDelete ? "0.6" : 1
                                return (<TableRow key={i} style={{opacity: strikeValue}}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.articleNumber}</TableCell>
                                    <TableCell>{item.amountReserved}</TableCell>
                                    <TableCell>{item.amountPending}</TableCell>
                                    <TableCell>{item.reason}</TableCell>
                                    <TableCell>{item.note}</TableCell>
                                    <TableCell>{new Date(item.expiryDate).toDateString()}</TableCell>
                                    <TableCell>{new Date(item.createdAt).toDateString()}</TableCell>
                                    <TableCell>
                                        {item.shouldDelete
                                        ?
                                            <p>Pending Delete</p>
                                        :
                                            <div>
                                                <Button onClick={() => {this.handleClickEdit(item)}}>
                                                    Edit
                                                </Button>
                                                <Button onClick={() => {this.handleClickDelete(item)}}>
                                                    Delete
                                                </Button>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>)
                            })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'Show all', value: filtered.length }]}
                            colSpan={5}
                            component="div"
                            count={filtered.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Rows per page"
                            labelDisplayedRows={this.handleLabelDisplayedRows.bind(this)}
                            page={page}
                            onChangePage={this.handleChangePage.bind(this)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </Container>}
                </FetchView>
                {this.state.openNewReservationDialog &&
                    <ReserveOneDialog
                        open={this.state.openNewReservationDialog}
                        onClose={this.toggleSingleReservationDialog.bind(this)}
                        reservation={this.state.selectedReservation}
                        onCreate={this.handleCreateOne.bind(this)}
                        onUpdate={this.handleUpdateOne.bind(this)}
                    /> }
                {this.state.openBulkReservationDialog &&
                    <ReserveMultipleDialog
                        open={this.state.openBulkReservationDialog}
                        onClose={this.toggleBatchReservationDialog.bind(this)}
                        reservation={this.state.selectedReservation}
                        onCreate={this.handleCreateBatch.bind(this)}
                        onUpdate={this.handleUpdateBatch.bind(this)}
                    /> }
            </Container>
        </div>);
    }
}

const styles = {
    matching: {
        display: "flex"
    },
    matchingTable:{
        width: "70%"
    },
    matchingTableFC:{
        width: "20%",
        padding: "10pt"
    },
    horiz: {
        display: "flex",
        width: "100%",
        alignItems: "bottom"
    },
    search:{
        paddingTop: "15pt",
        paddingLeft: "15pt",
        paddingRight: "15pt",
        width: "50%"
    },
    pagination:{
        paddingTop: "15pt",
        alignSelf: "flex-end",
        width:"50%"
    },
    button: {
        borderColor: "#cccccc",
        height: "38px",
        marginTop: "35pt",
        marginLeft: "15pt"
    },
    exportbutton: {
        marginRight: "6pt"
    },
    select: {
        padding: "10pt",
        margin: "auto"
    },
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader: {
        width: 150,
        height: 150
    }
}

export default StockReservation;