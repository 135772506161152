import {observer} from "mobx-react";
import React, {Component} from "react";
import {observable, action} from "mobx";
import FetchView from "../../dumb/FetchView/FetchView";
import {Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Tabs, Button} from 'react-bulma-components/dist'
import NavigationBar from "../NavigationBar/NavigationBar";
import moment from 'moment';
import {B2BPricesStore} from "../../../stores/B2BPrices";
import { Container, Columns, Box} from 'react-bulma-components/dist'
import Select from "react-select";
import {  Field, Input, Label, Control} from 'react-bulma-components/lib/components/form';
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";
import {SWParamsStore} from "../../../stores/SWParamsStore";
import {B2BOrderStore} from "../../../stores";


const WAREHOUSE_LOCATIONS = [{
    label: "Shop Location",
    value: "001"
},{
    label: "Retail Location",
    value: "004"
},{
    label: "Special Projects Location",
    value: "005"
}]

const RESET = {
    selectedShipping: null,
    selectedBilling: null,
    skus: [{
        sku: null,
        quantity: 1
    }],
    total: 0,
    paymentMethod: null,
    shipmentMethod: null,
    referenceNumber: "",
    internalNote: "",
    location: WAREHOUSE_LOCATIONS[0],
    shipping_cost: 0
}

@observer
class B2BOrderCreation extends Component {
    @observable localstate = {
        status: {
            loading: false
        },
        selectedCustomer: null,
        shippingAddresses: [],
        selectedShipping: null,
        selectedBilling: null,
        referenceNumber: "",
        deliveryDate: null,
        availableSKUs: [],
        skus: [{
            sku: null,
            quantity: 1
        }],
        paymentMethod: null,
        shipmentMethod: null,
        shopwareParams: {
            payment: [],
            shipping: []
        },
        total: 0,
        location: WAREHOUSE_LOCATIONS[0],
        shipping_cost: 0
    };

    constructor() {
        super();
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.formatShippingAddress = this.formatShippingAddress.bind(this);
        this.formatItemForSelect = this.formatItemForSelect.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
    }

    handleChange(k, v) {
        this.localstate[k] = v;
        this.calculcateTotal();
    }

    updateItem(index, key, value) {
        this.localstate.skus[index][key] = value;

        if (this.localstate.skus.length===index+1) {
            this.localstate.skus.push({sku: null, quantity: 1})
        }
        this.calculcateTotal();
    }

    removeItem(index) {
         this.localstate.skus.splice(index, 1);
         console.log(this.localstate.skus);
    }

    calculcateTotal() {
        const {skus, availableSKUs, shipping_cost} = this.localstate;
        let total = 0;
        for (const sku of skus) {
            if (!sku.sku) continue;
            total += sku.quantity * availableSKUs.find(x => x.sku === sku.sku).price;
        }
        total = parseFloat(total) + parseFloat(shipping_cost);
        this.localstate.total = Math.round(total*100)/100;
    }

    handleSelectChange(selectName, e) {
        this.localstate[selectName] = e;
        if (selectName === 'selectedCustomer') {
            this.localstate={...this.localstate, ...RESET};
            this.formatShippingAddress(B2BPricesStore.list, e.value);
            this.localstate.availableSKUs = B2BPricesStore.list.filter(x => x.customer === e.value);
            const {default_shipping_address_id, default_billing_address_id} = this.localstate.availableSKUs[0];
            const shipDefault = this.localstate.shippingAddresses.find(x => x.value.id === default_shipping_address_id);
            const billDefault = this.localstate.shippingAddresses.find(x => x.value.id === default_billing_address_id);

            this.localstate.selectedShipping = shipDefault;
            this.localstate.selectedBilling = billDefault;
            this.localstate.paymentMethod = this.localstate.shopwareParams.payment.find(x => x.label === 'Rechnung (Firmenkunde)')
        }
    }

    formatItemForSelect(data, key, labelKey) {
        const {selectedCustomer} = this.localstate;
        if (key !== 'customer') {
            data = data.filter(x => x.customer === selectedCustomer.value)
        }
        let items = [];
        for (const item of data) {
            if (!items.find(x => x.value === item[key])) {
                items.push({
                    label: labelKey ? item[key]  + " - " + item[labelKey] : item[key] ,
                    value: item[key]
                });
            }
        }
        return items;
    }

    formatShippingAddress(data, selectedCustomer) {
        let addresses = [];
        let options = data.filter(x => x.customer === selectedCustomer);
        options = options.map(x => { return { ...x, phone: x.phone ? x.phone :''}});
        for (const option of options) {
            const label = option.street + ", " + option.city;
            if (!addresses.find(x => x.label === label)) {
                addresses.push({
                    label: label,
                    value: option
                });
            }
        }
        this.localstate.shippingAddresses = addresses;
    }



    async submitOrder() {
        let {selectedCustomer,selectedShipping, skus, total, paymentMethod, shipmentMethod, selectedBilling, availableSKUs} = this.localstate;
        skus = skus.filter(x => x.sku);
        // check for completeness
        let complete = true;
        for (const item of skus) {
            if (item.sku && (item.quantity<0 || isNaN(item.quantity))) {
                complete = false;
            }
        }
        if (!selectedCustomer || !selectedShipping || skus.length<1 || !complete || !paymentMethod || !shipmentMethod || !selectedBilling) {
            toast.error('Please select a customer, a shipping method and at least 1 item!')
            return;
        }

        // submit order => let's do order calculation in the backend
        this.localstate.status.loading = true;
        const data = {
            customer: B2BPricesStore.list.find(x => x.customer === selectedCustomer.value).customer_id,
            shipping: selectedShipping.value,
            billing: selectedBilling.value,
            total: total,
            paymentMethod: paymentMethod.value,
            shipmentMethod: shipmentMethod.value,
            referenceNumber: this.localstate.referenceNumber,
            internalNote: this.localstate.internalNote,
            location: this.localstate.location.value,
            shipping_cost: this.localstate.shipping_cost,
            products: skus.map(x => {
                const infos = availableSKUs.find(y => y.sku === x.sku);
                return {
                    articleId: infos.articleId,
                    taxId: infos.taxId,
                    taxRate: 0,
                    statusId: 0,
                    articleNumber: x.sku,
                    quantity: x.quantity,
                    price: infos.price,
                    articleName: infos.articleName,
                    shipped: 0,
                    shippedGroup: 0,
                    mode: 0,
                    esdArticle: 0
                }
            })
        }
        const request = RestClient.prepareRequest('POST', 'b2b/orders');
        request.setData(data);
        try {
            const result = await request.send();
            console.log(result);
            toast.success("Created order " +  result.data.ordernumber);
            await B2BOrderStore.addItem(result.data, true);
            this.localstate={...this.localstate, ...RESET};
        } catch (e) {
            toast.error(e.message);
        }
        this.localstate.status.loading = false;
    }

    async fetchInfos() {
        await B2BPricesStore.fetchData();
        await SWParamsStore.fetchData();
        this.localstate.shopwareParams = {
            payment: SWParamsStore.list.paymentMethods.map((x) => ({
                label: x.description,
                value: x.id
            })),
            shipping: SWParamsStore.list.dispatchMethods.map((x) => ({
                label: x.name,
                value: x.id
            })),
        }
    }

    render() {
        const {shippingAddresses, skus, selectedCustomer, status, selectedShipping,availableSKUs, shopwareParams, total} = this.localstate;

        let note = '';
        if (availableSKUs.length>0) {
            note = availableSKUs[0].notes;
        }

        return (<div>

            <NavigationBar title='Create B2B Orders'>
            </NavigationBar>
       <Container style={{marginTop: 20, marginBottom: 20}}>
            <FetchView call={this.fetchInfos.bind(this)}>
                <Columns style={styles.section}>
                    <Columns.Column>
                        <div>Customer</div>
                        <Select
                            className="basic-single"
                            options={this.formatItemForSelect(B2BPricesStore.list, 'customer')}
                            placeholder="Customer"
                            value={this.localstate.selectedCustomer}
                            onChange = {e => this.handleSelectChange('selectedCustomer', e)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div>Shipping Address</div>
                        <Select
                            isDisabled={!selectedCustomer}
                            className="basic-single"
                            options={shippingAddresses}
                            value={this.localstate.selectedShipping}
                            placeholder="Shipping Address"
                            onChange = {e => this.handleSelectChange('selectedShipping', e)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div>Billing Address</div>
                        <Select
                            isDisabled={!selectedCustomer}
                            className="basic-single"
                            options={shippingAddresses}
                            value={this.localstate.selectedBilling}
                            placeholder="Billing Address"
                            onChange = {e => this.handleSelectChange('selectedBilling', e)}
                        />
                    </Columns.Column>
                </Columns>

                {note && <Columns style={styles.section}>
                    <Columns.Column><div style={styles.note}>{note}</div></Columns.Column>
                </Columns>}
                    {skus.map((item, index) => {
                        return ( <Columns key={index}>
                            <Columns.Column>
                                <Select
                                    isDisabled={!selectedCustomer}
                                    className="basic-single"
                                    options={this.formatItemForSelect(availableSKUs, 'sku', 'articleName')}
                                    placeholder="SKU"
                                    value={item.sku ? {value: item.sku, label:item.sku} : null}
                                    onChange = {e => this.updateItem(index, 'sku', e.value)}
                                />
                        </Columns.Column>
                        <Columns.Column>
                            {item.sku && <Input
                                disabled={!selectedCustomer}
                                type='number'
                                placeholder='Anzahl'
                                value={item.quantity}
                                onChange = {e => this.updateItem(index, 'quantity', e.target.value)}
                            />}
                        </Columns.Column>
                            <Columns.Column>
                                {item.sku && Math.round(availableSKUs.find(x =>x.sku === item.sku).price * item.quantity*100)/100 + " €"}
                            </Columns.Column>
                            <Columns.Column>
                                {item.sku && availableSKUs.find(x =>x.sku === item.sku).articleName}
                            </Columns.Column>
                            <Columns.Column>
                                {item.sku && availableSKUs.find(x =>x.sku === item.sku).instock}
                            </Columns.Column>
                            <Columns.Column>
                                {item.sku && <Button onClick={() => this.removeItem(index)}>Remove</Button>}
                            </Columns.Column>
                        </Columns>) })}
                        <Columns style={styles.sectionTop}>
                            <Columns.Column>
                                <div>Payment Method</div>
                                <Select
                                    isDisabled={!selectedCustomer}
                                    className="basic-single"
                                    options={shopwareParams.payment}
                                    placeholder="Payment Method"
                                    value={this.localstate.paymentMethod}
                                    onChange = {e => this.handleSelectChange('paymentMethod', e)}
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <div>Shipment Method</div>
                                <Select
                                    isDisabled={!selectedCustomer}
                                    className="basic-single"
                                    options={shopwareParams.shipping.filter((item, pos) => {
                                        // filter duplicates
                                        return shopwareParams.shipping.findIndex(x => x.label===item.label) == pos;
                                    })}
                                    placeholder="Shipment Method"
                                    value={this.localstate.shipmentMethod}
                                    default={shopwareParams.shipping.find(x => x.label === "Spedition")}
                                    onChange = {e => this.handleSelectChange('shipmentMethod', e)}
                                />
                            </Columns.Column>
                        </Columns>
                <Columns style={styles.sectionTop}>
                    <Columns.Column>
                        <div>Reference Number (optional)</div>
                        <Input
                            disabled={!selectedCustomer}
                            className="basic-single"
                            placeholder="Reference Number"
                            value={this.localstate.referenceNumber}
                            onChange = {e => this.handleChange('referenceNumber', e.target.value)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div>Internal Note (optional)</div>
                        <Input
                            disabled={!selectedCustomer}
                            placeholder="Internal Note"
                            value={this.localstate.internalNote}
                            onChange = {e => this.handleChange('internalNote', e.target.value)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div>Warehouse Location</div>
                        <Select
                            isDisabled={!selectedCustomer}
                            placeholder="Warehouse Location"
                            value={this.localstate.location}
                            options={WAREHOUSE_LOCATIONS}
                            onChange = {e => this.handleSelectChange('location', e)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div>Shipping Cost</div>
                        <Input
                            disabled={!selectedCustomer}
                            type='number'
                            min={0}
                            placeholder="Shipping Cost"
                            value={this.localstate.shipping_cost}
                            onChange = {e => this.handleChange('shipping_cost', e.target.value)}
                        />
                    </Columns.Column>
                    </Columns>
                        <Container style={{...styles.section, ...styles.sectionTop}}>
                            <b>Gesamtsumme: {total}€</b>
                        </Container>
                <Button
                    fullwidth={true} color={'primary'}
                    loading={status.loading}
                    disabled={status.loading || !selectedCustomer || !selectedShipping}
                    onClick={() => this.submitOrder()}
                >Create Order</Button>
                <Container style={{overflowX: 'scroll'}}>
                </Container>
            </FetchView>
        </Container> </div>);
    }

}

const styles = {
    section: {
        borderBottom: '1px solid grey',
        marginBottom: 20,
        paddingBottom: 20
    },
    sectionTop: {
        borderTop: '1px solid grey',
        marginTop: 20,
        paddingTop: 20
    },
    note: {
        padding: 15,
        borderRadius: 10,
        background: '#eee'
    }
}

export default B2BOrderCreation;