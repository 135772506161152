import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container} from 'react-bulma-components/dist';
import {Input, Label} from "react-bulma-components/lib/components/form";
import {
    Table, TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import FetchView from "../../dumb/FetchView/FetchView";
import {ArticlesDiffsStore}  from '../../../stores/ArticlesDiffsStore';
import ReactHtmlParser from "react-html-parser";
import {getArticlesDiffsStore} from '../../../stores/ArticlesDiffsStore';
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

@observer
class ArticleChanges extends Component {

    constructor(props) {
        super(props);
    }

    @observable localstate = {
        loading: false,
        products: [],
        filtered: [],
        page: 0,
        rowsPerPage: 10,
        searchWord: '',
        diffs: [],
        nrWeeksAgoSelected: 0
    };

    changeSearchWord(e){
        this.localstate.searchWord = e;
        const eLC = e.toLowerCase();
        this.localstate.filtered = this.localstate.products.filter(item =>
            item['articleID'].toString().includes(eLC) ||
            item['detailsID'].toString().includes(eLC) ||
            item['articleNumber'].toLowerCase().includes(eLC) ||
            new Date(item['createdAt']).toLocaleDateString("de-DE").includes(eLC));
        this.localstate.page = 0;
        this.calculateDiffs();
    };

    handleChangePage(event, newPage) {
        this.localstate.page = newPage
    };

    handleChangeRowsPerPage(event) {
        this.localstate.rowsPerPage = event.target.value;
        this.localstate.page = 0;
    };

    handleLabelDisplayedRows({from, to, count}) {
        return(
            <span>{from} - {to} out of {count}</span>
        );
    };

    async init() {
        this.localstate.loading = true
        let diffStore = getArticlesDiffsStore(this.localstate.nrWeeksAgoSelected);
        await diffStore.fetchData();
        let products = diffStore.list;
        let sorted = products.sort((a, b) => {
            if(new Date(a['createdAt']) < new Date(b['createdAt'])) {
                return 1;
            }else{
                return -1;
            }
        });
        this.localstate.searchWord = ''
        this.localstate.products = sorted;
        this.localstate.filtered = sorted;
        this.calculateDiffs();
        this.localstate.loading = false
    }

    goBackOneWeek() {
        this.localstate.nrWeeksAgoSelected++;
    }

    goForwardOneWeek() {
        if(this.localstate.nrWeeksAgoSelected > 0){
            this.localstate.nrWeeksAgoSelected--;
        }
    }

    calculateDiffs() {
        this.localstate.diffs = [];
        const {filtered} = this.localstate;
        for (const item of filtered) {
            const diffs = JSON.parse(item['diff']).filter(x => x.type==="update");
            for (const diff_item of diffs) {
                this.localstate.diffs.push({
                    articleNumber: item['articleNumber'],
                    key: diff_item['key'],
                    oldValue: diff_item['oldValue'],
                    value: diff_item['value'],
                    createdAt: item['createdAt']
                })
            }
        }
    }

    render() {
        const { loading, products, diffs, page, rowsPerPage, searchWord, nrWeeksAgoSelected } = this.localstate
        return (<div>
            <NavigationBar title='Observe Article Changes' itemsLeft={
                    <Input
                        onChange={(e) => this.changeSearchWord(e.target.value)}
                        value={ searchWord }
                        disabled={products && products.length === 0}
                        placeholder= "Search for articles by number or date"
                    />
            }>
                <div style={styles.counterContainer}>
                    <FaChevronLeft onClick={() => this.goBackOneWeek()} style={styles.iconButton}/>
                    <Label style={{paddingTop: '5px'}}>{nrWeeksAgoSelected} {nrWeeksAgoSelected === 1 ? 'week':'weeks'} ago</Label>
                    <FaChevronRight onClick={() => this.goForwardOneWeek()} style={styles.iconButton}/>
                </div>
            </NavigationBar>
            <Container style={{padding:"20px"}}>
                <FetchView call={this.init.bind(this)} refresh={[nrWeeksAgoSelected]}>
                    {diffs && products && <Container style={{overflowX: 'scroll'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Article Number</TableCell>
                                    <TableCell>Attribute Name</TableCell>
                                    <TableCell>Old Value</TableCell>
                                    <TableCell>New Value</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && diffs.slice(page*rowsPerPage, (page + 1)*rowsPerPage).map((item, i) => {
                                    return <TableRow key={i}>
                                        <TableCell>{item['articleNumber']}</TableCell>
                                        <TableCell>{item['key']}</TableCell>
                                        <TableCell>{ReactHtmlParser(item['oldValue'].toString().replace(/(?:\r\n|\r|\n)/g, '<br>'))}</TableCell>
                                        <TableCell>{ReactHtmlParser(item['value'].toString().replace(/(?:\r\n|\r|\n)/g, '<br>'))}</TableCell>
                                        <TableCell>{new Date(item['createdAt']).toLocaleDateString("de-DE")}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'Show all', value: diffs.length }]}
                            colSpan={5}
                            component="div"
                            count={diffs.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Rows per page"
                            labelDisplayedRows={this.handleLabelDisplayedRows.bind(this)}
                            page={page}
                            onChangePage={this.handleChangePage.bind(this)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </Container> }
                </FetchView>
            </Container>
        </div>);
    }
}

const styles = {
    counterContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent:'space-between',
        width:'150px'
    },
    iconButton: {
        cursor: 'pointer',
        alignSelf: 'center'
    }
}

export default ArticleChanges;