import NavigationBar from "../NavigationBar/NavigationBar";
import React, {Component} from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import FetchView from "../../dumb/FetchView/FetchView";
import {ArticlesStoreSW} from "../../../stores";
import { Container, Columns, Button, Box} from 'react-bulma-components/dist'
import {Input} from "react-bulma-components/lib/components/form";
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";
import Select from "react-select";

@observer
class InstaFetcher extends Component {
    @observable localstate = {
        content: {
            username: '',
            slideFrom: '',
            slideTo: '',
            articles: ''
        },
        status: {
            loading: false,
            stories: []
        }
    };

    updateState(k, v) {

            this.localstate.content[k] = v;
    }

    formatArticlesStore(a) {
        let list = [];
        a.list.map(item =>(
            list.push({value: item.ordernumber, label: item.name})
        ));
        return(list)
    }

    handleArticlesSelect(e){
        if (e !== null){
            let list = [];
            e.map(item => (
                list.push(item.value)
            ));
            this.localstate.content.articles = list;
        } else {
            this.localstate.content.articles = [];
        }
    }



    async startProcess() {
        const {username, slideFrom = 1, slideTo, articles} = this.localstate.content;
        console.log(slideTo);

        this.localstate.status.stories = [];
        if (!username || !slideFrom || !articles || articles.length === 0) {
            toast.error("Please fill in all required fields")
            return;
        }
        this.localstate.status.loading = true;
        let userID;
        // try {
        //     let response = await axios({
        //         method: 'GET',
        //         url: 'https://www.instagram.com/' + username,
        //         headers:   {
        //             'accept': 'text/html,application/xhtml+xml,application/xml;q0.9,image/webp,image/apng,*.*;q=0.8',
        //             'accept-encoding': 'gzip, deflate, br',
        //             'cookie': generateCookie()
        //         }
        //     });
        //     const regex = /window\._sharedData = (.*);<\/script>/;
        //     const match = regex.exec(response.data);

        //     const userData = JSON.parse(match[1]).entry_data.ProfilePage[0];
        //     userID = userData.graphql.user.id;
        //     console.log(userID);
        //     toast.info('Got id for user, now downloading the stories - this might take up to 3 min');
        // } catch (e) {
        //     return toast.error('Could not get id for this Insta-username');
        // }



        const request = RestClient.prepareRequest('POST', 'insta/stories');
        request.setData({username, slideFrom, slideTo, articles});
        try {
            const response = await request.send();
            if (response.success) {
                toast.success("All done! - Successfully added the story to this product!")
                this.localstate.status.stories = response.data;
            } else {
                toast.error("An error occurred: " + response.error);
            }
        } catch (e) {
            toast.error("An error occurred: " + e.message);
        }
        this.localstate.status.loading = false;
    }

    render() {
        const {username, slideFrom, slideTo, articles} = this.localstate.content;
        const {loading, stories} = this.localstate.status;
        return (<div>
            <NavigationBar title = "Get Insta Stories" />
            <Container style={{padding: 20}}>
                <FetchView store={ArticlesStoreSW}>
                    <Columns>
                        <Columns.Column>
                            <Input
                                onChange={(e) => this.updateState('username', e.target.value)}
                                value={ username }
                                disabled={loading}
                                placeholder= "Insta Username"
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Input
                                onChange={(e) => this.updateState('slideFrom', e.target.value)}
                                value={ slideFrom }
                                disabled={loading}
                                type={'number'}
                                placeholder= "From Slide Nr"
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Input
                                onChange={(e) => this.updateState('slideTo', e.target.value)}
                                value={ slideTo }
                                disabled={loading}
                                type={'number'}
                                placeholder= "To Slide Nr (Optional)"
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Select
                                className="basic-single"
                                options={this.formatArticlesStore(ArticlesStoreSW)}
                                placeholder="Products"
                                isMulti
                                onChange = {e => this.handleArticlesSelect(e)}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                color='primary'
                                fullwidth
                                onClick={this.startProcess.bind(this)}
                                loading={loading}
                            >Start</Button>
                        </Columns.Column>
                    </Columns>

                    <Container>
                        <div>{stories && stories.length > 0  && 'Downloaded ' +  stories.length + ' stories and attached them to ' + articles.length +  ' articles.'}</div>

                        <Columns>
                            {stories.map(({video, url}) => {
                                return (<Columns.Column size={2}>
                                    <Box>{video ? <video style={{maxHeight: 300}} src={url} /> : <img style={{maxHeight: 300}} src={url} />}</Box>
                                </Columns.Column>);
                            })}
                        </Columns>
                    </Container>
                </FetchView>
            </Container>

        </div>);
    }
}


let essentialValues = {
    sessionid   : undefined,
    ds_user_id  : undefined,
    csrftoken   : undefined,
    shbid       : undefined,
    rur         : undefined,
    mid         : undefined,
    shbts       : undefined,
    mcd         : undefined,
    ig_cb       : 1,
    //urlgen      : undefined //this needs to be filled in according to my RE
};

export default InstaFetcher;
