import {Dialog} from "@material-ui/core";
import {ArticlesStore} from "../../../stores";
import React, {Component} from "react";
import {Reasons} from "./ReasonOptions";
import {ReservationObject} from "./ReservationObject";
import {DialogBar, DialogDescription, DialogInputField, DialogSelectionField} from "./ReservationDialogParts";

class ReserveOneDialog extends Component {
    state = {
        selectedAmount: this.props.reservation?.amount || 0,
        selectedDate:  this.props.reservation?.date || '',
        selectedReason:  this.defaultReasonOption(),
        selectedProduct: this.defaultProductOption(),
        selectedNote:  this.props.reservation?.note || ''
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.createContext = { title:"Reserve a product",
                                submitText:"Reserve",
                                function: this.handleSubmitCreate.bind(this) }

        this.updateContext = { title:"Change a reservation",
                                submitText:"Update",
                                function: this.handleSubmitUpdate.bind(this) }
    }

    defaultReasonOption() {
        let option = null
        if(this.props.reservation){
            const reason = this.props.reservation.reason
            option = {label: reason, value: reason}
        }
        return option
    }

    defaultProductOption() {
        let option = null
        if(this.props.reservation){
            const productNumber = this.props.reservation.productNumber
            const productName = this.props.reservation.productName
            const label = productNumber + " - " + productName
            option = {label: label, value: productNumber, name: productName}
        }
        return option
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    isDateValid() {
        let today_min = new Date().toISOString().substring(0,10);
        let inAYear = new Date();
        inAYear.setDate(inAYear.getDate() + 365);
        let inAYear_min =  inAYear.toISOString().substring(0,10);

        return this.state.selectedDate > today_min && this.state.selectedDate < inAYear_min;
    };

    isFormCorrect() {
        const requiredFieldsFilled = this.state.selectedAmount > 0
            && this.state.selectedReason !== null
            && this.state.selectedDate !== ''
            && this.state.selectedProduct !== null
            && this.isDateValid();

        if(this.props.reservation){
            const reservation = this.props.reservation
            const anythingChanged = this.state.selectedNote !== reservation.note
                || this.state.selectedDate !== reservation.date
                || parseInt(this.state.selectedAmount) !== reservation.amount
                || this.state.selectedReason?.value !== reservation.reason
                || this.state.selectedProduct?.value !== reservation.productNumber

            return requiredFieldsFilled && anythingChanged
        }

        return requiredFieldsFilled
    };

    buildReservation() {
        return new ReservationObject({
            orderlineKey: this.props.reservation ? this.props.reservation.orderlineKey : null,
            id: this.props.reservation ? this.props.reservation.id : null,
            date: this.state.selectedDate,
            productNumber: this.state.selectedProduct.value,
            productName: this.state.selectedProduct.name,
            amount: parseInt(this.state.selectedAmount),
            reason: this.state.selectedReason.value,
            note: this.state.selectedNote
        })
    }

    async handleSubmitCreate() {
        const reservation = this.buildReservation()
        this.props.onCreate(reservation)
    };

    async handleSubmitUpdate(){
        const reservation = this.buildReservation()
        this.props.onUpdate(reservation)
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogBar
                    context={this.props.reservation ? this.updateContext : this.createContext}
                    onClose={this.props.onClose}
                    isValidForm={this.isFormCorrect()}
                />
                <div style={{padding: "10pt", margin: "auto"}}>
                    <DialogDescription/>
                    <DialogSelectionField
                        title="Product"
                        isDisabled={this.props.reservation}
                        placeholder="Enter name or number"
                        value={this.state.selectedProduct}
                        onChange={option => {this.setState({"selectedProduct": option})}}
                        options={ArticlesStore.list.map((article) => {
                            return {value: article.ordernumber, label: article.ordernumber + " - " + article.name, name: article.name}
                        })}
                    />
                    <DialogSelectionField
                        title="Reason"
                        isDisabled={false}
                        placeholder="What is the reservation for?"
                        value={this.state.selectedReason}
                        onChange={option => {this.setState({"selectedReason": option})}}
                        options={Reasons}
                    />
                    <DialogInputField
                        title="Amount"
                        name="selectedAmount"
                        value={this.state.selectedAmount}
                        onChange={this.handleChange}
                        type='number'
                        min={1}
                        placeholder="Amount"
                    />
                    <DialogInputField
                        title="Expiry Date"
                        name="selectedDate"
                        value={this.state.selectedDate}
                        onChange={this.handleChange}
                        type='date'
                        min={new Date().toISOString().slice(0,10)}
                    />
                    <DialogInputField
                        title="Note"
                        name="selectedNote"
                        value={this.state.selectedNote}
                        onChange={this.handleChange}
                        placeholder="Optional info detailing the reason for this reservation"
                    />
                </div>
            </Dialog>)
    }
}

export default ReserveOneDialog;