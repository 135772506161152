import React, { Component } from "react";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Login from "./components/screen/Login/Login";
import Authentication from "./shared/Authentication";
import { ModalContainer} from "react-router-modal";
import "react-router-modal/css/react-router-modal.css";

import OrdersScreen from "./components/screen/OrdersScreen/OrdersScreen";
import Pricelist from "./components/screen/Pricelist/Pricelist";
import OrdersImport from "./components/screen/OrdersImport/OrdersImport";
import StockReservation from "./components/screen/StockReservation/StockReservation";
import NotFound from "./components/screen/NotFound/NotFound";
import PasswordForgotten from "./components/screen/PasswordForgotten/PasswordForgotten";
import Header from "./components/screen/Header/Header";
import RefundsReplacements from "./components/screen/RefundsReplacements/RefundsReplacements";
import SourcingTool from "./components/screen/SourcingTool/SourcingTool";
import Recall from "./components/screen/Recall/Recall";
import OrderRetention from "./components/screen/OrderRetention/OrderRetention";
import StockNotification from "./components/screen/StockNotifications/StockNotifications";
import AffiliateUserScreen from "./components/screen/AffiliateUserScreen/AffiliateUserScreen";
import AffiliateManagerScreen from "./components/screen/AffiliateManager/AffiliateManagerScreen";
import DelayedSupplierOrders from "./components/screen/DelayedSupplierOrders/DelayedSupplierOrders";

import "animate.css";
import "public.css";
import { Page } from "./components/dumb";
import HomeScreen from "./components/screen/HomeScreen/HomeScreen";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstaFetcher from "./components/screen/InstaFetcher/InstaFetcher";
import TranslationScreen from "./components/screen/Translations/TranslationScreen/TranslationScreen";
import UserManagement from "./components/screen/Usermanagement/UserManagement";
import tabs from "./config/tabs";
import ArticleChanges from "./components/screen/ArticleChanges/ArticleChanges";
import Markets from "./components/screen/Markets/Markets";
import StatementInput from "./components/screen/Markets/MarketDailyStatement/StatementInput"
import StatementsOverview from "./components/screen/Markets/StatementsOverview/StatementsOverview";
import ReplacementOrderScreen from "./components/screen/ReplacementOrderScreen/ReplacementOrderScreen";
import CancelOrders from "./components/screen/CancelOrders/CancelOrders";
import HRChallenges from "./components/screen/HRChallenges";
import ChallengeEditor from "components/screen/HRChallenges/ChallengeEditor";
import InviteCreation from "components/screen/HRChallenges/InviteCreation";
import Challenge from "components/screen/HRChallenges/Challenge";
import ChallengeResults from "components/screen/HRChallenges/ChallengeResults";
import ManageChallenges from "components/screen/HRChallenges/ManageChallenges";
import CommercialInvoiceBuilderScreen
    from "./components/screen/CommercialInvoiceBuilder/CommercialInvoiceBuilderScreen";
import ScheduledOrders from "components/screen/ScheduledOrders/ScheduledOrders";
import Subscriptions from "components/screen/Subscription/Subscription"
import DeliveryDashboard from "./components/screen/DeliveryDashboard/DeliveryDashboard";
import B2BOrders from "./components/screen/B2BOrders/B2BOrders";

@observer
class App extends Component {
  status = {
    loggedIn: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>

          <Route exact path="/affiliate/:userCode" component={AffiliateUserScreen}/>
          <Route exact path="/orderdelay/:urlCode" component={DelayedSupplierOrders}/>
          <Route exact path="/replacementorders" component={ReplacementOrderScreen}/>
          <Route exact path="/challenge/:token" component={Challenge} />
          <Route exact path="/public/markets/statements" component={StatementsOverview}/>
          <Route exact path="/public/markets/:id/statement/:date" component={StatementInput}/>


          <Route exact path="/forgotten">
            <PasswordForgotten />
          </Route>

          <PrivateRoute exact path="/" component={HomeScreen} />
          <PrivateRoute exact path="/orders" component={OrdersScreen} />
          <PrivateRoute exact path="/pricelist" component={Pricelist} />
          <PrivateRoute exact path="/orderimport" component={OrdersImport} />
          <PrivateRoute exact path="/stockreservation" component={StockReservation}/>
          <PrivateRoute exact path="/instafetcher" component={InstaFetcher} />
          <PrivateRoute exact path="/hrchallenges" component={HRChallenges} />
          <PrivateRoute exact path="/hrchallenges/challenge/:id?" component={ChallengeEditor}/>
          <PrivateRoute exact path="/hrchallenges/challenges" component={ManageChallenges}/>
          <PrivateRoute exact path="/hrchallenges/invite" component={InviteCreation}/>
          <PrivateRoute exact path="/challenge/results/:id" component={ChallengeResults}/>
          <PrivateRoute exact path="/translations" component={TranslationScreen}/>
          <PrivateRoute exact path="/stocknotification" component={StockNotification}/>
          <PrivateRoute exact path="/articlechanges" component={ArticleChanges}/>
          <PrivateRoute exact path="/affiliatemanager" component={AffiliateManagerScreen}/>
          <PrivateRoute exact path="/markets" component={Markets} />
          <PrivateRoute exact path="/markets/*:path" component={Markets} />
          <PrivateRoute exact path="/settings/users" component={UserManagement}/>
          <PrivateRoute exact path="/refunds" component={RefundsReplacements} />
          <PrivateRoute exact path="/sourcing" component={SourcingTool} />
          <PrivateRoute exact path="/sourcing/*:path" component={SourcingTool}/>
          <PrivateRoute exact path="/recall" component={Recall} />
          <PrivateRoute exact path="/orderRetention" component={OrderRetention}/>
          <PrivateRoute exact path="/cancelOrders" component={CancelOrders} />
          <PrivateRoute exact path="/commercialinvoice" component={CommercialInvoiceBuilderScreen} />
          <PrivateRoute exact path="/scheduledOrders" component={ScheduledOrders} />
          <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
          <PrivateRoute exact path="/deliveries" component={DeliveryDashboard} />
          <PrivateRoute exact path="/b2bOrders" component={B2BOrders} />
          <PrivateRoute exact path="/b2bOrders/*:path" component={B2BOrders} />

          <Route path="*">
            <NotFound loggedIn={Authentication.status.loggedIn} />
          </Route>
        </Switch>
        <ToastContainer />
        <ModalContainer />
      </HashRouter>
    );
  }
}

function PrivateRoute({ component, children, ...rest }) {
  let history = useHistory();

  autorun(() => {
    if (!Authentication.status.loggedIn) {
      history.push("/login");
    }
  }, "LogoutRouter");

  return (
    <div>
      <Header />
      <div>
        <Route
          {...rest}
          render={(props) => {
            if (!Authentication.status.loggedIn) {
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              );
            } else if (
              props.location.pathname !== "/" &&
              !Authentication.hasAccess(
                tabs.find((x) => x.url === props.location.pathname)
              )
            ) {
              return (
                <Page location={props.location} history={history}>
                  Sorry, you are not authorized for this feature.
                </Page>
              );
            } else {
              return (
                <Page location={props.location} history={history}>
                  {React.createElement(
                    component,
                    props.match
                      ? { ...props.match.params, location: props.location }
                      : { location: props.location }
                  )}
                </Page>
              );
            }
          }}
        />
      </div>
    </div>
  );
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  autorun(() => {
    if (Authentication.status.loggedIn) {
      history.replace(from);
    }
  }, "AuthenticationRouter");

  return <Login />;
}

export default App;
