import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {IconWithText} from "../../dumb";
import {FaPlusSquare} from "react-icons/fa";
import NavigationBar from "../NavigationBar/NavigationBar";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Section, Button, Container} from 'react-bulma-components/dist';
import RestClient from "../../../shared/Network/RestClient";
import {toast} from "react-toastify";
import Loader from "react-bulma-components/lib/components/loader";
import TableBody from "@material-ui/core/TableBody";


@observer
class AffiliateManagerScreen extends Component {
    @ observable localstate = {
        links: [],
        loadingPage: false,
        loadCreateURL: false,
        dataFetched: false,
    }

    componentDidMount() {
        this.getLinks()
    }


    async getLinks() {
        const request = RestClient.prepareRequest('GET', 'affiliate');
        try {
            this.localstate.loadingPage = true
            const response = await RestClient.sendRequest(request);
            const links = response.data
            for (const linkData of links){
                linkData['url'] = "https://toolbox.korodrogerie.de/#/affiliate/" + linkData.urlCode
            }
            this.localstate.links = links;
            this.localstate.loadingPage = false
            this.localstate.dataFetched = true
        } catch (e) {
            toast.error("Something went wrong. " + e)
            this.localstate.loadingPage = false
        }
    }

    makeNavigationBar() {
        return(
            <NavigationBar title='Affiliate Programme Manager'>
                <Button onClick={() => this.createNewLink()} loading={this.localstate.loadCreateURL}>
                    <IconWithText icon={<FaPlusSquare/>} text= "Create new links"/>
                </Button>
            </NavigationBar>
        )
    }

    async markAsSent(urlCode) {
        console.log(urlCode)
        const url = 'affiliate/' + urlCode + '/sent'
        const request = RestClient.prepareRequest('PUT', url)
        try {
            const response = await RestClient.sendRequest(request)
            console.log(response)
            const index = this.localstate.links.findIndex(link => link.id === response.data.id)
            this.localstate.links[index] = response.data
            console.log(index)
            toast.success("URL is marked as sent")
        } catch (e){
            toast.error("Something went wrong" + e)
        }
    }

    async createNewLink() {
        const request = RestClient.prepareRequest('POST', 'affiliate')
        this.localstate.loadCreateURL = true
        try{
            const response = await RestClient.sendRequest(request)
            this.localstate.links.push(response.data)
            toast.success("New URL links created!")
            this.localstate.loadCreateURL = false
        } catch (e) {
            toast.error("Something went wrong. " + e)
            this.localstate.loadCreateURL = false
        }
    }

    render(){
        const {links, loadingPage, dataFetched} = this.localstate
        if (loadingPage) {
            return(
                <div>
                    {this.makeNavigationBar()}
                    <div style={styles.loadingContainer}>
                        <Loader style={styles.loader}/>
                    </div>
                </div>
            )
        } else if(dataFetched) {
            return(
                <div>
                    {this.makeNavigationBar()}
                    <Container>
                        <Section>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>url</TableCell>
                                        <TableCell>is sent?</TableCell>
                                        <TableCell>account name</TableCell>
                                        <TableCell>name</TableCell>
                                        <TableCell>email</TableCell>
                                        <TableCell>voucher</TableCell>
                                        <TableCell>Amount Paid Out</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {links.map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <a href={item.url} target="_blank" rel="noopener noreferrer">link</a>
                                            </TableCell>
                                            <TableCell>
                                                {!item.isSent && <Button onClick={() => this.markAsSent(item.urlCode)}>mark as sent</Button>}
                                                {item.isSent && <div>yes</div>}
                                            </TableCell>
                                            <TableCell>{item.accountName}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{item.voucherCode}</TableCell>
                                            <TableCell>{item.amountPaidOut}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Section>
                    </Container>
                </div>
            )
        } else {
            return(
                <div>
                    {this.makeNavigationBar()}
                    <div>An error occurred. Please try again later.</div>
                </div>

            )
        }
    };
}

const styles = {
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerModal: {
        backgroundColor: 'white'
    },
    loader: {
        width: 150,
        height: 150
    }
}


export default AffiliateManagerScreen;