import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container, Heading, Button} from 'react-bulma-components/dist';
import {Control, Field, Label, Input} from "react-bulma-components/lib/components/form";
import Papa from "papaparse";
import {toast} from "react-toastify";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import RestClient from "../../../shared/Network/RestClient";


@observer
class RefundsReplacements extends Component {
    @observable localstate = {
        refundData: [],
        refundLoading: false,
        refundErrors: []
    };

    handleFileRead() {
        try {
            const content = this.fileReader.result;
            const results = Papa.parse(content, {header: true, delimiter: ";"});
            if (results.errors.length > 0) {
                throw('invalidee format');
            }
            this.localstate.refundData = results.data;
            toast.success('Daten erfolgreich hochgeladen')
        } catch (e) {
            toast.error("Fehler in der CSV-Datei. Bitte Format prüfen.")
            console.log("invalid format");
        }

    }

    handleFileChange(file) {
        this.localstate.refundErrors = [];
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead.bind(this);
        this.fileReader.readAsText(file);
    }
    
    async createRefunds() {
        let refundsObject = [];
        let errorObject = [];
        this.localstate.refundLoading = true;
        for(let refund of this.localstate.refundData) {
            try {
                const request = RestClient.prepareRequest('GET', 'orders/' + refund['orig-number'] + '/refundables');
                const response = await RestClient.sendRequest(request);
                if(response.success) {
                    let data = response.data;
                    data.refundOrder.refundables.forEach((refundable) => {

                        const refundableLine = refundable.refundableLines.find((line) => {
                            return line.itemNrInt === refund.articleNumber
                        });

                        if(!refundableLine) {
                            errorObject.push({order: refund['orig-number'], article: refund.articleNumber, msg: "not in invoice or not refundable"})
                            return;
                        }

                        if(!refundsObject[refundable.invoiceNr]) {
                            refundsObject[refundable.invoiceNr] = [
                                {
                                    invoiceLineKey: refundableLine.invoiceLineKey,
                                    itemQty: refund.quantity,
                                    itemPrice: refund.price,
                                    paymentType: refundable.paymentCode,
                                    orderNr: refund['orig-number']
                                }
                            ]
                        } else {
                            refundsObject[refundable.invoiceNr].push(
                                {
                                    invoiceLineKey: refundableLine.invoiceLineKey,
                                    itemQty: refund.quantity,
                                    itemPrice: refund.price,
                                    paymentType: refundable.paymentCode,
                                    orderNr: refund['orig-number']
                                }
                            )
                        }
                    });
                } else {
                    errorObject.push({order: refund['orig-number'], article: "all", msg: response.error.message})
                }
                
            } catch(e){
                errorObject.push({order: refund['orig-number'], article: "all", msg: e})
            }
        }

        const invoiceNrs = Object.keys(refundsObject);

        for(let invoiceNr of invoiceNrs) {
            if(refundsObject[invoiceNr].length > 0) {
                const orderNr = refundsObject[invoiceNr][0].orderNr;
                const body = {
                    invoiceNr: invoiceNr,
                    paymentType: refundsObject[invoiceNr][0].paymentType,
                    username: "KoroHelper",
                    refundables: refundsObject[invoiceNr]
                }
                try {
                    const request = RestClient.prepareRequest('POST', 'orders/' + orderNr + '/createrefund');
                    request.setData(body);
                    const response = await RestClient.sendRequest(request);
                    if(!response.success) {
                        throw (response.error.message)
                    }
                } catch (e) {
                    errorObject.push({order: orderNr, article: "all", msg: e})
                }
            }
        }

        if(errorObject.length > 0){
            toast.error("Es sind Fehler aufgetreten");
            this.localstate.refundErrors = errorObject;
            console.log(errorObject);
        } else {
            toast.success("Es wurden alle Gutschriften erstellt!");
        }

        this.localstate.refundLoading = false;
        this.localstate.refundData = [];

    }

    getErrorTable = () => {
        if(this.localstate.refundErrors.length > 0) {
            return (
                <Container>
                    <Heading>The following errors have occurred</Heading>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableCell>ordernumber</TableCell>
                            <TableCell>article</TableCell>
                            <TableCell>error</TableCell>
                        </TableHead>
                        <TableBody>
                            {this.localstate.refundErrors.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.order}</TableCell>
                                    <TableCell>{item.article}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.msg}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Container>
                
            )
        }
    }



    render() {
        const refundData = this.localstate.refundData;
        return (<div>
            <NavigationBar title='Create refunds from CSV'>
            </NavigationBar>
            <Container style={{padding: 20}}>
                <Heading size={5}>Upload CSV file for refunds</Heading>
                <Field>
                    <Label>Datei auswählen</Label>
                    <Control>
                        <input
                            type="file"
                            accept='.csv'
                            onChange={e => this.handleFileChange(e.target.files[0])}
                        />
                    </Control>
                </Field>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ordernumber</TableCell>
                            <TableCell>articlenumber</TableCell>
                            <TableCell>quantity</TableCell>
                            <TableCell>price</TableCell>
                            <TableCell>currency</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {refundData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item['orig-number']}</TableCell>
                                <TableCell>{item.articleNumber}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.currency}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    onClick={this.createRefunds.bind(this)}
                    loading={this.localstate.refundLoading}
                    disabled={this.localstate.refundData.length < 1}
                >Create refunds</Button>
                {this.getErrorTable()}
            </Container>
        </div>);
    }
}

export default RefundsReplacements;