import React, {Component} from 'react';
import {Input, Textarea} from "react-bulma-components/lib/components/form";
import {Columns} from "react-bulma-components";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

class AttributeValueRow extends Component {
    render() {
        return(
            <Columns>
                <Columns.Column style={styles.html} className='html'>
                    {ReactHtmlParser(this.props.attributeValue.replace(/\r\n|\r|\n/g, '<br>'))}
                </Columns.Column>
                <Columns.Column>
                    {this.props.type === 'short' &&
                    <Input disabled={this.props.isDisabled}
                              onChange={(e) => this.props.onChange(this.props.id, e.target.value)}
                              value={this.props.translatedValue ? this.props.translatedValue : ""}/>}
                    {this.props.type === 'long' &&
                    <Textarea style={styles.textarea} disabled={this.props.isDisabled}
                              onChange={(e) => this.props.onChange(this.props.id, e.target.value)}
                              value={this.props.translatedValue ? this.props.translatedValue : ""}/>}
                    {this.props.type === 'enum' &&
                    <Textarea style={styles.textarea} disabled={this.props.isDisabled}
                              onChange={(e) => this.props.onChange(this.props.id, e.target.value)}
                              value={this.props.translatedValue ? this.props.translatedValue : ""}/>}
                    {this.props.type === 'editor' &&
                    <ReactQuill style={styles.editor} disabled={this.props.isDisabled}
                              onChange={(e) => this.props.onChange(this.props.id, e)}
                              value={this.props.translatedValue ? this.props.translatedValue : ""}/>}
                </Columns.Column>
            </Columns>
        );
    }
}

const styles = {
    html: {
        fontSize: 14,
        maxHeight: '60vh',
        overflowY: 'auto'
    },
    textarea: {
        height: '100%'
    },
    editor: {
        height:'90%',
        minHeight: '150px',
        maxHeight: '60vh'
    }
}

export {AttributeValueRow};